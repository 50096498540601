import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { RecentEvent } from '../../../@core/models/recents';

@Component({
  selector: 'app-last-events',
  templateUrl: './last-events.component.html',
  styleUrls: ['./last-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastEventsComponent {
  @Input() events: RecentEvent[];
}
