<ng-container *isDesktop>
  <app-home-navbar *ngIf="!isCustomSubdomain"></app-home-navbar>

  <div class="container initial" [class.custom-subdomain]="isCustomSubdomain">
    <div class="columns is-centered">
      <div class="column is-6-tablet is-5-widescreen is-4-fullhd">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <app-home-footer *ngIf="!isCustomSubdomain"></app-home-footer>
</ng-container>

<ng-container *isMobile>
  <ion-router-outlet></ion-router-outlet>
</ng-container>
