import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IonSelect, Platform} from '@ionic/angular';
import { Message, ChatType } from '../../channels';
import { Profile } from '../../../@core/modules/profile/profile';

interface MessageEditModal {
  message: Message;
  hidden: boolean;
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesComponent {
  @Input() messages: Message[];
  @Input() chatType: ChatType;
  @Input() user: Profile;

  @Output() messageEditClick = new EventEmitter<Message>();
  @Output() messageEdit = new EventEmitter<Message>();

  @ViewChild('messageEditModalRef') messageEditModalRef: IonSelect;

  hoveredMessageId = null;
  isMobile: boolean;

  messageEditModal: MessageEditModal = {
    hidden: true,
    message: null,
  };

  constructor(public platform: Platform) {
    this.isMobile = platform.is('mobile');
  }

  messageEditModalOpen(message: Message) {
    if (this.isMobile && message.canEdit) {
      this.messageEditModal.message = message;
      this.messageEditModalRef.open();
    }
  }

  messageEditModalUpdate(event: Partial<CustomEvent>) {
    if (event.detail.value === 'edit' && this.messageEditModal.message) {
      this.messageEdit.emit(this.messageEditModal.message);
    }
    this.messageEditModalRef.value = null;
  }

  isEditableMessage(message) {
    return message.canEdit && this.hoveredMessageId === message.id;
  }

  getFormattedUserFullName(): string {
    if (this.user) {
      return `${this.user.firstName} ${this.user.lastName}`;
    }
  }

  chatUserSwap(messages: Message[], index: number): boolean {
    if (messages[index + 1] && messages[index - 1]) {
      const selfUser = this.getFormattedUserFullName();
      const currentChatUser = messages[index].userFullName;
      const previousChatUser = messages[index - 1].userFullName;
      const nextChatUser = messages[index + 1].userFullName;
      if (selfUser === currentChatUser && (currentChatUser !== previousChatUser || currentChatUser !== nextChatUser)) {
        return true;
      } else if (selfUser !== previousChatUser) {
        return true;
      }
    }
    return false;
  }

  trackByMessageBlock(index, item: any) {
    return item.date;
  }

  trackByMessage(index, item: Message) {
    return item.id;
  }
}
