<div class="field has-margin-bottom-md">
  <div class="control">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item has-margin-right-sm">
          <label class="checkbox">
            <div class="level is-mobile">
              <div class="checkbox-container">
                <input type="checkbox" [formControl]="$any(form.get(formControlName))">
              </div>
              <app-icon
                [source]="form.get(formControlName).value ? 'checkmark-selected.svg' : 'checkmark-default.svg'" class="has-margin-right-sm"></app-icon>
              <ng-content>
              </ng-content>
            </div>
          </label>
        </div>
      </div>
      <div class="level-right">
        <ng-content select="[level-right]">
        </ng-content>
      </div>
    </div>
    <p class="help" *ngIf="helper">{{helper}}</p>
  </div>
</div>
