import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AppState } from '../store/app.state';
import { map } from 'rxjs/operators';
import { Logout } from '../store/app.actions';
import { Role, UserSession } from '../models';

const availableRoutes = [
  {route: 'news', feature: 'news'},
  {route: 'polls', feature:'polls'},
  {route: 'events', feature: 'events'},
  {route: 'benefits', feature:'benefits'},
  {route: 'locations', feature: 'locations'},
  {route: 'chat', feature:'channels'},
];

@Injectable({providedIn: 'root'})
export class FeatureGuard implements CanLoad {
  constructor(private store: Store, private router: Router) { }

  canLoad(route: Route) {
    return this.store.selectOnce(AppState.userSession).pipe(
      map(session => {
        if (!session) {
          // allow if session is not loaded yet
          return true;
        }

        if (session.logoutNeeded) {
          this.store.dispatch(new Logout());
        }

        if (route.path === 'users') {
          return this.canLoadUsersPage(session);
        }

        if (route.path === 'billing') {
          return this.canLoadBillingPage(session);
        }

        const featureFromRoute = availableRoutes.filter(r => r.route.includes(route.path)).map(x => x.feature);

        if(featureFromRoute.length === 0) return true;

        const sessionFeatures = session.features?.map(f => f.toLowerCase());
        if (sessionFeatures?.includes(featureFromRoute[0])) {
          return true;
        }

        const redirectTo = availableRoutes.filter(route => sessionFeatures?.includes(route.feature));

        // todo: investigate why session.features in some cases is empty
        if (redirectTo.length === 0) {
          return true;
        }

        return this.router.parseUrl(`/${redirectTo[0].route}`);
      })
    );
  }

  canLoadUsersPage(session: UserSession){
    if(session.features.includes('HideUsersListForUserRole') && session.role === Role.User) return this.router.parseUrl(`/news`);
    return true;
  }

  canLoadBillingPage(session: UserSession){
    if(session.features.includes('DisableBilling')) return this.router.parseUrl(`/news`);
    return true;
  }
}
