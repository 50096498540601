import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild } from '@angular/core';
import { ImageCropComponent } from '../image-crop/image-crop.component';
import { ImageCropData } from '../../models/image-crop-data';
import { DialogRef, DIALOG_DATA } from 'projects/web/src/app/@core/modules';


export interface ImageEditDialogData {
  url: string;
  cropRatio: number;
  roundCrop: boolean;
}

@Component({
  selector: 'app-image-edit-dialog',
  templateUrl: './image-edit-dialog.component.html',
  styleUrls: ['./image-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageEditDialogComponent implements OnInit {
  cropData: ImageCropData;

  @ViewChild(ImageCropComponent) imageCrop: ImageCropComponent;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: ImageEditDialogData) { }

  ngOnInit() {
  }

  zoomIn() {
    this.imageCrop.zoomIn();
  }

  zoomOut() {
    this.imageCrop.zoomOut();
  }

  apply() {
    this.dialogRef.close(this.cropData);
  }

  close() {
    this.dialogRef.close();
  }

  onCropChange(cropData: ImageCropData) {
    this.cropData = cropData;
  }
}
