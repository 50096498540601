import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import Cropper from 'cropperjs';

import { ImageCropData } from '../../models/image-crop-data';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() url: string;
  @Input() aspectRatio: number;
  @Input() enableCrop = true;
  @Input() round = false;
  @Input() cropBoxResizable: number;

  @Output() cropChange = new EventEmitter<ImageCropData>();

  @ViewChild('image') image: ElementRef;

  cropper: Cropper;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.enableCrop) {
      const enableCrop = changes.enableCrop.currentValue;
      if (!enableCrop) {
        if (this.cropper) {
          this.cropper.destroy();
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.enableCrop) {
      const that = this;
      this.cropper = new Cropper(this.image.nativeElement, {
        viewMode: 0,
        dragMode: 'move',
        cropBoxMovable: true,
        cropBoxResizable: true,
        guides: false,
        aspectRatio: this.aspectRatio,
        movable: false,
        ready() {
          that.emitCropData();
        },
        cropend(event) {
          that.emitCropData();
        },
        zoom(event) {
          that.emitCropData();
        }
      });
    }
  }

  emitCropData() {
    const cropData = this.cropper.getData(true);
    this.cropChange.emit({
      x: cropData.x,
      y: cropData.y,
      height: cropData.height,
      width: cropData.width
    });
  }

  zoomIn() {
    this.cropper.zoom(0.1);
  }

  zoomOut() {
    this.cropper.zoom(-0.1);
  }

  ngOnDestroy() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  }
}
