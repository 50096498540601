import {AnalyticsEvent} from '../models/analytics';
import {Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {AppState} from '../store/app.state';
import { first, tap } from 'rxjs/operators';
import '@capacitor-community/firebase-analytics';
import { Device } from '@capacitor/device';
import { environment } from 'projects/web/src/environments/environment';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private store: Store, private platform: Platform) {
  }

  log(eventName: string, analyticsEvent: AnalyticsEvent) {
    this.store.select(AppState.userSession).pipe(
      first(session => !!session),
      tap(session => {
        FirebaseAnalytics.logEvent({
          name: eventName,
          params: {
            ...analyticsEvent,
            group_id: session.communityId,
            user_id: session.userId,
            community_name: session.communityName,
            view_in: this.platform.is('desktop') || this.platform.is('mobileweb') ? 'browser' : 'native_app'
          }
        });
      })
    ).subscribe();
  }

  updateUserId() {
    this.store.select(AppState.userSession).pipe(
      first(session => !!session),
      tap(session => {
        FirebaseAnalytics.setUserId({userId: session.userId.toString()});
      })
    ).subscribe();
  }

  async initializeFireBase() {
    if ((await Device.getInfo()).platform == 'web'){
      await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }
}
