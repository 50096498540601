import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'showNumberOfElements',
  pure: true
})
export class ShowNumberOfElementsPipe implements PipeTransform {
  defaultShowingNumberOfItems = 5;

  transform(value: any[], show: boolean, showingNumberOfItems = this.defaultShowingNumberOfItems): any {
    if (value) {
      if (show) {
        return value.slice(0, showingNumberOfItems);
      } else {
        return value;
      }
    }
  }

}
