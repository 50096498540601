import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BenefitLocation } from '../../../benefits/benefits';

@Component({
  selector: 'app-multi-locations',
  templateUrl: './multi-locations.component.html',
  styleUrls: ['./multi-locations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiLocationsComponent),
      multi: true,
    }
  ]
})
export class MultiLocationsComponent implements ControlValueAccessor {
  form: FormArray;
  onChange: (values: any[]) => void;

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = formBuilder.array([]);
  }

  addLocationAfter(index: number): void {
    this.cdr.markForCheck();
    this.form.insert(index + 1, this.formBuilder.control(null));
  }

  deleteLocation(index: number): void {
    this.cdr.markForCheck();
    this.form.removeAt(index);
    this.onChange(this.form.value);
  }

  onLocationChange(place: google.maps.places.PlaceResult, index: number): void {
    this.cdr.markForCheck();

    if (this.onChange) {
      this.form.setControl(index, this.formBuilder.control({
        name: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      }));

      this.onChange(this.form.value);
    }
  }

  getLocationName(control: AbstractControl): string | null {
    if (control.value && control.value.hasOwnProperty('name')) {
      return control.value.name;
    }

    return null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: Partial<BenefitLocation[]> | Partial<BenefitLocation>) {
    if (value && Array.isArray(value)) {
      value.forEach((location: BenefitLocation) => {
        this.form.controls.push(this.formBuilder.control(location));
      });
    }
  }
}
