import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

export interface MenuItem {
  key: string;
  label: string;
}

@Component({
  selector: 'app-context-button',
  templateUrl: './context-button.component.html',
  styleUrls: ['./context-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextButtonComponent implements OnInit {
  @Input() menuItems: MenuItem[];

  @Output() itemClick = new EventEmitter<MenuItem>();
  // @Input() editRoute: string[];
  // @Input() extraMargin = false;
  // @Input() optionalDropdownOption;
  // @Input() hideOptionalDropdownOption = false;
  // @Input() hideEdit = false;
  // @Input() deleteText = 'Delete';
  // @Input() deletePermission: Permission;

  // @Output() delete = new EventEmitter();
  // @Output() edit = new EventEmitter();
  // @Output() optionalDropdownOptionClick = new EventEmitter();

  dropdownOpen = false;
  click = false;

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.dropdownOpen = !this.dropdownOpen;
    this.click = true;
  }

  onItemClick(item: MenuItem) {
    this.itemClick.emit(item);
    this.dropdownOpen = false;
  }

  onClickOutside() {
    if (!this.click) {
      this.dropdownOpen = false;
    }

    this.click = false;
  }
}
