import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { CommunitySettingsDialogComponent } from '../../../community/containers/community-settings-dialog/community-settings-dialog.component';
import { DialogService } from '../../../@core/modules';
import { Community } from '../../../@core/models';
import { FeedbackDialogComponent } from '../../../@core/modules/feedback/containers/feedback-dialog/feedback-dialog.component';
import { Profile } from '../../../@core/modules/profile/profile';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent {
  @Input() user: Profile;
  @Input() communities?: Community[];
  @Input() activeCommunity?: Community;
  @Input() basicMode: boolean;

  @Output() logout = new EventEmitter();
  @Output() communitySelect = new EventEmitter();

  constructor(
    private dialogService: DialogService,
  ) {
  }

  onCreateCommunityClick() {
    this.dialogService.open(CommunitySettingsDialogComponent, {
      width: 500,
      data: false
    });
  }

  onFeedbackClick() {
    this.dialogService.open(FeedbackDialogComponent, {
      width: 500
    });
  }
}
