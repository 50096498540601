<div class="dropdown is-disabled is-block" [class.is-active]="opened">
  <div class="dropdown-trigger">
    <a
      aria-controls="dropdown-menu"
      aria-haspopup="true"
      class="button dropdown has-background-white"
      [class.narrow]="isNarrow"
      (click)="onTriggerClick()"
      [attr.disabled]="editMode ? true : null"
    >
      <span [innerHTML]="triggerText"></span>
      <app-icon source="navigation-down.svg"></app-icon>
    </a>
  </div>
  <div class="dropdown-menu" [class.narrow]="isNarrow" id="dropdown-menu" role="menu">
    <div
      class="dropdown-content"
      exclude=".dropdown-trigger"
      (clickOutside)="close()"
    >
      <a
        *ngIf="editOptionText"
        class="dropdown-item"
        [class.has-margin-bottom-md]="availableOptions?.length > 0"
        (click)="onEditOptionClick()"
      >
        <app-text-with-icon
          source="plus.svg"
          [text]="editOptionText"
        ></app-text-with-icon>
      </a>
      <a
        *ngIf="isSelectionOptional"
        class="dropdown-item"
        (click)="onOptionClick({id: null})">
        -
      </a>
      <a
        *ngFor="let option of availableOptions; let last = last"
        class="dropdown-item"
        (click)="onOptionClick(option)"
        [innerHTML]="getLabel(option)">
      </a>
    </div>
  </div>
</div>

<ng-container *ngIf="editMode">
  <ng-container *ngTemplateOutlet="editTemplate">
  </ng-container>
</ng-container>

