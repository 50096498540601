import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() text = 'Add';
  @Input() iconSource = 'plus-white.svg';
  @Input() flat = false;

  constructor() {
  }

  ngOnInit() {
  }

}
