import { Pipe, PipeTransform } from '@angular/core';
import { Event, EventsGroup } from '../../events/event';

@Pipe({
  name: 'eventGroup',
  pure: true
})
export class EventGroupPipe implements PipeTransform {
  // tslint:disable-next-line: max-line-length
  monthsFullNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  transform(value: Array<Event>, ...args: any[]): any {
    const eventsGroups: Array<EventsGroup> = [];

    value.forEach((event: Event) => {
      const month = this.getEventMonth(event);
      const index = eventsGroups.findIndex((eventsGroup: EventsGroup) => eventsGroup.month === month);
      if (index === -1) {
        eventsGroups.push(
          {
            month,
            events: [event]
          }
        );
      } else {
        eventsGroups[index].events.push(event);
      }
    });

    return eventsGroups;
  }

  getEventMonth(event: Event) {
    const monthIndex = new Date(event.eventDate).getMonth();
    const month = this.monthsFullNames[monthIndex];
    return month;
  }

}
