<nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
  <div class="container">
    <div class="navbar-brand" *ngIf="communities && !basicMode">
      <div class="navbar-item level has-margin-community">
        <a class="has-margin-right-md">
          <app-icon
            [source]="'ic-switch.svg'"
            [routerLink]="'/setup'"
            sizeClass="is-32x32"
            [backgroundOnHover]="true">
          </app-icon>
        </a>
        <app-community-logo class="logo" [logoUrl]="activeCommunity?.logoUrl" [name]="activeCommunity?.name ?? ''" alternative="name"></app-community-logo>
      </div>
    </div>
    <div class="navbar-menu">
      <div class="navbar-end">
        <span *ngIf="user" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link is-arrowless">
            <span class="profile level is-semi-round">
              <figure class="image is-32x32  has-margin-right-sm">
                <app-user-avatar [avatarUrl]="user.avatarUrl"></app-user-avatar>
              </figure>
              <span class="text-width text-body-2">{{user.firstName}} {{user.lastName}}</span>
            </span>
          </a>
          <span class="navbar-dropdown is-right is-boxed">
            <a *ngIf="!basicMode" class="dropdown-item" routerLink="/profile/edit">
              Edit profile
            </a>
            <a *ngIf="!basicMode" class="dropdown-item" (click)="onFeedbackClick()">
              Send us feedback
            </a>
            <a href="https://www.fellowup.co/company/" target="_blank" class="dropdown-item">
              Help & Support
            </a>
            <a class="dropdown-item" (click)="logout.emit()">
              Logout
            </a>
          </span>
        </span>
      </div>
    </div>
  </div>
</nav>
