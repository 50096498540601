<div class="inner-content has-padding-bottom-md is-scrollable" platform #p="platform">
  <div class="has-margin-bottom-md has-margin-top-md">
    <div class="text-subheading-1 modal-header">Select Community</div>
    <img class="corner-element has-margin-right-md has-margin-top-md" (click)="dismiss()" src="assets/icons/exit.svg" />
  </div>

  <div class="level-left block has-margin-top-sm has-margin-bottom-sm"
    (click)="community.enabled && onCommunitySelect(community)"
    [ngClass]="{'is-non-clickable': !community.enabled,'is-clickable': community.enabled}"
    *ngFor="let community of communities$ | async as communities">
    <div class="level thumbnail-container has-margin-right-sm" slot="start" *ngIf="community.logoUrl">
      <img class="thumbnail" [src]="community.logoUrl">
    </div>
    <div class="level">
      <div class="is-flex-direction-column">
        <span class="text-body-1 has-text-left">{{community.name}}</span>
        <span *ngIf="!community.enabled" class="has-text-left has-text-pink text-body-1">Pending approval</span>
      </div>
    </div>
  </div>
</div>