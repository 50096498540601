import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: 'removeHtml',
  pure: true
})
export class RemoveHtmlPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return value;
    }
    return value.replace(/<(?:.|\n)*?>/gm, ' ').replace(/ +(?= )/g,'').trim();
  }
}
