import { Component } from '@angular/core';
import { DialogRef } from '../../dialog';

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss']
})

export class DownloadDialogComponent {

  constructor(
    private dialogRef: DialogRef
  ) {
  }

  onCancel() {
    this.dialogRef.close();
  }

}
