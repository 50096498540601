import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import {tap, catchError} from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import {RecentsService} from '../services/recents.service';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { FeatureDataService } from '../services/feature-data.service';
import { ToastService } from '../services/toast.service';
import { FeedbackService } from '../services/feedback.service';
import {
  LoadUserSession,
  Login,
  RefreshAccessToken,
  SwitchCommunity,
  LoadRecents,
  Logout,
  SetMenuItem,
  WSArticleCreated,
  WSEventCreated,
  WSPollCreated,
  WSBenefitCreated,
  ToggleFeature,
  DeleteCommunity,
  SendFeedback,
  LoadCommunities,
  SaveCommunity,
  LoadNotifications,
  SetNotificationCount,
  CommunityChanged,
  SetReady,
  WSUserRestricted, UpdateBenefitsNotificationTimer, SetNearbyBenefits, AddNearbyBenefits, RemoveNearbyBenefits, LeaveCommunity, CreateCommunity
} from './app.actions';
import { Recents } from '../models/recents';
import { CommunityService } from '../../community/community.service';
import { StateResetAll, StateReset } from 'ngxs-reset-plugin';
import { UserSession, Community, CommunityPlan, Feature, Role } from '../models';
import { ProfileState } from '../modules/profile/profile.state';
import { NotificationsService } from '../services/notifications.service';
import { Notifications } from '../models/notifications';
import {
  DisconnectWebSocket,
  ConnectWebSocket,
  WebSocketConnected,
  WebSocketDisconnected,
  SendWebSocketMessage as NgxsSendWebSocketMessage
} from '@ngxs/websocket-plugin';
import { BenefitsService } from '../../benefits/benefits.service';
import { Benefit, BenefitLocation } from '../../benefits/benefits';
//import BackgroundGeolocation, { Geofence } from '@transistorsoft/capacitor-background-geolocation';
import {Platform} from '@ionic/angular';
import { of } from 'rxjs';
import { AnalyticsService } from '../services/analytics.service';

export interface AppStateModel {
  isLoggedIn: boolean;
  loginErrorCode: string;
  loginLoading: boolean;
  accessToken: string;
  encryptedAccessToken: string;
  refreshAccessToken: string;
  session: UserSession;
  communities: Community[];
  communitiesLoaded: boolean;
  leaveCommunityLoading: boolean;
  saveCommunityLoading: boolean;
  webSocketConnected: boolean;
  wsShouldBeConnected: boolean;
  recents: Recents;
  notifications: Notifications;
  menuItemId: string;
  ready: boolean;
  groupedBenefitsGeofence: any;
  nearbyBenefits: Benefit[];
  benefitsNotificationTimer: any;
  clientSecret: string;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    isLoggedIn: false,
    loginErrorCode: null,
    loginLoading: false,
    accessToken: null,
    encryptedAccessToken: null,
    refreshAccessToken: null,
    session: null,
    communities: [],
    communitiesLoaded: false,
    leaveCommunityLoading: false,
    saveCommunityLoading: false,
    webSocketConnected: false,
    wsShouldBeConnected: false,
    recents: {
      benefits: [],
      events: [],
    },
    groupedBenefitsGeofence: {},
    notifications: {},
    menuItemId: 'news',
    ready: false,
    nearbyBenefits: [],
    benefitsNotificationTimer: {},
    clientSecret: ''
  },
  children: [
    ProfileState
  ]
})
@Injectable()
export class AppState {

  constructor(
    private platform: Platform,
    private authService: AuthService,
    private recentsService: RecentsService,
    private notificationsService: NotificationsService,
    private featureDataService: FeatureDataService,
    private communityService: CommunityService,
    private toastService: ToastService,
    private feedbackService: FeedbackService,
    private benefitsService: BenefitsService,
    private analyticsService: AnalyticsService) {
  }

  // @Selector([AppState.community, AppState.userSession])
  // static ready(community: Community, userSession: UserSession) {
  //   return !!community && !!userSession;
  // }

  @Selector()
  static accessToken(state: AppStateModel) {
    return state.accessToken;
  }

  @Selector()
  static getNearbyBenefits(state: AppStateModel): Benefit[] {
    return state.nearbyBenefits;
  }

  @Selector()
  static refreshAccessToken(state: AppStateModel) {
    return state.refreshAccessToken;
  }

  @Selector()
  static ready(state: AppStateModel) {
    return state.ready;
  }

  @Selector()
  static getBenefitsNotificationTimer(state: AppStateModel) {
    return state.benefitsNotificationTimer;
  }

  @Selector()
  static loginErrorCode(state: AppStateModel) {
    return state.loginErrorCode;
  }

  @Selector()
  static loginLoading(state: AppStateModel) {
    return state.loginLoading;
  }

  @Selector()
  static notifications(state: AppStateModel) {
    return state.notifications;
  }

  @Selector()
  static menuItemId(state: AppStateModel) {
    return state.menuItemId;
  }

  @Selector()
  static isLoggedIn(state: AppStateModel) {
    return state.isLoggedIn;
  }

  @Selector()
  static recents(state: AppStateModel) {
    return state.recents;
  }

  @Selector([AppState.recents])
  static recentBenefits(recents: Recents) {
    return recents.benefits;
  }

  @Selector([AppState.recents])
  static recentEvents(recents: Recents) {
    return recents.events;
  }

  @Selector()
  static encryptedAccessToken(state: AppStateModel) {
    return state.encryptedAccessToken;
  }

  @Selector()
  static communities(state: AppStateModel) {
    return state.communities;
  }

  @Selector()
  static totalCommunities(state: AppStateModel) {
    return state.communities.length;
  }

  @Selector()
  static communitiesLoaded(state: AppStateModel) {
    return state.communitiesLoaded;
  }

  @Selector()
  static community(state: AppStateModel) {
    if (state.session && state.communities.length > 0) {
      return state.communities.find(c => c.id === state.session.communityId);
    }
  }

  @Selector([AppState.community])
  static communityName(community: Community) {
    if (community) {
      return community.name;
    }
  }

  @Selector()
  static webSocketConnected(state: AppStateModel): boolean {
    return state.webSocketConnected;
  }

  @Selector()
  static wsShouldBeConnected(state: AppStateModel): boolean {
    return state.wsShouldBeConnected;
  }

  @Selector()
  static userSession(state: AppStateModel) {
    return state.session;
  }

  @Selector()
  static hideUsersPage(state: AppStateModel) {
    return state.session && state.session.features?.includes('HideUsersListForUserRole') && state.session.role === Role.User
  }

  @Selector()
  static hideBilling(state: AppStateModel) {
    return state.session && state.session.features?.includes('DisableBilling')
  }

  @Selector()
  static currentUserId(state: AppStateModel) {
    return state.session?.userId;
  }

  @Selector()
  static currentUserRole(state: AppStateModel) {
    return state.session?.role;
  }

  @Selector()
  static standardPlan(state: AppStateModel): boolean {
    return state.session
      ? state.session.plan === CommunityPlan.Standard
      : false;
  }

  @Selector()
  static permissions(state: AppStateModel) {
    if (state.session) {
      return state.session.permissions;
    }
  }

  @Selector()
  static features(state: AppStateModel): Feature[] {
    if (state.session) {
      return state.session.features;
    }
  }

  @Selector()
  static leavingCommunity(state: AppStateModel) : boolean {
    return state.leaveCommunityLoading;
  }

  @Selector()
  static savingCommunity(state: AppStateModel) : boolean {
    return state.saveCommunityLoading;
  }

  @Selector()
  static clientSecret(state: AppStateModel): string {
    return state.clientSecret;
  }

  @Action(Login)
  login(ctx: StateContext<AppStateModel>, action: Login) {
    ctx.patchState({
      isLoggedIn: false,
      loginErrorCode: null,
      loginLoading: true
    });

    return this.authService.authenticate(action.login).pipe(
      tap(res => {
        ctx.patchState({
          isLoggedIn: true,
          accessToken: res.accessToken,
          encryptedAccessToken: res.encryptedAccessToken,
          refreshAccessToken: res.refreshAccessToken,
          loginLoading: false
        });
      }),
      catchError((err) => {
        const error = err.error?.error;
        ctx.patchState({
          loginErrorCode: error?.code ?? 'unexpectedError',
          loginLoading: false
        });

        if (error) {
          // add error toast with details (e.g. password is incorrect)
          if(error.code !== 'UserEmailIsNotConfirmed')  this.toastService.error(error.details ?? error.message)
          return of();
        }

        // unexpected errors (e.g. network)
        this.toastService.error('Something went wrong. Please try again.');
        return of();
      })
    );
  }

  @Action(RefreshAccessToken)
  refreshAccessToken(ctx: StateContext<AppStateModel>) {
    const refreshToken = ctx.getState().refreshAccessToken;

    if (!refreshToken) {
      return;
    }

    return this.authService.refreshAccessToken(refreshToken).pipe(
      tap(res => {
        ctx.patchState({
          accessToken: res.accessToken,
          encryptedAccessToken: res.encryptedAccessToken,
          refreshAccessToken: res.refreshAccessToken
        });
      })
    );
  }

  @Action(LoadCommunities)
  loadCommunities(ctx: StateContext<AppStateModel>) {
    return this.communityService.getCommunities().pipe(
      tap(communities => {
        ctx.patchState({
          communities,
          communitiesLoaded: true
        });
      })
    );
  }

  @Action(SwitchCommunity)
  switchCommunity(ctx: StateContext<AppStateModel>, action: SwitchCommunity) {
    const { accessToken, communities, session, webSocketConnected } = ctx.getState();

    if (action.communityId === session?.communityId) {
      ctx.dispatch(new Navigate([action.navigateTo], action.queryParams));
      return;
    }

    if (action.disconnectWebSocket && webSocketConnected) {
      ctx.dispatch(new DisconnectWebSocket());
    }

    if (action.resetState) {
      ctx.dispatch(new StateResetAll());
      // resets saved values to localstorage
      ctx.dispatch(new StateReset(AppState));
    }

    return this.authService.switchCommunity(accessToken, action.communityId).pipe(
      tap(res => {
        ctx.patchState({
          isLoggedIn: true,
          accessToken: res.accessToken,
          encryptedAccessToken: res.encryptedAccessToken,
          refreshAccessToken: res.refreshAccessToken,
          communities // restore communities after clearing state
        });

        ctx.dispatch(new CommunityChanged(action.communityId, action.navigateTo, action.queryParams));
      }),
      catchError(err => {
        return ctx.dispatch(new Logout());
      })
    );
  }

  // @Action(LoadBenefitsGeofence)
  // loadBenefitsGeofence(ctx: StateContext<AppStateModel>) {
  //   if (! this.platform.is('cordova')) { return; }

  //   this.benefitsService.getBenefits().subscribe((benefits: Benefit[]) => {
  //     BackgroundGeolocation.removeGeofences();
  //     const groupedBenefitsGeofence = {};

  //     benefits.forEach((benefit: Benefit) => {
  //       benefit.locations.forEach((location: BenefitLocation) => {
  //         const identifier = `${ location.latitude }:${ location.longitude }`;
  //         if (groupedBenefitsGeofence.hasOwnProperty(identifier)) {
  //           groupedBenefitsGeofence[identifier].push(benefit);

  //           return;
  //         }

  //         groupedBenefitsGeofence[identifier] = [benefit];
  //       });
  //     });

  //     ctx.patchState({
  //       groupedBenefitsGeofence,
  //     });

  //     const geofenceList: Geofence[] = [];

  //     Object.keys(groupedBenefitsGeofence).map((key: string) => {
  //       const location: string[] = key.split(':');

  //       geofenceList.push({
  //         identifier: key,
  //         radius: 75,
  //         latitude: Number(location[0]),
  //         longitude: Number(location[1]),
  //         notifyOnDwell: true,
  //         notifyOnEntry: true,
  //         notifyOnExit: true,
  //         loiteringDelay: 29000,
  //         extras: {
  //           benefits: groupedBenefitsGeofence[key],
  //         }
  //       });
  //     });

  //     BackgroundGeolocation.addGeofences(geofenceList);
  //   });
  // }

  @Action(SaveCommunity)
  @ImmutableContext()
  saveCommunity(ctx: StateContext<AppStateModel>, action: SaveCommunity) {
    return this.communityService.saveCommunity(action.community)
      .pipe(
        tap(savedSpace => {
          this.logCommunityEvent('update_community', savedSpace)
        })
      );
  }

  @Action(CreateCommunity)
  @ImmutableContext()
  createCommunity(ctx: StateContext<AppStateModel>, action: CreateCommunity) {
    ctx.setState((state: AppStateModel) => {
      state.saveCommunityLoading = true;
      return state;
    })
    return this.communityService.createCommunity(action.community)
      .pipe(
        tap(savedSpace => {
          this.logCommunityEvent('create_community', savedSpace.community)
          ctx.setState((state: AppStateModel) => {
            state.communities.push(savedSpace.community);
            state.saveCommunityLoading = false;
            state.clientSecret = savedSpace.paymentIntent?.clientSecret;
            return state;
          })
        }),
        catchError((error) => {
          ctx.setState((state: AppStateModel) => {
            state.saveCommunityLoading = false;
            return state;
          })
          this.toastService.error('Error while saving community. Please try again. Message: ' + error?.error?.message);
          return of();
        })
      );
  }

  @Action(DeleteCommunity)
  deleteCommunity(ctx: StateContext<AppStateModel>) {
    return this.communityService.deleteCommunity().pipe(
      tap(_ => {
        const communityId = ctx.getState().session.communityId;

        ctx.patchState({
          communities: ctx.getState().communities.filter(c => c.id !== communityId)
        });
        ctx.dispatch(new SwitchCommunity(null, '/setup'));
      })
    );
  }

  @Action(LeaveCommunity)
  leaveCommunity(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      leaveCommunityLoading: true
    });
    return this.communityService.leaveCommunity().pipe(
      tap(_ => {
        const communityId = ctx.getState().session.communityId;

        ctx.patchState({
          communities: ctx.getState().communities.filter(c => c.id !== communityId),
          leaveCommunityLoading: false,
        });
        ctx.dispatch(new SwitchCommunity(null, '/setup'));
      }),
      catchError((_) => {
        ctx.patchState({
          leaveCommunityLoading: false
        });
        this.toastService.error('Error while leaving community. Please try again.');
        return of();
      })
    );
  }

  @Action(LoadRecents)
  loadRecents(ctx: StateContext<AppStateModel>) {
    return this.recentsService.get().pipe(
      tap(recents => {
        ctx.patchState({recents});
      })
    );
  }

  @Action(LoadNotifications)
  loadNotifications(ctx: StateContext<AppStateModel>) {
    return this.notificationsService.get().pipe(
      tap(notifications => {
        ctx.patchState({
          notifications
        });
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AppStateModel>) {
  }

  @Action(LoadUserSession)
  loadUserSession(ctx: StateContext<AppStateModel>) {
    return this.authService.getUserSession().pipe(
      tap(session => {
        ctx.patchState({
          session
        });

        if (session.logoutNeeded) {
          ctx.dispatch(new Logout());
        }
      })
    );
  }

  @Action(SetMenuItem)
  @ImmutableContext()
  setMenuItem(ctx: StateContext<AppStateModel>, action: SetMenuItem) {
    ctx.setState((state: AppStateModel) => {
      state.menuItemId = action.menuItemId;
      state.notifications[action.menuItemId] = 0;
      return state;
    });
  }

  @Action(WSArticleCreated)
  @ImmutableContext()
  articleCreated(ctx: StateContext<AppStateModel>, action: WSArticleCreated) {
    ctx.setState(state => {
      if (state.menuItemId !== 'news') {
        state.notifications['news']++;
      }
      return state;
    });
  }

  @Action(WSEventCreated)
  @ImmutableContext()
  eventCreated(ctx: StateContext<AppStateModel>, action: WSEventCreated) {
    ctx.setState(state => {
      if (state.menuItemId !== 'events') {
        state.notifications['events']++;
      }
      return state;
    });
  }

  @Action(WSPollCreated)
  @ImmutableContext()
  pollCreated(ctx: StateContext<AppStateModel>, action: WSPollCreated) {
    ctx.setState(state => {
      if (state.menuItemId !== 'polls') {
        state.notifications['polls']++;
      }
      return state;
    });
  }

  @Action(WSBenefitCreated)
  @ImmutableContext()
  benefitCreated(ctx: StateContext<AppStateModel>, action: WSBenefitCreated) {
    ctx.setState(state => {
      if (state.menuItemId !== 'benefits') {
        state.notifications['benefits']++;
      }
      return state;
    });
  }

  @Action(WSUserRestricted)
  @ImmutableContext()
  userRestricted(ctx: StateContext<AppStateModel>, action: WSUserRestricted) {
    ctx.dispatch(new SwitchCommunity(null, '/setup', true, true));
  }

  @Action(ToggleFeature)
  @ImmutableContext()
  toggleFeature(ctx: StateContext<AppStateModel>, action: ToggleFeature) {
    return this.featureDataService.toggleFeature(action.feature, action.enabled).pipe(
      tap(_ => {
        ctx.setState(state => {
          if (action.enabled) {
            state.session.features = [ ...state.session.features, action.feature ];
          } else {
            state.session.features = state.session.features.filter(x => x !== action.feature);
          }
          return state;
        });
      })
    );
  }

  @Action(SendFeedback)
  sendFeedback(ctx: StateContext<AppStateModel>, action: SendFeedback) {
    return this.feedbackService.sendFeedback(action.feedback).pipe(
      tap(_ => {
        this.toastService.success('Thank you for your feedback!');
      }),
      catchError((_) => {
        this.toastService.error('Error while sending feedback. Please try again.');
        return of();
      })
    );
  }

  @Action(SetNotificationCount)
  @ImmutableContext()
  setNotificationCount(ctx: StateContext<AppStateModel>, action: SetNotificationCount) {
    ctx.setState(state => {
      state.notifications[action.menuItemId] = action.count;
      return state;
    });
  }

  @Action(ConnectWebSocket)
  connectWebSocket(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      wsShouldBeConnected: true
    });
  }

  @Action(DisconnectWebSocket)
  disconnectWebSocket(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      wsShouldBeConnected: false
    });
  }

  @Action(WebSocketConnected)
  webSocketConnected(ctx: StateContext<AppStateModel>) {
    // handshake
    return ctx.dispatch(new NgxsSendWebSocketMessage({protocol: 'json', version: 1})).pipe(
      tap(() => {
        ctx.patchState({
          webSocketConnected: true
        });
      })
    );
  }

  @Action(WebSocketDisconnected)
  webSocketDisconnected(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      webSocketConnected: false
    });
  }

  @Action(SetReady)
  setReady(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      ready: true
    });
  }

  @Action(UpdateBenefitsNotificationTimer)
  updateBenefitsNotificationTimer(ctx: StateContext<AppStateModel>, action: UpdateBenefitsNotificationTimer) {
    const benefitsNotificationTimer = ctx.getState().benefitsNotificationTimer;

    benefitsNotificationTimer[action.identifier] = new Date().getTime();

    ctx.patchState({
      benefitsNotificationTimer,
    });
  }

  @Action(SetNearbyBenefits)
  setNearbyBenefits(ctx: StateContext<AppStateModel>, action: SetNearbyBenefits) {
    ctx.patchState({
      nearbyBenefits: action.benefits,
    });
  }

  @Action(AddNearbyBenefits)
  addNearbyBenefits(ctx: StateContext<AppStateModel>, action: AddNearbyBenefits) {
    ctx.patchState({
      nearbyBenefits: [...action.benefits, ctx.getState().nearbyBenefits] as Benefit[]
    });
  }

  @Action(RemoveNearbyBenefits)
  removeNearbyBenefits(ctx: StateContext<AppStateModel>, action: RemoveNearbyBenefits) {
    ctx.patchState({
      nearbyBenefits: ctx.getState().nearbyBenefits.filter((benefit: Benefit) => ! action.ids.some((id: number) => id === benefit.id))
    });
  }

  logCommunityEvent(event: string, community: Community){
    this.analyticsService.log(event, {
      'content_type': 'communities',
      'item_id': community.id
    });
  }
}
