import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { MessageEditDialogComponent } from '../message-edit-dialog/message-edit-dialog.component';
import {ChatMessage} from '../../components/chat/chat.component';
import { ChatState } from '../../chat.state';
import { PrivateChat, Message } from '../../channels';
import { DialogService } from '../../../@core/modules';
import { SendWebSocketMessage } from '../../../@core/store/send-websocket-message';
import { UnsetPrivateChat, SetCurrentPrivateChat, LoadPrivateChatMessages } from '../../chat.actions';
import { ProfileState } from '../../../@core/modules/profile/profile.state';
import { Profile } from '../../../@core/modules/profile/profile';
import { AppState } from '../../../@core/store/app.state';
import { tap, filter, first } from 'rxjs/operators';
import { PageComponentBase } from '../../../@core/components/component-base';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-private-chat-page',
  templateUrl: './private-chat-page.component.html',
  styleUrls: ['./private-chat-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivateChatPageComponent extends PageComponentBase implements OnInit, OnDestroy {
  @Select(ChatState.currentPrivateChat) currentChat$: Observable<PrivateChat>;
  @Select(ChatState.currentPrivateChatMessages) currentChatMessages$: Observable<Message[]>;
  @Select(ProfileState.user) user$: Observable<Profile>;

  currentRecipientId: number;
  backUrl: string;

  sub: Subscription;

  constructor(private store: Store, private route: ActivatedRoute, private dialogService: DialogService, platform: Platform) {
    super(platform);
  }

  init() {
    this.backUrl = this.route.snapshot.queryParamMap.get('back');

    // wait until ws is connected
    this.store.select(AppState.webSocketConnected).pipe(
      filter(connected => connected),
      first(),
      tap(_ => {
        this.sub = this.route.paramMap.subscribe(params => {
          const newRecipientId = +params.get('recipientId');

          if (this.currentRecipientId) {
            this.leaveChat(this.currentRecipientId);
          }

          this.setCurrentChat(newRecipientId);
          this.loadChatMessages(newRecipientId);
          this.joinChat(newRecipientId);

          this.currentRecipientId = newRecipientId;
        });
      })
    ).subscribe();
  }

  destroy(): void {
    this.leaveChat(this.currentRecipientId);
    this.sub.unsubscribe();
    this.store.dispatch(new UnsetPrivateChat());
  }

  onMessageEditClick(event) {
    this.dialogService.open(MessageEditDialogComponent, {
      width: 500,
      data: {
        chatType: 'private',
        message: event,
        currentRecipientId: this.currentRecipientId
      }
    });
  }

  setCurrentChat(recipientId: number) {
    this.store.dispatch(new SetCurrentPrivateChat(recipientId));
  }

  loadChatMessages(recipientId: number) {
    this.store.dispatch(new LoadPrivateChatMessages(recipientId));
  }

  joinChat(recipientId: number) {
    this.store.dispatch(new SendWebSocketMessage('JoinPrivateChat', {recipientId}));
  }

  leaveChat(recipientId: number) {
    this.store.dispatch(new SendWebSocketMessage('LeavePrivateChat', {recipientId}));
  }

  sendMessage(chatMessage: ChatMessage) {
    if (chatMessage.messageId) {
      this.store.dispatch(new SendWebSocketMessage('EditPrivateMessage', {...chatMessage, recipientId: this.currentRecipientId}));
    } else {
      this.store.dispatch(new SendWebSocketMessage('SendPrivateMessage', {
        message: chatMessage.message,
        recipientId: this.currentRecipientId
      }));
    }
  }
}
