import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadDialogComponent } from './containers/download-dialog.component';
import { DownloadContentComponent } from './components/download-content.component';

@NgModule({
  declarations: [
    DownloadDialogComponent,
    DownloadContentComponent
  ],
  imports: [
    CommonModule,
  ]
})
export class DownloadModule { }
