import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Channel } from '../../channels';

@Component({
  selector: 'app-channel-form',
  templateUrl: './channel-form.component.html',
  styleUrls: ['./channel-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelFormComponent implements OnChanges {
  @Output() channelFormSubmit = new EventEmitter();
  @Input() channel: Channel;
  @Input() saving: boolean;
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]]
    });

  }

  ngOnChanges() {
    if (this.channel) {
      this.form.patchValue(this.channel);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const {value} = this.form;
      const updatedEvent = {...this.channel, ...value};
      this.channelFormSubmit.emit(updatedEvent);
    }
  }

}
