import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { CustomSubdomainService } from '../../../@core/services/custom-subdomain.service';

@Component({
  selector: 'app-initial-layout',
  templateUrl: './initial-layout.component.html',
  styleUrls: ['./initial-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialLayoutComponent implements OnInit {

  isCustomSubdomain: boolean;

  constructor(private customSubdomainService: CustomSubdomainService) { }

  ngOnInit() {
    this.isCustomSubdomain = this.customSubdomainService.isCustomSubdomain();
  }

}
