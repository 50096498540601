import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {TopbarComponent} from './components/topbar/topbar.component';
import {SharedModule} from '../@shared/shared.module';
import {MenuComponent} from './components/menu/menu.component';
import {MainLayoutComponent} from './components/main-layout/main-layout.component';
import {InitialLayoutComponent} from './components/initial-layout/initial-layout.component';
import {ChatModule} from '../chat/chat.module';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {BottomBarLayoutComponent} from './components/bottom-bar-layout/bottom-bar-layout.component';
import { InitialLayoutV2Component } from './components/initial-layout-v2/initial-layout-v2.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    InitialLayoutComponent,
    MenuComponent,
    TopbarComponent,
    MenuItemComponent,
    BottomBarLayoutComponent,
    InitialLayoutV2Component,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ChatModule
  ],
  exports: [
    MainLayoutComponent,
    InitialLayoutComponent,
    TopbarComponent
  ],
})
export class LayoutModule {
}
