import {SendWebSocketMessage as NgxsSendWebSocketMessage } from '@ngxs/websocket-plugin';

export class SendWebSocketMessage extends NgxsSendWebSocketMessage {
  constructor(method: string, ...args: any[]) {
    super({
      target: method,
      arguments: args,
      type: 1
    });
  }
}
