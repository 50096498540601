import {Component, Input} from '@angular/core';
import { getMenuIconUrl } from '../../utilities';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Input() id: string;
  @Input() text: string;
  @Input() link: string;
  @Input() notificationCount: number;

  getIconUrl(isActive: boolean) {
    return getMenuIconUrl(this.id, isActive);
  }
}
