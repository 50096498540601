<p *ngIf="benefits && benefits.length > 0" class="text-body-2 has-margin-bottom-mlg">
  New benefits
</p>

<a *ngFor="let benefit of benefits" [routerLink]="['/benefits', benefit.id]">
  <div class="media has-margin-bottom-mlg">
    <div class="media-left">
      <div class="card-image has-margin-bottom-md">
        <ng-container *ngIf="benefit.companyLogoUrl; else benefitPlaceholder">
              <span class="image is-32x32 is-1by1">
                <img class="is-semi-round image" [src]="benefit.companyLogoUrl" alt="Placeholder image">
              </span>
        </ng-container>
      </div>
    </div>
    <div class="media-content">
      <p class="text-body-2">{{benefit.companyName}}</p>
      <p class="text-body-1 has-margin-bottom-sm">{{benefit.shortDescription}}</p>
      <span *ngIf="benefit.discount"
              class="tag primary has-text-weight-bold is-rounded">
        {{benefit.discount}}
      </span>
    </div>
  </div>
</a>

<ng-template #benefitPlaceholder>
  <span class="image placeholder is-semi-round is-32x32 is-1by1">
    <app-icon source="benefits-grey.svg"></app-icon>
  </span>
</ng-template>
