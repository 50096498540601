  <div class="level is-mobile list-tab" (click)="itemClick.emit()">
    <div class="level-left">
      <div class="has-padding-right-mds">
        <app-icon
          *ngIf="!!iconPath"
          source={{iconPath}}
          sizeClass="is-20x20"
          ></app-icon>
      </div>
      <span>{{title}}</span>
    </div>
    <div *ngIf="!!count" class="level-right">
      <app-counter
        [number]="count"
      ></app-counter>
    </div>
  </div>
