import { NgxsWebsocketPluginOptions } from '@ngxs/websocket-plugin';

export const ngxsWebsocketOptions: NgxsWebsocketPluginOptions = {
  serializer: serializer,
  deserializer: deserializer,
  typeKey: 'target'
};

export function serializer(value) {
  return JSON.stringify(value) + '';
}

export function deserializer(e: MessageEvent) {
  // prevent from crashing
  if (!e.data.includes('target')) {
    return { target: 'foo' };
  } else {
    return JSON.parse((e.data as string).replace('', '').replace('"arguments"', '"payload"'));
  }
}
