import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Community, CreateCommunityRequest } from '../@core/models';
import { Observable } from 'rxjs';
import { CommunityInvitation, CreateCommunityResponse, FullCommunityInvitation, UpdateCommunityInvitation, WhitelistRule } from './community';

@Injectable({providedIn: 'root'})
export class CommunityService {

  constructor(private httpClient: HttpClient) {
  }

  getCommunities(): Observable<Community[]> {
    return this.httpClient.get<Community[]>(`${environment.baseApiUrl}v2/communities`);
  }

  createCommunity(community: CreateCommunityRequest): Observable<CreateCommunityResponse> {   
      return this.httpClient.post<CreateCommunityResponse>(`${environment.baseApiUrl}v2/communities`, community);    
  }

  saveCommunity(community: Community): Observable<Community> {
    return this.httpClient.put<Community>(`${environment.baseApiUrl}v2/communities`, community);    
  }

  deleteCommunity() {
    return this.httpClient.delete(`${environment.baseApiUrl}v2/communities/current`);
  }

  leaveCommunity() {
    return this.httpClient.put(`${environment.baseApiUrl}v2/communities/leave`, {});
  }
  
  getCommunityInvitation(): Observable<CommunityInvitation> {
    return this.httpClient.get<CommunityInvitation>(`${environment.baseApiUrl}v2/communities/current/invitation`);
  }

  getFullCommunityInvitation(code: string): Observable<FullCommunityInvitation> {
    return this.httpClient.get<FullCommunityInvitation>(`${environment.baseApiUrl}v2/communities/invitation/${code}`);
  }

  updateCommunityInvitation(request: UpdateCommunityInvitation): Observable<CommunityInvitation> {
    return this.httpClient.put<CommunityInvitation>(`${environment.baseApiUrl}v2/communities/current/invitation`, request);
  }

  createCommunityInvitation(): Observable<CommunityInvitation> {
    return this.httpClient.post<CommunityInvitation>(`${environment.baseApiUrl}v2/communities/current/invitation`, null);
  }

  getWhitelistRules(): Observable<WhitelistRule[]> {
    return this.httpClient.get<WhitelistRule[]>(`${environment.baseApiUrl}v2/communities/current/whitelist`);
  }

  saveWhitelistRule(emails: WhitelistRule[]): Observable<WhitelistRule[]> {
    return this.httpClient.put<WhitelistRule[]>(`${environment.baseApiUrl}v2/communities/current/whitelist`, emails);
  }
}
