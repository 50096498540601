import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

export enum ImageUploadStatus {
  Pending,
  Success,
  Error,
  Progress
}

export class ImageUploadProgress {
  public file: any;
  public status: ImageUploadStatus = ImageUploadStatus.Pending;
  public progress: number = 0;
  public request: Subscription = null;
  public response: HttpResponse<any>;
  public errorResponse: HttpErrorResponse = null;

  constructor(file: any) {
    this.file = file;
  }

  // statuses
  public isPending = () => this.status === ImageUploadStatus.Pending;
  public isSuccess = () => this.status === ImageUploadStatus.Success;
  public isError = () => this.status === ImageUploadStatus.Error;
  public inProgress = () => this.status === ImageUploadStatus.Progress;
  public isUploadable = () => this.status === ImageUploadStatus.Pending || this.status === ImageUploadStatus.Error;
}
