import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {Subscription} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import { DialogRef, ModalService, DIALOG_DATA } from '../../../@core/modules';
import { AppState } from '../../../@core/store/app.state';
import { Community } from '../../../@core/models';
import { SaveCommunity } from '../../../@core/store/app.actions';

@Component({
  selector: 'app-community-settings-dialog',
  templateUrl: './community-settings-dialog.component.html',
  styleUrls: ['./community-settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunitySettingsDialogComponent implements OnInit, OnDestroy {
  actionsSubscription: Subscription;
  community: Community;

  constructor(
    private store: Store,
    private dialogRef: DialogRef,
    private modalService: ModalService,
    private actions$: Actions,
    @Inject(DIALOG_DATA) public editMode: boolean,
  ) {
  }

  ngOnInit(): void {
    if (this.editMode) {
      this.community = this.store.selectSnapshot(AppState.community);
    }

    this.actionsSubscription = this.actions$.pipe(
      ofActionSuccessful(SaveCommunity),
      take(1),
      tap((action) => {
        this.dialogRef.close();
        if (!action.space.id) {
          this.modalService.openMessageModal({
            title: 'Community created',
            message: 'You have successfully created FellowUp community. ' +
              'We recommend start with inviting members to your newly created community.',
            primaryButtonText: 'Start using FellowUp'
          });
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.actionsSubscription.unsubscribe();
  }

  onCommunitySubmit(community: Community) {
    this.store.dispatch(new SaveCommunity(community));
  }

  onCancel() {
    this.dialogRef.close();
  }
}
