import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-location-permission-content',
  templateUrl: './location-permission-content.component.html',
  styleUrls: ['./location-permission-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LocationPermissionContentComponent {
  @Output() cancel = new EventEmitter();
}
