import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[remove-wrapper]'
})
export class RemoveWrapperDirective implements AfterViewInit {
  constructor(private el: ElementRef, private platform: Platform) {
  }

  ngAfterViewInit() {
    // Ionic does not like app-page wrapper so we remove it when a page loads
    if (this.platform.is('mobile')) {
      const element = this.el.nativeElement;
      element.replaceWith(...element.childNodes); // or node.children, if you don't want textNodes
    }
  }
}
