<footer class="footer has-background-dark-blue">
  <div class="content container">
    <div class="columns has-text-centered">
      <div class="column is-12-desktop">
        <div class="columns text-description">
          <div class="column">
            <div class="image is-48x48">
              <img src="assets/icons/FellowUp_logo.svg" alt="pulse">
            </div>
          </div>
          <div class="column has-text-left">
            <p class="has-text-weight-bold has-margin-bottom-sm">
              FellowUp App
            </p>
            <dl>
              <dt>
                <a href="https://fellowup.co/">Home</a>
              </dt>
              <dt>
                <a href="https://fellowup.co/pricing">Pricing</a>
              </dt>
              <dt>
                <a href="https://fellowup.co/#download">Download</a>
              </dt>
              <dt>
                <a href="/login">Try FellowUp</a>
              </dt>
              <dt>
                <a href="https://fellowup.co/contact-us/">Support</a>
              </dt>
            </dl>
          </div>
          <div class="column has-text-left">
            <p class="has-text-weight-bold has-margin-bottom-sm">
              Policies
            </p>
            <dl>
              <dt>
                <a href="https://fellowup.co/terms-conditions/">Terms of use</a>
              </dt>
              <dt>
                <a href="https://fellowup.co/privacy-policy/">Privacy policy</a>
              </dt>
            </dl>
          </div>
          <div class="column has-text-left">
            <p class="has-text-weight-bold has-margin-bottom-sm">
              Company
            </p>
            <dl>
              <dt>
                <a href="https://fellowup.co/about-us/">Our team</a>
              </dt>
              <dt>
                <a href="https://fellowup.co/contact-us/">Contact us</a>
              </dt>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-9 is-offset-3 copyright">
        <p>© 2022 Vulpes In Arca MB</p>
      </div>
    </div>
  </div>
</footer>
