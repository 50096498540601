<form (ngSubmit)="onSubmit()" [formGroup]="form">

  <div class="field">
    <label class="label">Channel name</label>
    <div class="control">
      <input class="input" formControlName="name" maxlength="50" placeholder="Add channel name" type="text">
    </div>
  </div>

  <div *isDesktop class="field is-grouped">
    <div class="control">
      <button class="button is-rounded is-secondary" [class.is-loading]="saving" type="submit">
        <span *ngIf="channel; else noChannelButton ">Save</span>
        <ng-template #noChannelButton>Create channel</ng-template>
      </button>
    </div>
    <div class="control">
      <button class="button is-rounded" routerLink="/news" type="button">
        Cancel
      </button>
    </div>
  </div>

  <div *isMobile class="field has-margin-top-mlg">
    <div class="control">
      <button type="submit" class="button is-rounded is-secondary is-fullwidth is-high-button" [class.is-loading]="saving">Save</button>
    </div>
  </div>
</form>
