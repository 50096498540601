import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from './modal.service';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { Platform, AlertController } from '@ionic/angular';
import { DialogService } from '../dialog';
import { MobileModalService } from './mobile-modal.service';

const COMPONENTS = [
  MessageModalComponent
];

function modalServiceFactory(platform: Platform, dialogService: DialogService, alertController: AlertController) {
  if (platform.is('desktop')) {
    return new ModalService(dialogService);
  } else {
    return new MobileModalService(alertController);
  }
}

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule
  ],
  providers: [
    { provide: ModalService, useFactory: modalServiceFactory, deps: [Platform, DialogService, AlertController]}
  ]
})
export class ModalModule { }
