import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {AppState} from '../store/app.state';
import {first, map} from 'rxjs/operators';

export type Permission =
  'news_manage' |
  'polls_manage' |
  'benefits_manage' |
  'locations_manage' |
  'events_manage' |
  'users_manage' | 'users_view' |
  'community_manage' |
  'billing_manage' |
  'statistics_view' |
  'channels_view' |
  'channels_manage' ;

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(private store: Store) {
  }

  hasPermission(permissions: Permission[]) {
    return this.store.select(AppState.permissions).pipe(
      first(userPermissions => !!userPermissions),
      map(userPermissions => {
        return (userPermissions as Permission[]).some(p => permissions.includes(p));
      })
    );
  }

  isAdmin() {
    const role = this.store.selectSnapshot(AppState.currentUserRole);
    return role === 'Admin';
  }

  getUserInfo() {
    const session = this.store.selectSnapshot(AppState.userSession);
    return {
      userId: session?.userId,
      role: session?.role
    };
  }
}
