import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Feature } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FeatureDataService {
  constructor(private httpClient: HttpClient) {
  }

  toggleFeature(feature: Feature, enabled: boolean) {
    return this.httpClient.put(`${environment.baseApiUrl}v2/communities/current/feature-toggles`, { feature, enabled });
  }
}
