import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationPermissionDialogComponent } from './containers/location-permission-dialog.component';
import { LocationPermissionContentComponent } from './components/location-permission-content.component';

@NgModule({
  declarations: [
    LocationPermissionDialogComponent,
    LocationPermissionContentComponent
  ],
  imports: [
    CommonModule,
  ]
})
export class LocationPermissionModule { }
