<div class="dropdown is-disabled" [class.is-active]="opened" platform #p="platform">
  <div class="dropdown-trigger">
    <a aria-controls="dropdown-menu" aria-haspopup="true" class="button dropdown has-background-white"
      [class.narrow]="isNarrow" (click)="onTriggerClick()" [attr.disabled]="editMode ? true : null">
      <div class="level-left">
      <span *isDesktop [ngClass]="{'has-margin-right-sm':value}">
        <app-role [role]="value" *ngIf="value"></app-role>
      </span>
      {{ triggerText }}
      </div>
      <app-icon source="navigation-down.svg"></app-icon>
    </a>
  </div>
  <div class="dropdown-menu" [class.narrow]="isNarrow" id="dropdown-menu" role="menu">
    <div class="dropdown-content" exclude=".dropdown-trigger">
      <a (click)="onOptionClick(option)" *ngFor="let option of availableOptions; let last=last" class="dropdown-item">
        <div class="level-left">
          <span *isDesktop class="has-margin-right-sm">
            <app-role [role]="option.name"></app-role>
          </span>
          {{option.name}}
        </div>
      </a>
    </div>
  </div>
</div>


<ng-container *ngIf="editMode">
  <ng-container *ngTemplateOutlet="editTemplate">
  </ng-container>
</ng-container>
