import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackFormComponent {
  @Output() feedbackSubmit = new EventEmitter();
  @Output() cancel = new EventEmitter();

  form: FormGroup;

  constructor(fb: FormBuilder) {
    this.form = fb.group({
      feedback: ['', [Validators.required, Validators.maxLength(2048)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.feedbackSubmit.emit(this.form.value.feedback);
    }
  }
}
