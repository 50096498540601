import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CustomSubdomainService } from '../../../@core/services/custom-subdomain.service';

@Component({
  selector: 'app-initial-layout-v2',
  templateUrl: './initial-layout-v2.component.html',
  styleUrls: ['./initial-layout-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitialLayoutV2Component implements OnInit {

  isCustomSubdomain: boolean;

  constructor(private customSubdomainService: CustomSubdomainService) { }

  ngOnInit() {
    this.isCustomSubdomain = this.customSubdomainService.isCustomSubdomain();
  }

}
