import {ChangeDetectionStrategy, Component, OnInit, OnDestroy} from '@angular/core';
import {Select, Store, Actions, ofActionSuccessful} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import { ChatState } from '../../chat.state';
import { Channel, ChannelsLoading, PrivateChat } from '../../channels';
import { LoadChannels } from '../../chat.actions';
import { CommunityChanged } from '../../../@core/store/app.actions';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelsComponent implements OnInit, OnDestroy {
  @Select(ChatState.channels) channels$: Observable<Channel[]>;
  @Select(ChatState.privateChats) privateChats$: Observable<PrivateChat[]>;
  @Select(ChatState.loading) loading$: Observable<ChannelsLoading>;

  private sub: Subscription;

  constructor(private store: Store, private actions$: Actions) {
  }

  ngOnInit() {
    this.store.dispatch(new LoadChannels());

    this.sub = this.actions$.pipe(ofActionSuccessful(CommunityChanged)).subscribe((action: CommunityChanged) => {
      this.store.dispatch(new LoadChannels());
    });
  }

  onRefresh(event) {
    this.store.dispatch(new LoadChannels(true)).subscribe(_ => event.target.complete());
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
