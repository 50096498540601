import {Component, forwardRef, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import {ImageUploadService} from '../../services/image-upload.service';
import {ImageCropData} from '../../models/image-crop-data';
import {tap} from 'rxjs/operators';
import { ImageEditDialogComponent, ImageEditDialogData } from '../image-edit-dialog/image-edit-dialog.component';
import { DialogService } from 'projects/web/src/app/@core/modules/dialog';


export interface Image {
  id?: number | string;
  fileName?: string;
  order?: number;
  url: string;
  cropData?: ImageCropData;
  status: 'idle' | 'uploading';
  file?: File;
  progress?: number
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true
    }
  ]
})
export class ImageUploadComponent implements OnInit, ControlValueAccessor {
  @Input() uploadPath: string;
  @Input() httpMethod: 'POST' | 'PUT' = 'POST';
  @Input() formDataKey: string;
  @Input() singleMode = false;
  @Input() maxImages = 24;
  @Input() aspectRatio: number;
  @Input() roundCrop = false;
  @Input() allowReupload = false;

  private _images: Image[];

  get images(): Image[]{
    return this._images;
  }

  set images(newImages: Image[]){
    this._images = newImages;
    this.cdr.markForCheck();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(
    private dialogService: DialogService,
    private imageUploadService: ImageUploadService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.singleMode) {
      this.maxImages = 1;
    }
  }

  onClick(event) {
    event.srcElement.value = null;
  }

  handleFileUpload(event: Event) {
    const file = (<HTMLInputElement>event.target).files[0];

    if (!this.allowReupload && this.images.length >= this.maxImages) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (event: any) => {
      const dataUrl = event.target.result;
      const ref = this.dialogService.open(ImageEditDialogComponent, {
        data: {
          url: dataUrl,
          cropRatio: this.aspectRatio,
          roundCrop: this.roundCrop
        } as ImageEditDialogData,
        width: '600px'
      });

      ref.afterClosed$.subscribe(res => {
        if (res.data) {
          const cropData = res.data as ImageCropData;

          const newImage = {
            status: 'idle' as 'idle',
            file: file,
            url: dataUrl,
            cropData: cropData
          };

          this.uploadImage(newImage);
        }
      });
    };

    reader.readAsDataURL(file);
  }

  uploadImage(image: Image) {

    if (this.images.length >= this.maxImages) {
      this.images.pop();
    }

    image.status = 'uploading';

    this.images.push(image);
    this.cdr.markForCheck();

    this.imageUploadService.upload(image.file, this.uploadPath, image.cropData, this.httpMethod, this.formDataKey).pipe(
      tap((res) => {
        image.progress = res.progress;
        this.cdr.markForCheck();

        if (res.isSuccess()) {
          const body = res.response.body;
          image.status = 'idle';
          image.url = body.fullImageUrl;
          image.fileName = body.imageFileName;

          this.cdr.markForCheck();
          this.updateControlValue();
        }
      })
    ).subscribe();
  }

  removeImage(idx: number) {
    this.images.splice(idx, 1);
    this.updateControlValue();
  }

  private updateControlValue() {
    const controlValue = this.images.map(i => {
      return {
        id: i.id,
        fileName: i.fileName,
        order: i.order,
        url: i.url
      };
    });

    if (this.singleMode) {
      this.onChange(controlValue[0]);
    } else {
      this.onChange(controlValue);
    }
  }

  /** ControlValueAccessor */

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value: Partial<Image>[] | Partial<Image>) {
    if (value) {
      let valueAsArray: Partial<Image>[];

      if (Array.isArray(value)) {
        if (this.singleMode) {
          throw Error('In single mode - form control value must be an object');
        }

        valueAsArray = value;
      } else {
        if (!this.singleMode) {
          throw Error('In not single mode - form control value must be an array');
        }

        valueAsArray = [value];
      }

      this.images = valueAsArray.map(v => {
        return {
          id: v.id,
          fileName: v.fileName,
          order: v.order,
          url: v.url,
          status: 'idle'
        };
      });
    } else {
      this.images = [];
    }
  }
}
