import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Role } from '../../../@core/models';
import { User } from '../../../users/users';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent implements OnInit {
  @Input() currentUserRole: Role;
  @Output() userSubmit = new EventEmitter<User>();

  form: FormGroup;

  get editMode() {
    return false;
  }

  constructor(fb: FormBuilder) {
    this.form = fb.group({
      email: [null, [Validators.required, Validators.email]],
      role: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    const { valid, value } = this.form;

    if (valid) {
      this.userSubmit.emit(value);
    }
  }
}
