
    <div class="field">
      <label class="label">Crop image</label>
      <!-- <app-image-crop
        [url]="data.url"
        [aspectRatio]="data.cropRatio"
        [round]="data.roundCrop"
        (cropChange)="onCropChange($event)">
      </app-image-crop> -->
      <app-image-crop
      [url]="data.url"
      [aspectRatio]="data.cropRatio"
      [round]="data.roundCrop"
      (cropChange)="onCropChange($event)">
    </app-image-crop>
      <div class="zoom-control">
        <span class="text-info">Zoom</span>
        <app-icon class="is-clickable" source="plus.svg" sizeClass="is-small" (click)="zoomIn()"></app-icon>
        <app-icon class="is-clickable" source="minus.svg" sizeClass="is-small" (click)="zoomOut()"></app-icon>
      </div>
    </div>
    <div class="dialog-actions has-margin-top-lg">
      <button class="button is-secondary is-rounded" (click)="apply()">Apply</button>
      <button class="button is-rounded" (click)="close()">Cancel</button>
    </div>
