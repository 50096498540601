<ng-container *isMobile platform #p="platform">
  <div class="level is-mobile has-margin-bottom-sm has-padding-left-md has-padding-right-md">
    <div class="level-left has-text-weight-bold">
        People
    </div>
    <div class="level-right">
        <a routerLink="/menu/community">
          <app-icon source="search-black.svg"></app-icon>
        </a>
    </div>
  </div>

  <ng-container *ngIf="privateChats?.length > 0">
    <div class="card has-no-border-radius-mobile">
      <app-a *ngFor="let privateChat of privateChats; last as last" [route]="['/chat', 'private', privateChat.userId]" class="has-border">
        <div class="level is-mobile list-tab">
          <div class="level-left is-mobile">
            <figure class="image is-32x32 has-margin-right-sm">
              <app-user-avatar [avatarUrl]="privateChat.iconUrl"></app-user-avatar>
            </figure>
            <span [class.text-body-2]="privateChat.unreadMessageCount" class="cut-text text-body-1">
              {{privateChat.name}}
            </span>
          </div>
          <div class="level-right">
            <app-counter *ngIf="privateChat.unreadMessageCount" [number]="privateChat.unreadMessageCount"></app-counter>
          </div>
        </div>
      </app-a>
    </div>
  </ng-container>
</ng-container>

<ng-container *isDesktop>
  <div class="level has-margin-bottom-mlg">
    <div class="level-left">
      <div class="level-item has-text-weight-bold">
        People
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <a routerLink="/menu/community">
          <app-icon source="search-black.svg"></app-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="has-margin-bottom-mlg">
    <app-a *ngFor="let privateChat of privateChats; last as last" [route]="['/chat', 'private', privateChat.userId]">
      <div class="level items" [class.has-margin-bottom-md]="!last">
        <div class="level-item">
          <figure class="has-margin-right-sm">
            <p class="image is-32x32 image-notification">
              <app-user-avatar [avatarUrl]="privateChat.iconUrl"></app-user-avatar>
              <app-notifications-number *ngIf="privateChat.unreadMessageCount" [number]="privateChat.unreadMessageCount"
                                        class="notifications-number"></app-notifications-number>
            </p>
          </figure>
        </div>
        <span [class.text-body-2]="privateChat.unreadMessageCount"
              class="cut-text text-body-1">
            {{privateChat.name}}
        </span>
      </div>
    </app-a>
  </div>
  <!-- <div *ngIf="privateChats.length > maxPrivateChats">
    <app-a [route]="['/menu','community']">
      <span class="text-body-2">Show all</span>
    </app-a>
  </div> -->
</ng-container>
