import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from '@angular/core';
import {DropdownComponent} from '../dropdown/dropdown.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { Role } from '../../../@core/models';

@Component({
  selector: 'app-role-dropdown',
  templateUrl: './role-dropdown.component.html',
  styleUrls: ['./role-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleDropdownComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleDropdownComponent extends DropdownComponent implements OnInit, ControlValueAccessor {
  @Input() currentUserRole: Role;

  compareFn = ((a: any, b: any) => a[this.optionKey] === b);

  get triggerText() {
    if (!this.value) {
      return this.placeholder;
    }

    const option = this.availableOptions.find(o => this.compareFn(o, this.value));

    if (option) {
      return option.name;
    } else {
      return '-';
    }
  }

  ngOnInit() {
    const roles = Object.keys(Role).filter(x => x !== 'Admin' || this.currentUserRole === 'Admin').map((value, index) => {
      return {
        name: Role[value],
        id: index,
      };
    });

    this.availableOptions = roles;
  }
}
