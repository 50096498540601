import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, Input} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { MessageEditDialogComponent } from '../message-edit-dialog/message-edit-dialog.component';
import {ChatMessage} from '../../components/chat/chat.component';
import { ChatState } from '../../chat.state';
import { Channel, ChannelsLoading, Message } from '../../channels';
import { DialogService } from '../../../@core/modules';
import { UnsetChannel, SetCurrentChannel, LoadChannelMessages, DeleteChannel, FollowChannel, UnfollowChannel, LoadChannels, AddMessage } from '../../chat.actions';
import { SendWebSocketMessage } from '../../../@core/store/send-websocket-message';
import { ProfileState } from '../../../@core/modules/profile/profile.state';
import { Profile } from '../../../@core/modules/profile/profile';
import { AppState } from '../../../@core/store/app.state';
import { tap, filter, first } from 'rxjs/operators';
import { IonSelect, Platform } from '@ionic/angular';
import { Navigate } from '@ngxs/router-plugin';
import { ModalService } from '../../../@core/modules';
import { PageComponentBase } from '../../../@core/components/component-base';

@Component({
  selector: 'app-channel-chat-page',
  templateUrl: './channel-chat-page.component.html',
  styleUrls: ['./channel-chat-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelChatPageComponent extends PageComponentBase implements OnInit, OnDestroy {
  @Select(ChatState.currentChannel) currentChannel$: Observable<Channel>;
  @Select(ChatState.currentChannelMessages) currentChannelMessages$: Observable<Message[]>;
  @Select(ChatState.loading) loading$: Observable<ChannelsLoading>;
  @Select(ProfileState.user) user$: Observable<Profile>;
  
  @Input() deleteText = 'Delete';
  @ViewChild('moreMenuRef') moreMenuRef: IonSelect;

  currentChannelId: number;

  sub: Subscription;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private modalService: ModalService,
    platform: Platform) {
    super(platform);
  }

  init() {
    this.store.dispatch(new LoadChannels());

    // wait until ws is connected
    this.store.select(AppState.webSocketConnected).pipe(
      filter(connected => connected),
      first(),
      tap(_ => {
        this.sub = this.route.paramMap.subscribe(params => {
          const newChannelId = +params.get('channelId');

          if (this.currentChannelId) {
            this.leaveChannel(this.currentChannelId);
          }

          this.setCurrentChannel(newChannelId);
          this.loadChannelMessages(newChannelId);
          this.joinChannel(newChannelId);

          this.currentChannelId = newChannelId;
        });
      })
    ).subscribe();
  }

  destroy(): void {
    this.leaveChannel(this.currentChannelId);
    this.sub.unsubscribe();
    this.store.dispatch(new UnsetChannel());
  }

  onMessageEditClick(event) {
    this.dialogService.open(MessageEditDialogComponent, {
      width: 500,
      data: {
        chatType: 'channel',
        message: event,
        currentChannelId: this.currentChannelId
      }
    });
  }

  setCurrentChannel(channelId: number) {
    this.store.dispatch(new SetCurrentChannel(channelId));
  }

  loadChannelMessages(channelId: number) {
    this.store.dispatch(new LoadChannelMessages(channelId));
  }

  joinChannel(channelId: number) {
    this.store.dispatch(new SendWebSocketMessage('JoinChannel', channelId));
  }

  leaveChannel(channelId: number) {
    this.store.dispatch(new SendWebSocketMessage('LeaveChannel', channelId));
  }

  sendMessage(chatMessage: ChatMessage) {
    if (chatMessage.messageId) {
      this.store.dispatch(new SendWebSocketMessage('EditMessage', this.currentChannelId, chatMessage.messageId, chatMessage.message,));
    } else {
      const messageCorrelationId = Math.random().toString(36).slice(2, 7);
      this.store.dispatch(new SendWebSocketMessage('SendMessageV2', this.currentChannelId, chatMessage.message, messageCorrelationId));
      
      const user = this.store.selectSnapshot(ProfileState.user);
      const currentDate = new Date();
      // necessary so messages that are being sent stays at the bottom of the chat
      currentDate.setMinutes(currentDate.getMinutes() + 2);

      const newMessage = {
        chatId: this.currentChannelId,
        text: chatMessage.message,
        userAvatarUrl: user.avatarUrl,
        messageCorrelationId: messageCorrelationId,
        userFullName: user.firstName ? user.firstName + " " + user.lastName : user.email,
        time: currentDate.toString(),
        edited: false,
        canEdit: false,
        isSending: true
      }
      this.store.dispatch(new AddMessage(newMessage));
    }
  }

  deleteChannel() {
    this.store.dispatch(new DeleteChannel(this.currentChannelId));
  }

  followStatusChange(followStatus: boolean) {
    if (followStatus) {
      this.store.dispatch(new FollowChannel(this.currentChannelId));
    } else {
      this.store.dispatch(new UnfollowChannel(this.currentChannelId));
    }
  }

  onMoreMenuChange(event) {
    if (event.detail.value === 'edit') {
      this.store.dispatch(new Navigate(['/chat/edit/',this.currentChannelId]));
    } else if (event.detail.value === 'delete') {
      this.modalService.openMessageModal({
        message: `Are you sure you want to ${this.deleteText.toLowerCase()}`,
        primaryButtonText: this.deleteText,
        secondaryButtonText: 'Cancel'
      }).subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(new DeleteChannel(this.currentChannelId));
        }
      });
    } else if (event.detail.value == 'follow') {
      this.followStatusChange(true);
    } else if (event.detail.value == 'unfollow'){
      this.followStatusChange(false);
    }
    this.moreMenuRef.value = null;
  }

}
