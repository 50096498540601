<div class="page-title-wrapper is-marginless">
  <h1 class="page-title">{{community ? 'Settings' : 'Create new community'}}</h1>
</div>
<div class="text-info has-margin-bottom-mlg">Community name and Logo is displayed for the user when they login to
  your space.
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="field has-margin-bottom-mlg">
    <label class="label">Community name</label>
    <div class="control is-fullwidth">
      <input
        class="input"
        formControlName="name"
        maxlength="40"
        placeholder="Enter community name">
    </div>
  </div>

  <div class="field has-margin-bottom-mlg">
    <label class="label">Community logo</label>
    <app-image-upload
      formControlName="logo"
      uploadPath="v2/communities/upload-image"
      [singleMode]="true"
      [aspectRatio]="1.666">
    </app-image-upload>
  </div>
  <div class="field is-grouped has-margin-top-lg">
    <div class="control">
      <button type="submit" class="button is-rounded is-secondary">
        {{community ? 'Save changes' : 'Create'}}
      </button>
    </div>
    <div class="control">
      <button type="button" class="button is-rounded" (click)="cancel.emit()">Cancel
      </button>
    </div>
  </div>
</form>
