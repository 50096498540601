import {Component, Input, EventEmitter, Output, ChangeDetectionStrategy, OnInit, OnChanges} from '@angular/core';
import { Feature } from '../../../@core/models';
import { Notifications } from '../../../@core/models/notifications';
import { Permission } from '../../../@core/services/authorization.service';

export interface MenuItem {
  id: string;
  text?: string;
  link: string;
  features?: Feature[];
  permissions?: Permission[];
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnChanges { 
  @Input() notificationCounts: Notifications;
  @Input() hideUsersPage: boolean;
  @Input() hideBilling: boolean;
  @Output() menuItemClick = new EventEmitter<string>();

  primaryMenu: MenuItem[] = [
    {
      id: 'news',
      text: 'News',
      link: '/news',
      features: ['News']
    },
    {
      id: 'polls',
      text: 'Polls',
      link: '/polls',
      features: ['Polls']
    },
    {
      id: 'events',
      text: 'Events',
      link: '/events',
      features: ['Events']
    },
    {
      id: 'benefits',
      text: 'Benefits',
      link: '/benefits',
      features: ['Benefits']
    },
    {
      id: 'locations',
      text: 'Locations',
      link: '/locations',
      features: ['Locations']
    }
  ];

  secondaryMenu: MenuItem[] = [
    {
      id: 'statistics',
      text: 'Dashboard',
      link: '/statistics',
      permissions: ['statistics_view'],
    },    
    {
      id: 'settings',
      text: 'Community settings',
      link: '/community/settings'
    }
  ];

  ngOnInit(): void {
    this.addUsersTab();
    this.addBillingTab();
  }

  ngOnChanges(): void {
    this.addUsersTab();
    this.addBillingTab();
  }

  addUsersTab() {
    const users = {
      id: 'users',
      text: 'Users',
      link: '/users',
    };

    if (this.hideUsersPage) {
      this.secondaryMenu = this.secondaryMenu.filter(item => item.id !== 'users')
      return;
    }

    if (this.secondaryMenu.filter(item => item.id == 'users').length > 0) return;

    this.secondaryMenu = [
      ...this.secondaryMenu.slice(0, 1),
      users,
      ...this.secondaryMenu.slice(1)
    ]
  }

  addBillingTab() {
    const billing = 
    {
      id: 'billing',
      text: 'Billing',
      link: '/billing',
      permissions: ['billing_manage' as Permission],
    }

    if (this.hideBilling) {
      this.secondaryMenu = this.secondaryMenu.filter(item => item.id !== 'billing')
      return;
    }

    if (this.secondaryMenu.filter(item => item.id == 'billing').length > 0) return;

    this.secondaryMenu.push(billing);
  }
}
