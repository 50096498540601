import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { RecentBenefit } from '../../../@core/models/recents';

@Component({
  selector: 'app-last-benefits',
  templateUrl: './last-benefits.component.html',
  styleUrls: ['./last-benefits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastBenefitsComponent {
  @Input() benefits: RecentBenefit[];
}
