import {Component, Inject, OnDestroy, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {Subscription} from 'rxjs';
import { DialogRef, DIALOG_DATA } from '../../../@core/modules';
import { SendWebSocketMessage } from '../../../@core/store/send-websocket-message';

@Component({
  selector: 'app-message-edit-dialog',
  templateUrl: './message-edit-dialog.component.html',
  styleUrls: ['./message-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageEditDialogComponent implements OnInit, OnDestroy {
  sub: Subscription;

  constructor(
    private store: Store,
    private dialogRef: DialogRef,
    private actions$: Actions,
    @Inject(DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.sub = this.actions$.pipe(ofActionSuccessful(SendWebSocketMessage)).subscribe(_ => this.dialogRef.close());
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onMessageEditSubmit(text: string) {
    const messageId = this.data.message.id;
    if (this.data.chatType === 'channel') {
      this.store.dispatch(new SendWebSocketMessage('EditMessage', this.data.currentChannelId, messageId, text));
    } else {
      this.store.dispatch(new SendWebSocketMessage('EditPrivateMessage', {
        message: text,
        messageId: messageId,
        recipientId: this.data.currentRecipientId
      }));
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
