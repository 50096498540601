import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class PushNotificationService {
  constructor(private httpClient: HttpClient) { }

  registerNotificationToken(deviceId: string, registrationId: string) {
    return this.httpClient.post(`${environment.baseApiUrl}v2/push-notifications`, {
      deviceId,
      registrationId
    });
  }

  unregisterNotificationToken(deviceId: string) {
    return this.httpClient.delete(`${environment.baseApiUrl}v2/push-notifications/${deviceId}`);
  }
}
