import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';
import { Notifications } from '../models/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private httpClient: HttpClient) {
  }

  get(): Observable<Notifications> {
    return of({
      'news': 0,
      'polls': 0,
      'chat': 0,
      'events': 0,
      'benefits': 0
    });
  }
}
