import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { PrivateChat } from '../../channels';

@Component({
  selector: 'app-private-chats',
  templateUrl: './private-chats.component.html',
  styleUrls: ['./private-chats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivateChatsComponent {
  @Input() privateChats: PrivateChat[];
}
