import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnInit {
  @Input() value: number;
  @Input() max = 100;
  @Input() split = 5;

  steps: number[];
  stripes: number[];

  constructor() { }

  ngOnInit() {
    const step = this.max / (this.split - 1);
    this.stripes = Array(this.split - 1).fill(0);
    this.steps = Array(this.split).fill(0).map((_, i) => i * step);
  }
}
