import {ChangeDetectionStrategy, Component, Input, Output, EventEmitter} from '@angular/core';
import { Notifications } from '../../../@core/models/notifications';
import { getMenuIconUrl } from '../../utilities';
import { MenuItem } from '../menu/menu.component';

@Component({
  selector: 'app-bottom-bar-layout',
  templateUrl: './bottom-bar-layout.component.html',
  styleUrls: ['./bottom-bar-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomBarLayoutComponent {
  @Input() notificationCounts: Notifications;
  @Input() menuItemId: string;
  @Output() menuItemClick = new EventEmitter<string>();

  menu: MenuItem[] = [
    {
      id: 'news',
      link: 'news',
      features: ['News']
    },
    {
      id: 'events',
      link: 'events',
      features: ['Events']
    },
    {
      id: 'chat',
      link: 'chat',
      features: ['Channels']
    },
    {
      id: 'polls',
      link: 'polls',
      features: ['Polls']
    },
    {
      id: 'benefits',
      link: 'benefits',
      features: ['Benefits']
    },
    {
      id: 'menu',
      link: 'menu'
    }
  ];

  getIconUrl(id: string) {
    return getMenuIconUrl(id, this.menuItemId === id);
  }
}
