<div class="level is-mobile items">
  <div class="level-left">
    <div class="level-item">
      <app-icon
        [source]="source"
        [sizeClass]="sizeClass"
        [notificationCount]="notificationCount"
      ></app-icon>
    </div>
    <div class="level-item {{textClass}}" [class.text-body-2]="bold" [class.text-info]="smallText" [class.disabled]="disabled">
      {{text}}
    </div>
  </div>
</div>
