import { Component, OnInit, ChangeDetectionStrategy, Input, Output,
  EventEmitter, HostBinding, ViewChild, OnDestroy, Optional } from '@angular/core';
import { Platform, IonInfiniteScroll, IonSelect, ModalController, IonRouterOutlet } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { AppState } from '../../../@core/store/app.state';
import { Community } from '../../../@core/models';
import { Observable, Subscription } from 'rxjs';
import { Profile } from '../../../@core/modules/profile/profile';
import { ProfileState } from '../../../@core/modules/profile/profile.state';
import { Navigate } from '@ngxs/router-plugin';
import { Logout } from '../../../@core/store/app.actions';
import { CommunitySwitchPageComponent } from '../../../@core/containers/community-switch-page/community-switch-page.component';
import { AddNewItemPageComponent } from '../../../@core/containers/add-new-item-page/add-new-item-page.component';
import { App } from '@capacitor/app';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageComponent implements OnInit, OnDestroy {
  @Select(AppState.community) activeCommunity: Observable<Community>;
  @Select(AppState.totalCommunities) totalCommunities$: Observable<number>;
  @Select(ProfileState.user) user$: Observable<Profile>;

  @Input() title: string;
  @Input() titleVisibility: 'desktop' | 'mobile' | 'both' = 'both';
  @Input() isInfiniteList = false;
  @Input() showHeader = true;
  @Input() showCommunity = false;
  @Input() showProfile = false;
  @Input() backRoute: string;
  @Input() grayToolbar = false

  @Output() scroll = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild('profileMenuRef') profileMenuRef: IonSelect;
  @ViewChild('addItemMenuRef') addItemMenuRef: IonSelect;
  
  @HostBinding('class.ion-page') isMobile = false;

  darkMode = false;
  totalCommunities: number;
  
  private sub: Subscription;
  private backSub: Subscription;

  constructor(
    private store: Store,
    public platform: Platform,
    private modalController: ModalController,
    private location: Location,
    @Optional() private routerOutlet?: IonRouterOutlet) {
    this.isMobile = platform.is('mobile');
  }

  ngOnInit() {
    this.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    this.sub = this.totalCommunities$.subscribe((count) => {
      this.totalCommunities = count;
    });

    this.backSub = this.platform.backButton.subscribe(() => {
      if (this.backRoute) {
        this.store.dispatch(new Navigate([this.backRoute]));
      }
      if (this.routerOutlet?.canGoBack()) {
       this.location.back();
      }

      if(this.location.path() !== '/news') {
        this.store.dispatch(new Navigate(['/news']));
      } else {
         App.exitApp();   
      }      
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.backSub) this.backSub.unsubscribe();
  }

  enableInfiniteScroll() {
    this.infiniteScroll.disabled = false;
  }

  disableInfiniteScroll() {
    this.infiniteScroll.disabled = true;
  }

  async onCommunityClick() {
    if (this.totalCommunities <= 1) return;

    const modal = await this.modalController.create({
      component: CommunitySwitchPageComponent,
      initialBreakpoint: 0.5,
      breakpoints: [0, 0.5, 1],
      backdropDismiss: false
    });

    await modal.present();
  }

  async onAddNewItemClick() {
    const modal = await this.modalController.create({
      component: AddNewItemPageComponent,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      cssClass: 'add-new-item-modal',
      handle: false,
      swipeToClose: false,
      backdropDismiss: false
    });

    await modal.present();
  }

  onProfileMenuChange(event) {
    if (event.detail.value === 'logout') {
      this.store.dispatch(new Logout());
    }
    this.profileMenuRef.value = null;
  }

}
