import {ChangeDetectionStrategy, Component, EventEmitter, Output, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-message-edit-form',
  templateUrl: './message-edit-form.component.html',
  styleUrls: ['./message-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditFormComponent implements OnInit {
  @Input() message: string;

  @Output() messageEditSubmit = new EventEmitter();
  @Output() cancel = new EventEmitter();

  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      message: [this.message, [Validators.required]]
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.messageEditSubmit.emit(this.form.value.message);
    }
  }
}
