<div class="has-text-centered">
  <h1
    *ngIf="data.title"
    class="text-list-item has-text-weight-bold">
    {{data.title}}
  </h1>
  <p class="text-body-1 has-margin-bottom-mlg">{{data.message}}</p>
  <div class="field is-grouped has-margin-top-lg columns is-centered is-marginless">
    <div class="control">
      <button
        class="button is-rounded is-secondary"
        (click)="close(true)">
        {{data.primaryButtonText}}
      </button>
    </div>
    <div class="control">
      <button
        *ngIf="data.secondaryButtonText"
        class="button is-rounded"
        (click)="close(false)">
        {{data.secondaryButtonText}}
      </button>
    </div>
  </div>
</div>
