<span
  class="icon image-notification {{sizeClass}}"
  [ngClass]="{'with-background': withBackground, 'with-hover-background': backgroundOnHover}">
  <img
    *ngIf="formattedPath"
    [src]="formattedPath"
    [alt]="path"
    [ngClass]="{'is-rounded': isRounded}">
  <app-notifications-number
    *ngIf="notificationCount"
    [number]="notificationCount"
    class="notifications-number">
  </app-notifications-number>
</span>