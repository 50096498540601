import {Directive} from '@angular/core';
import {Platform} from '@ionic/angular';

@Directive({
  selector: '[platform]',
  exportAs: 'platform'
})
export class PlatformDirective {
  get mobile() {
    return this.platform.is('mobile');
  }

  get desktop() {
    return this.platform.is('desktop');
  }

  constructor(private platform: Platform) {}
}
