<ng-container *isMobile platform #p="platform">
    <div *ngIf="channels.length > 0" class="has-padding-left-md text-subheading-1 has-padding-bottom-sm has-margin-top-md">
      Channels ({{channels.length}})
    </div>

    <div class="card has-no-border-radius-mobile">
      <app-a *ngFor="let channel of channels" [route]="['/chat','channel', channel.id]">
        <div [ngClass]="channel.unreadMessageCount>0 ? 'text-body-2' : 'text-body-1'" class="level is-mobile list-tab">
          <span class="has-margin-right-sm" [class.text-body-2]="channel.unreadMessageCount > 0">#{{channel.name}}</span>
          <app-counter *ngIf="channel.unreadMessageCount" [number]="channel.unreadMessageCount"></app-counter>
        </div>
      </app-a>
    </div>

    <div class="loading-container" *ngIf="loading.channels && !loading.forceReload">
      <img height="60px" width="60px" src="assets/loaders/loader.svg" />
    </div>
</ng-container>


<ng-container *isDesktop>
  <div class="has-margin-bottom-xlg">
    <div class="level is-marginless has-margin-bottom-mlg">
      <div class="level-left">
        <div class="level-item text-subheading-1">
          Channels
        </div>
      </div>
      <div class="level-right" *ifHasPermission="'channels_manage'">
        <div class="level-item">
          <a [routerLink]="['/chat','create']">
            <app-icon source="plus.svg"></app-icon>
          </a>
        </div>
      </div>
    </div>
    <app-a *ngFor="let channel of channels" [route]="['/chat','channel', channel.id]">
      <div class="level items has-margin-bottom-md text-body-1">
        <div [ngClass]="{'text-body-2': channel.unreadMessageCount>0}" class="level-item">
          <span class="has-margin-right-sm" [class.text-body-2]="channel.unreadMessageCount>0">#{{channel.name}}</span>
          <app-notifications-number *ngIf="channel.unreadMessageCount"
                                    [number]="channel.unreadMessageCount"></app-notifications-number>
        </div>
      </div>
    </app-a>
  </div>
</ng-container>
