import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Store} from '@ngxs/store';
import {map} from 'rxjs/operators';
import {AppState} from '../store/app.state';
import {Observable} from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root'
})
export class CommunitySelectedGuard implements CanActivate {
  constructor(private store: Store) {
  }

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(AppState.community).pipe(
      map(community => {
        if (community) {
          return true;
        } else {
          this.store.dispatch(new Navigate(['/setup']));
          return false;
        }
      })
    );
  }
}
