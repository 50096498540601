import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService } from './analytics.service';

@Injectable({providedIn: 'root'})
export class ToastService {

  constructor(
    private toastr: ToastrService,
    private analyticsService: AnalyticsService) { }

  success(message: string, title: string = 'Success') {
    this.toastr.success(message, title, {
      onActivateTick: true
    });
    this.sendToastToAnalytics(message);
  }

  error(message: string, title: string = 'Error', time: number = 5000) {
    // default toast to live is 5000ms
    this.toastr.error(message, title, {
      onActivateTick: true,
      timeOut: time
    });
    this.sendToastToAnalytics(message);
  }

  info(message: string, title: string = 'Info') {
    this.toastr.info(message, title, {
      onActivateTick: true
    });
    this.sendToastToAnalytics(message);
  }

  private sendToastToAnalytics(message: string){
    this.analyticsService.log('view_toast_message', {
      toast_message: message
    });
  }
}
