<div class="image-upload" appDragDrop (fileDrop)="handleFileUpload($event)" *ngIf="allowReupload || images.length < maxImages" platform #p="platform">
  <!-- <img *ngIf="!singleMode" src="assets/icons/image.svg" class="has-margin-right-mlg"> -->
  <div class="file-wrapper">
    <div class="file-center">
      <label class="file-label">
        <input class="file-input" type="file" accept="image/*" (change)="handleFileUpload($event)" (click)="onClick($event)">
        <app-icon sizeClass="is-20x20" [ngClass]="{'has-margin-right-md': p.mobile}" source="new/ic-upload.svg"></app-icon>
        <span class="text-body-small" *isMobile>Upload image</span>

        <span class="drag-drop-label" *isDesktop>
          <span class="text-body-2">Drop your image here</span>
          <span *ngIf="!singleMode" class="text-body-1"> (up to {{maxImages}} pictures)</span>
        </span>

        <span class="button is-rounded" *isDesktop>
          <span class="file-label">
            Upload
          </span>
        </span>
      </label>
    </div>
    <!-- <div *ngIf="!singleMode" class="has-margin-top-sm text-body-1">You can add up to {{maxImages}} pictures</div> -->
  </div>
</div>

<div class="image-list">
  <ng-container *ngFor="let image of images; let idx=index">
    <div class="image-upload-container">
    <!-- <div class="image-upload-container" style.height="calc(10rem / {{aspectRatio}} + 1.875rem)"> -->
      <ng-container *ngIf="image.status === 'idle'">
        <div class="image-container {{roundCrop ? 'is-rounded' : ''}}">
          <img class="image-item" [src]="image.url">
          <div class="icon-button" (click)="removeImage(idx)">
            <app-icon sizeClass="is-16x16" source="new/ic-cross-16.svg"></app-icon>
          </div>
        </div>

      </ng-container>
      <div class="loader-background" *ngIf="image.status === 'uploading'">
        <img height="60px" width="60px" src="assets/loaders/loader.svg" />
        <span>{{ image.progress }}%</span>
      </div>
    </div>
  </ng-container>
</div>
