<div class="card" [class.dark]="options?.isDarkBackground" [class.no-corner-radius]="options?.hasNoCorerRadius">
  <div class="card-content" [class.has-no-padding]="options?.noPadding">
    <ng-container [ngSwitch]="renderMethod">

      <div *ngSwitchCase="'text'" [innerHTML]="content"></div>

      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="$any(content); context: context"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="$any(content)"></ng-container>
      </ng-container>

    </ng-container>
  </div>
</div>
