import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'month',
  pure: true
})
export class MonthPipe implements PipeTransform {
  public monthsFullNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'];
  public monthsStrippedNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  transform(value: any, isFullMonthName?: boolean, includeMonthDay?: boolean, isUppercaseStrippedName: boolean = false): any {
    if (value) {
      if (isUppercaseStrippedName) {
        this.monthsStrippedNames = this.monthsStrippedNames.map(strippedName => strippedName.toUpperCase());
      }
      if (value instanceof Array) {
        value = this.getTransformedDates(value, includeMonthDay, isFullMonthName);
      } else {
        value = this.getTransformedDate(value, includeMonthDay, isFullMonthName);
      }
    }
    return value;
  }

  getTransformedDates(value: Array<string>, includeMonthDay: boolean, isFullMonthName: boolean) {
    const transformedDates = [];
    for (const dateString of value) {
      transformedDates.push(this.getTransformedDate(dateString, includeMonthDay, isFullMonthName));
    }
    return transformedDates;
  }

  getTransformedDate(value: any, includeMonthDay: boolean, isFullMonthName: boolean) {
    const month = this.getMonth(value);
    const monthName = isFullMonthName ? this.getFullMonthValue(month) : this.getStrippedMonthValue(month);
    if (includeMonthDay) {
      const day = new Date(value).getDate();
      return `${monthName} ${day}`;
    } else {
      return monthName;
    }
  }

  getMonth(value: any) {
    const date = new Date(value);
    return date.getMonth();
  }

  getFullMonthValue(month: any) {
    return this.monthsFullNames[month];
  }

  getStrippedMonthValue(month: any) {
    return this.monthsStrippedNames[month];
  }

}
