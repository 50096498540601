import {ComponentRef, Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {
  EmptyStatePlaceholderComponent,
  EmptyStatePlaceholderType
} from '../components/empty-state-placeholder/empty-state-placeholder.component';

@Directive({
  selector: '[emptyStatePlaceholder]'
})
export class EmptyStatePlaceholderDirective {
  placeholderComponent: ComponentRef<EmptyStatePlaceholderComponent>;
  container: ViewContainerRef;

  @Input() emptyStatePlaceholderText: string;
  @Input() emptyStatePlaceholderType: EmptyStatePlaceholderType;
  @Input() emptyStatePlaceholder: boolean; // = no content

  constructor(
    private templateRef: TemplateRef<any>,
    private vcr: ViewContainerRef
  ) {
    this.vcr.createEmbeddedView(this.templateRef);
  }

  ngOnChanges() {
    if (this.emptyStatePlaceholderType && this.emptyStatePlaceholderText) {
      this.vcr.clear();

      if (this.emptyStatePlaceholder) {
        this.placeholderComponent = this.vcr.createComponent(EmptyStatePlaceholderComponent);
        this.placeholderComponent.instance.type = this.emptyStatePlaceholderType;
        this.placeholderComponent.instance.text = this.emptyStatePlaceholderText;
      } else {
        this.vcr.createEmbeddedView(this.templateRef);
      }
    }
  }
}
