<ng-container *ngIf="platform.is('desktop'); else mobile">
  <ng-container *ngIf="!isInfiniteList; else infiniteDesktop">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
</ng-container>


<ng-template #infiniteDesktop>
  <div class="container" infiniteScroll [infiniteScrollDistance]="1" (scrolled)="scroll.emit()">
    <div class="page-title-wrapper">
      <h1 class="page-title">
        <ng-container *ngIf="titleVisibility === 'both' || titleVisibility === 'desktop'">{{title}}</ng-container>
      </h1>
      <ng-container *ngTemplateOutlet="actionButton"></ng-container>
    </div>

    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #mobile>
  <ion-header *ngIf="showHeader">
    <ion-toolbar mode="ios" [ngClass]="{'dark-toolbar': darkMode, 'gray-toolbar': grayToolbar}">
      <div class="title-container">
        <ion-buttons slot="start">
          <ion-back-button [defaultHref]="backRoute" text=""></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ng-content select="[right-icon]"></ng-content>
        </ion-buttons>
        <ng-content select="[header-content]" slot="end"></ng-content>
        <ion-title>
          <ng-content select="[title-content]"></ng-content>
          <ng-container *ngIf="titleVisibility === 'both' || titleVisibility === 'mobile'">{{title}}</ng-container>
          <div *ngIf="showCommunity" class="community-container" (click)="onCommunityClick()">
            <app-icon [ngClass]="{'is-invisible': totalCommunities < 2}" [source]="'nav-down-small.svg'" class="has-margin-right-xs"></app-icon>
            <app-community-logo class="logo" [logoUrl]="(activeCommunity | async)?.logoUrl" [name]="(activeCommunity | async)?.name ?? ''" alternative="name"></app-community-logo>
          </div>
        </ion-title>
        <ng-container *ifHasPermission="['news_manage', 'polls_manage', 'benefits_manage', 'events_manage', 'channels_manage']">
          <div *ngIf="titleVisibility === 'desktop'" class="has-margin-right-sm" (click)="onAddNewItemClick()">
            <app-icon
              source="new/ic-plus-16.svg"
              sizeClass="is-medium"
              [withBackground]="true"
            ></app-icon>
          </div>
        </ng-container>
        <div *ngIf="showProfile" class="has-margin-right-sm">
          <ion-item [hidden]="true">
            <ion-select #profileMenuRef (ionChange)="onProfileMenuChange($event)" interface="action-sheet">
              <ion-select-option value="logout">Logout</ion-select-option>
            </ion-select>
          </ion-item>
          <a class="navbar-link is-arrowless" (click)="profileMenuRef.open()">
            <span class="profile level is-semi-round">
              <figure class="image is-32x32  has-margin-right-sm">
                <app-user-avatar [avatarUrl]="(user$ | async)?.avatarUrl"></app-user-avatar>
              </figure>
            </span>
          </a>
        </div>
      </div>
    </ion-toolbar>
    <ng-content select="[subheader-content]"></ng-content>
  </ion-header>

  <ion-content class="ion-content">
    <ion-refresher slot="fixed" (ionRefresh)="refresh.emit($event)" *ngIf="refresh.observers.length">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <ng-container *ngTemplateOutlet="content"></ng-container>

    <ion-infinite-scroll threshold="100px" (ionInfinite)="scroll.emit($event)" *ngIf="isInfiniteList">
      <ion-infinite-scroll-content>
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-content>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-template #actionButton>
  <ng-content select="[action-button]"></ng-content>
</ng-template>
