<!-- <ion-header mode="ios">
  <ion-toolbar>
    <ion-title>Select Community</ion-title>
      <ion-icon slot="end" size="large" name="close" (click)="dismiss()"></ion-icon>
  </ion-toolbar>
</ion-header> -->
<div class="inner-content has-padding-bottom-md" platform #p="platform">
    <div class="has-margin-bottom-md has-margin-top-md">
      <div class="text-subheading-1 modal-header">Add new</div>
      <img class="corner-element has-margin-right-md has-margin-top-md" (click)="dismiss()" src="assets/icons/exit.svg" />
    </div>

    <div class="feature" *ngFor="let featureProperty of featureProperties">
      <app-button
        *ngIf="featureProperty.enabled"
        [ngClass]="{'block has-margin-top-sm has-margin-bottom-sm': p.mobile}"
        [text]="featureProperty.name"
        [iconSource]="featureProperty.icon"
        (click)="featureProperty.onClickFunction()">
      </app-button>
    </div>
</div>
