import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-full-date',
  templateUrl: './full-date.component.html',
  styleUrls: ['./full-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullDateComponent {
  @Input() date: string;
  @Input() dateWithDiff: boolean = false;
 
  getDifferentInDays() {
    const date = new Date(this.date);
    const currentDate = new Date();
    return Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24).toString(); 
  }
}
