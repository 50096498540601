<div *ngIf="chat" class="card has-no-border-radius-mobile" platform #p="platform">
  <div class="card-content" [ngClass]="{'private-chat': chatType==='private'}">
    <div class="user has-padding-left-mlg" *isDesktop>
      <div class="page-title-wrapper">
        <div class="back-element">
          <div class="text-subheading-1">
            <ng-container *ngIf="chatType==='private'; else channelTemplate">
              <div class="level is-mobile">
                <div class="level-left">
                    <span class="image is-32x32 has-margin-right-md">
                      <app-user-avatar [avatarUrl]="asPrivateChat.iconUrl"></app-user-avatar>
                    </span>
                  <h1 class="text-subheading-1">{{chat.name}}</h1>
                </div>
              </div>
            </ng-container>
            <ng-template #channelTemplate>
              <div class="text-subheading-1">#{{chat.name}}</div>
            </ng-template>
          </div>
        </div>
        <a class="corner-element" *ngIf="chatType!=='private'">
          <app-more-button
            [extraMargin]="true"
            [optionalDropdownOption]="asChannel.isFollowing ? 'Unfollow' : 'Follow'"
            (delete)="delete.emit()"
            (optionalDropdownOptionClick)="followStatusChange.emit(!asChannel.isFollowing)"
            [editRoute]="['/chat/edit/', chat.id]"
            deletePermission="channels_manage">
          </app-more-button>
        </a>
      </div>
    </div>

    <div class="messages" [class.has-margin-bottom-md]="p.desktop" #messagesContainer [class.is-flex]="loading">
      <app-messages
        [messages]="messages"
        [chatType]="chatType"
        [user]="user"
        (messageEditClick)="messageEditClick.emit($event)"
        (messageEdit)="editModeMessage = $event"
        *ngIf="!loading">
      </app-messages>
      <div class="loading-container" *ngIf="loading">
        <div class="loader is-loading"></div>
      </div>
    </div>
    
    <app-message-input
      [user]="user"
      [editModeMessage]="editModeMessage"
      (messageSend)="onMessageSubmit($event)">
    </app-message-input>
  </div>
</div>
