<p *ngIf="events && events.length > 0" class="text-body-2 has-margin-bottom-mlg has-margin-top-xlg">
  Closest events
</p>

<a *ngFor="let event of events" [routerLink]="['/events', event.id]">
  <div class="level has-margin-bottom-mlg">
    <div class="level-left">
      <div class="date-box has-margin-right-md">
        <app-event-month-day [eventDate]="event.eventDate" size="sm"></app-event-month-day>
      </div>
      <p class="text-info text-body-2">{{event.title}}</p>
    </div>
  </div>
</a>
