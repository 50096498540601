import {Pipe, PipeTransform} from '@angular/core';
import { Message } from '../../chat/channels';

export class MessagesBlock {
  messages: Message[];
  date: string;
}

@Pipe({
  name: 'messagesDateBlock',
  pure: true
})
export class MessagesDateBlockPipe implements PipeTransform {
  public monthsStrippedNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  transform(messages: Message[], ...args: any[]): any {
    const messagesBlocks: MessagesBlock[] = [];

    if (messages) {
      const messagesSortedByDate = messages
        .slice()
        .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

      for (const message of messagesSortedByDate) {
        const [day, month, year] = this.getDayMonthYear(message.time);
        const formattedDate = this.getFormattedDate(day, month, year);
        const messageBlockIndex = messagesBlocks.findIndex(messageBlock => messageBlock.date === formattedDate);
        if (messageBlockIndex !== -1) {
          messagesBlocks[messageBlockIndex].messages.push(message);
        } else {
          const newMessageBlock = {
            date: formattedDate,
            messages: [message]
          };
          messagesBlocks.push(newMessageBlock);
        }
      }

      return messagesBlocks;
    }

  }

  getDayMonthYear(date: string): Array<number> {
    const timeOfMessage = new Date(date);
    const month = timeOfMessage.getMonth();
    const year = timeOfMessage.getFullYear();
    const day = timeOfMessage.getDate();
    return [day, month, year];
  }

  getFormattedDate(day: number, month: number, year: number): string {
    const todayDate = new Date();
    const isSameYearAndMonth = month === todayDate.getMonth() && year === todayDate.getFullYear();
    if (isSameYearAndMonth) {
      const todayDateDay = todayDate.getDate();
      if (todayDateDay === day) {
        return 'Today';
      } else if (todayDateDay - 1 === day) {
        return 'Yesterday';
      } else {
        return `${year} ${this.monthsStrippedNames[month]} ${day + 1}`;
      }
    } else {
      return `${year} ${this.monthsStrippedNames[month]} ${day + 1}`;
    }
  }


}
