<div class="has-margin-sm">
  <div class="text-body-2">
    Download FellowUp app
  </div>
  <div class="text-body-1 has-margin-bottom-md">
    Scan QR with your phone’s camera or QR app.
  </div>
  <div class="qr-container has-padding-mlg has-margin-bottom-lg has-text-centered">
    <div class="is-180x180">
      <!--<app-icon source="download_qr.svg" class="is-180x180"></app-icon>-->
      <img src="assets/icons/download_qr.svg">
    </div>
  </div>

  <div class="has-text-centered">
    <a class="has-margin-right-md" href="https://apps.apple.com/lt/app/pulse-for-enterprise/id1200297263"
      target="_blank">
      <img src="assets/images/app-store.svg">
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.vulpesinarca.pulseapp&hl=en_US" target="_blank">
      <img src="assets/images/google-play.svg">
    </a>
  </div>


  <a class="corner-element has-margin-smd icon is-24x24" (click)="cancel.emit()">
    <!--<app-icon source="exit.svg" class="icon is-24x24"></app-icon>-->
    <img src="assets/icons/exit.svg">
  </a>

</div>
