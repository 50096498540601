import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekDay',
  pure: true
})
export class WeekDayPipe implements PipeTransform {

  intervals = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  transform(value: any, args?: any): any {
    if (value) {
      const date = new Date(value);
      const weekDay = date.getDay();
      value = this.intervals[weekDay];
    }
    return value;
  }

}
