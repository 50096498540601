import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { Community } from '../../../@core/models';

@Component({
  selector: 'app-user-spaces',
  templateUrl: './user-spaces.component.html',
  styleUrls: ['./user-spaces.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSpacesComponent {
  @Input() communities: Community[];
  @Input() activeCommunity: Community;

  @Output() communitySelect = new EventEmitter();

  onSelect(spaceId) {
    if (spaceId !== this.activeCommunity.id) {
      this.communitySelect.emit(spaceId);
    }
  }
}
