<div class="dropdown is-active text-list-item">
  <div class="dropdown-trigger" (click)="dropdownOpen = !dropdownOpen">
    <ng-content></ng-content>
  </div>
  <div class="dropdown-menu narrow" *ngIf="dropdownOpen" [class.has-margin-right-md]="extraMargin">
    <div class="dropdown-content" (clickOutside)="dropdownOpen = false" exclude=".dropdown-trigger">
      <a class="dropdown-item text-body-1" *ngFor="let option of options; let i = index" (click)="optionClick.emit(i)">{{option}}</a>
    </div>
  </div>
</div>
