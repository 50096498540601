import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-notification-light',
  templateUrl: './notification-light.component.html',
  styleUrls: ['./notification-light.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationLightComponent {
  constructor() { }
}
