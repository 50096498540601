import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { ChatState } from '../../chat.state';
import { Channel } from '../../channels';
import { SetCurrentChannel, UnsetChannel, SaveChannel } from '../../chat.actions';
import { PageComponentBase } from '../../../@core/components/component-base';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-channel-create',
  templateUrl: './channel-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelEditComponent extends PageComponentBase {
  @Select(ChatState.currentChannel) channel$: Observable<Channel>;
  @Select(ChatState.saving) saving$: Observable<boolean>;

  editMode: boolean;

  constructor(private route: ActivatedRoute, private store: Store, platform: Platform) {
    super(platform);
  }

  init() {
    const channelId = +this.route.snapshot.paramMap.get('channelId');
    if (channelId) {
      this.editMode = true;
      this.store.dispatch(new SetCurrentChannel(channelId));
    }
  }

  destroy() {
    this.store.dispatch(new UnsetChannel());
  }

  onSubmitChannel(channel: Channel) {
    this.store.dispatch(new SaveChannel(channel));
  }
}
