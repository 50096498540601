<a class="level" *ngFor="let community of communities" (click)="community.enabled && onSelect(community.id)" [ngClass]="{
    'is-non-clickable': !community.enabled,
    'is-clickable': community.enabled
 }">
  <div class="level-left has-margin-right-mlg">
    <div class="level-item image-container" *ngIf="community.logoUrl">
      <img [src]="community.logoUrl" [alt]="community.id" class="has-margin-right-mlg is-semi-round" />
    </div>
    <div class="is-flex-direction-column">
      <span [class.text-body-2]="community.id === activeCommunity?.id"
        class="text-body-1 has-text-left cut-text">{{community.name}}</span>
      <span *ngIf="!community.enabled" class="has-text-left has-text-pink text-body-1">Pending approval</span>
    </div>
  </div>
    <a class="selected-checkmark" *ngIf="community.id === activeCommunity?.id; else radioNotSelected">
      <img src="assets/icons/radio-selected.svg" alt="not selected" />
    </a>
</a>

<ng-template #radioNotSelected>
  <a class="rectangle"></a>
</ng-template>