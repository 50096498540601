import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackDialogComponent } from './containers/feedback-dialog/feedback-dialog.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FeedbackDialogComponent,
    FeedbackFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class FeedbackModule { }
