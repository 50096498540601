import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Role } from '../../../@core/models';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleComponent {
  @Input() role: Role;

  get firstChar() {
    return this.role.charAt(0);
  }

  get isDarkTheme() {
    if (this.role) {
      return [Role.Admin, Role.Editor].includes(this.role);
    }
  }
}
