import { Platform } from '@ionic/angular';
import { OnInit, OnDestroy, Component, Injectable } from '@angular/core';

@Injectable() // suppress error that class is not decorated
export abstract class PageComponentBase implements OnInit, OnDestroy {
  constructor(public platform: Platform) {
  }

  ngOnInit() {
    if (!this.platform.is('mobile')) {
      this.init();
    }
  }

  ngOnDestroy() {
    if (!this.platform.is('mobile')) {
      this.destroy();
    }
  }

  ionViewWillEnter() {
    if (this.platform.is('mobile')) {
      this.init();
    }
  }

  ionViewWillLeave() {
    if (this.platform.is('mobile')) {
      this.destroy();
    }
  }

  abstract init(): any;
  destroy(): any {}
}
