import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({providedIn: 'root'})
export class LocationService {

  constructor() { }

  setPermission(permission: 'granted' | 'denied') {
    return Preferences.set({ key: 'locationPermission', value: permission });
  }

  async getPermission(): Promise<'none' | 'granted' | 'denied'> {
    const permission = await Preferences.get({ key: 'locationPermission' });

    if (!permission.value) {
      return 'none';
    }

    return permission.value as 'granted' | 'denied';
  }
}
