import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[optional]'
})
export class OptionalDirective implements OnInit {
  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.innerHTML =
      this.el.nativeElement.innerHTML + `<span class="has-text-weight-normal is-italic"> - optional</span>`;
  }
}
