import { NgModule, ErrorHandler } from '@angular/core';
import { NgxsModule, NgxsModuleOptions } from '@ngxs/store';
import { AppState } from './store/app.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '../../environments/environment';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsWebsocketPluginModule } from '@ngxs/websocket-plugin';
import { ngxsWebsocketOptions } from './ngxs-websocket-options';
import { AgmCoreModule } from '@agm/core';
import { IonicModule } from '@ionic/angular';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { QuillModule } from 'ngx-quill';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors';
import { ToastrModule } from 'ngx-toastr';
import { GlobalErrorHandler } from './error-handlers/global-error.handler';
import { DialogModule, ModalModule } from './modules';
import { FeedbackModule } from './modules/feedback/feedback.module';
import { DownloadModule } from './modules/download/download.module';
import { BenefitTypeModule } from './modules/benefit-type/benefit-type.module';
import { LocationPermissionModule } from './modules/location-permission/location-permission.module';
import { ProfileState } from './modules/profile/profile.state';
import { CommunitySwitchPageComponent } from './containers/community-switch-page/community-switch-page.component';
import { AddNewItemPageComponent } from './containers/add-new-item-page/add-new-item-page.component';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../@shared/shared.module';
import { AddNewItemComponent } from './components/add-new-item/add-new-item.component';
import { PLATFORMS_MAP } from './ionic-platform';
import { NgxStripeModule } from 'ngx-stripe';

export const ngxsOptions: NgxsModuleOptions = {
  developmentMode: !environment.production,
  selectorOptions: {
    // These Selector Settings are recommended in preparation for NGXS v4
    // (See above for their effects)
    suppressErrors: false,
    injectContainerState: false
  }
};

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    DialogModule,
    ModalModule,
    FeedbackModule,
    DownloadModule,
    BenefitTypeModule,
    LocationPermissionModule,
    SharedModule,
    NgxsModule.forRoot([AppState, ProfileState], ngxsOptions),
    NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        'app.accessToken',
        'app.encryptedAccessToken',
        'app.refreshAccessToken',
        'app.isLoggedIn',
        'app.session',
        'app.communities',
        'app.benefitsNotificationTimer',
      ]
    }),
    NgxsWebsocketPluginModule.forRoot(ngxsWebsocketOptions),
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.firebaseConfig.apiKey,
      libraries: ['places']
    }),
    IonicModule.forRoot({
      platform: PLATFORMS_MAP
    }),
    NgxsResetPluginModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripe.apiKey),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{'indent': '-1'}, {'indent': '+1'}],
          [{'color': ['#030303', '#13023f', '#4e426f', '#ff4070', '#e53964', '#9a9a9a', '#cccccc']},
            {'background': ['#030303', '#13023f', '#4e426f', '#ff4070', '#e53964', '#9a9a9a', '#cccccc']}],
          ['link'],
          ['clean']
        ]
      }
    })
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  declarations: [CommunitySwitchPageComponent, AddNewItemPageComponent, AddNewItemComponent],
  exports: [
    AddNewItemPageComponent
  ]
})
export class CoreModule { }
