import { UserLogin, Feature, Community, CreateCommunityRequest } from '../models';
import { Benefit } from '../../benefits/benefits';
import { Params } from '@angular/router';

export class Login {
  static readonly type = '[App] Login';
  constructor(public login: UserLogin) { }
}

export class LoadCommunities {
  static readonly type = '[App] LoadCommunities';
  constructor() {}
}

export class SwitchCommunity {
  static readonly type = '[App] SwitchCommunity';
  constructor(
    public communityId?: number,
    public navigateTo: string = '/news',
    public resetState: boolean = true,
    public disconnectWebSocket = true,
    public queryParams: Params = undefined) { }
}

export class CommunityChanged {
  static readonly type = '[App] CommunityChanged';
  constructor(
    public communityId?: number,
    public navigateTo: string = '/news',
    public queryParams: Params = undefined) { }
}

// export class LoadBenefitsGeofence {
//   static readonly type = '[App] LoadBenefitsGeofence';
// }

export class SaveCommunity {
  static readonly type = '[App] SaveCommunity';
  constructor(public community: Community) {}
}

export class CreateCommunity {
  static readonly type = '[App] CreateCommunity';
  constructor(public community: CreateCommunityRequest) {}
}

export class DeleteCommunity {
  static readonly type = '[App] DeleteCommunity';
  constructor() {}
}

export class LeaveCommunity {
  static readonly type = '[App] LeaveCommunity';
  constructor() {}
}

export class RefreshAccessToken {
  static readonly type = '[App] RefreshAccessToken';
}

export class Logout {
  static readonly type = '[App] Logout';
}

export class LoadUserSession {
  static readonly type = '[App] LoadUserSession';
}

export class LoadRecents {
  static readonly type = '[App] LoadRecents';
}

export class LoadNotifications {
  static readonly type = '[App] LoadNotifications';
}

export class SetMenuItem {
  static readonly type = '[App] SetMenuItem';
  constructor(public menuItemId: string) {}
}

export class ToggleFeature {
  static readonly type = '[App] ToggleFeature';
  constructor(public feature: Feature, public enabled: boolean) {}
}

export class SendFeedback {
  static readonly type = '[App] SendFeedback';
  constructor(public feedback: string) { }
}

export class SetNotificationCount {
  static readonly type = '[App] SetNotificationCount';
  constructor(public menuItemId: string, public count: number) { }
}

export class SetReady {
  static readonly type = '[App] SetReady';
}

export class UpdateBenefitsNotificationTimer {
  static readonly type = '[App] UpdateBenefitsNotificationTimer';
  constructor(public identifier: string) {}
}

// push notifications

export class WSArticleCreated {
  static readonly type = 'ArticleCreated';
  constructor(public payload: [any]) { }
}

export class WSEventCreated {
  static readonly type = 'EventCreated';
  constructor(public payload: [any]) { }
}

export class WSPollCreated {
  static readonly type = 'PollCreated';
  constructor(public payload: [any]) { }
}

export class WSBenefitCreated {
  static readonly type = 'BenefitCreated';
  constructor(public payload: [any]) { }
}

export class SetBenefitSearchQuery {
  static readonly type = 'SetBenefitSearchQuery';
  constructor(public payload: string) {}
}

export class SetNearbyBenefits {
  static readonly type = 'SetNearbyBenefits';
  constructor(public benefits: Benefit[]) {}
}

export class AddNearbyBenefits {
  static readonly type = 'AddNearbyBenefits';
  constructor(public benefits: Benefit[]) {}
}

export class RemoveNearbyBenefits {
  static readonly type = 'RemoveNearbyBenefits';
  constructor(public ids: number[]) {}
}

export class WSEditMessage {
  static readonly type = 'EditMessage';
  constructor(public payload: [any]) { }
}

export class WSEditPrivateMessage {
  static readonly type = 'EditPrivateMessage';
  constructor(public payload: [any]) { }
}

export class WSChannelMessageSent {
  static readonly type = 'ChannelMessageSent';
  constructor(public payload: [any]) { }
}

export class WSPrivateChatMessageSent {
  static readonly type = 'PrivateChatMessageSent';
  constructor(public payload: [any]) { }
}

export class WSUserRestricted {
  static readonly type = 'UserRestricted';
  constructor(public payload: [any]) { }
}
