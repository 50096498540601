import { Component } from '@angular/core';
import { DialogRef } from '../../dialog';

@Component({
  selector: 'app-location-permission-dialog',
  templateUrl: './location-permission-dialog.component.html',
  styleUrls: ['./location-permission-dialog.component.scss']
})

export class LocationPermissionDialogComponent {

  constructor(
    private dialogRef: DialogRef
  ) {
  }

  onCancel() {
    this.dialogRef.close();
  }

}
