import {Pipe, PipeTransform} from '@angular/core';

export type SortingOrder = 'asc' | 'desc' | null;

interface SortingOption {
  sortingOrder: SortingOrder;
  additionalSortingBy: string;
  key?: string;
}

export interface SortingOptions {
  [key: string]: SortingOption;
}

@Pipe({
  name: 'sort',
  pure: true
})
export class SortPipe implements PipeTransform {

  transform(values: any[], sortingOptions: SortingOptions): any[] {
    if (!values) {
      return;
    }

    const firstSortingOptions = this.getSortableField(sortingOptions);

    if (!firstSortingOptions) {
      return values;
    }

    const objectsArray = [...values];

    if (firstSortingOptions.additionalSortingBy) {
      this.twoLevelsSort(objectsArray, firstSortingOptions);
    } else {
      this.sort(objectsArray, firstSortingOptions);
    }

    return objectsArray;
  }

  sort(values: any[], sortingOption: SortingOption) {
    values.sort((a, b) => {
      const isGreater = a[sortingOption.key].toLowerCase() > b[sortingOption.key].toLowerCase();
      const isAscending = sortingOption.sortingOrder === 'asc';
      return (isGreater && isAscending) ? 1 : -1;
    });
  }

  twoLevelsSort(values: any[], sortingOption: SortingOption) {
    values.sort((a, b) => {
      if (!a[sortingOption.key] || !b[sortingOption.key]) {
        return 1;
      }

      const mainSortingIsGreater = a[sortingOption.key].toLowerCase() > b[sortingOption.key].toLowerCase();
      const additionalSortingIsGreater = a[sortingOption.additionalSortingBy].toLowerCase() > b[sortingOption.additionalSortingBy].toLowerCase();

      const isGreater = mainSortingIsGreater || additionalSortingIsGreater;
      const isAscending = sortingOption.sortingOrder === 'asc';
      return (isGreater && isAscending) ? 1 : -1;
    });
  }

  getSortableField(sortingOptions: SortingOptions): SortingOption {
    for (const key of Object.keys(sortingOptions)) {
      if (sortingOptions[key].sortingOrder) {
        return {...sortingOptions[key], key};
      }
    }
  }

}
