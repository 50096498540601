import {ChangeDetectionStrategy, Component} from '@angular/core';
import { DialogService } from '../../../@core/modules';
import { FeedbackDialogComponent } from '../../../@core/modules/feedback/containers/feedback-dialog/feedback-dialog.component';
import {DownloadDialogComponent} from '../../../@core/modules/download/containers/download-dialog.component'


@Component({
  selector: 'app-download-pulse',
  templateUrl: './download-pulse.component.html',
  styleUrls: ['./download-pulse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadPulseComponent {
  
  constructor(
    private dialogService: DialogService,
  ) {
  }

  onDownloadClick() {
    this.dialogService.open(DownloadDialogComponent, {
      width: 360
    });
  }

}

