import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-text-with-icon',
  templateUrl: './text-with-icon.component.html',
  styleUrls: ['./text-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextWithIconComponent {
  @Input() text: string;
  @Input() source: string;
  @Input() sizeClass: '' | 'is-small' | 'is-medium' | 'is-large' | 'is-20x20' | 'is-36x36' = 'is-small';
  @Input() smallText: boolean;
  @Input() bold: boolean;
  @Input() disabled: boolean;
  @Input() notificationCount: number;
  @Input() textClass: string;
}
