import {Action, Selector, State, StateContext} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {ImmutableContext} from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { LoadProfile, SaveProfile, SetProfileAvatar, DeleteProfileAvatar, DeleteUserProfile } from './profile.actions';
import { Profile } from './profile';
import { ProfileService } from './profile.service';

export interface ProfileStateModel {
  user: Profile;
}

@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    user: null
  }
})
@Injectable()
export class ProfileState {

  constructor(private profileService: ProfileService) {}

  @Selector()
  static user(state: ProfileStateModel): Profile {
    return state.user;
  }

  @Action(LoadProfile)
  loadUser(ctx: StateContext<ProfileStateModel>) {
    return this.profileService.get()
      .pipe(
        tap(user => {
          ctx.patchState({
            user,
          });
        })
      );
  }

  @Action(SaveProfile)
  save(ctx: StateContext<ProfileStateModel>, action: SaveProfile) {
    return this.profileService.save(action.user)
      .pipe(
        tap(() => {
          ctx.patchState({user: action.user});
        })
      );
  }

  @Action(SetProfileAvatar)
  @ImmutableContext()
  SetProfileAvatar(ctx: StateContext<ProfileStateModel>, action: SetProfileAvatar) {
    ctx.setState(state => {
      state.user.avatarUrl = action.avatarUrl;
      return state;
    });
  }

  @Action(DeleteProfileAvatar)
  @ImmutableContext()
  DeleteProfileAvatar(ctx: StateContext<ProfileStateModel>) {
    return this.profileService.deleteProfileAvatar().pipe(
      tap(() => {
        ctx.setState(state => {
          state.user.avatarUrl = null;
          return state;
        });
      })
    );
  }
  
  @Action(DeleteUserProfile)
  @ImmutableContext()
  DeleteUserProfile() {
    return this.profileService.delete();
  }
}
