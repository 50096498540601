import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Select, Store, Actions, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Community } from '../../models';
import { AppState } from '../../store/app.state';
import { SwitchCommunity, CommunityChanged, LoadCommunities } from '../../store/app.actions';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-community-switch-page',
  templateUrl: './community-switch-page.component.html',
  styleUrls: ['./community-switch-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunitySwitchPageComponent implements OnInit, OnDestroy {
  @Select(AppState.communities) communities$: Observable<Community[]>;

  switching = false;
  sub: Subscription;

  constructor(
    private store: Store,
    private actions$: Actions,
    private modalCtrl: ModalController,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.store.dispatch(new LoadCommunities());

    this.sub = this.actions$.pipe(
      ofActionSuccessful(CommunityChanged),
      delay(300)
    ).subscribe((action: CommunityChanged) => {
      this.dismiss();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  onCommunitySelect(community: Community) {
    this.switching = true;
    this.cdr.detectChanges();

    // prevent from flickering while clearing a state
    this.cdr.detach();

    this.store.dispatch(new SwitchCommunity(community.id));
  }
}
