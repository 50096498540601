import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Store} from '@ngxs/store';
import {map} from 'rxjs/operators';
import {AppState} from '../store/app.state';
import {Observable} from 'rxjs';
import {Navigate} from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root'
})
export class ActivateIfNotLoggedIn implements CanActivate {
  constructor(private store: Store) {
  }

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(AppState.isLoggedIn).pipe(map(isLoggedIn => {
      if (isLoggedIn) {
        this.store.dispatch(new Navigate(['news']));
        return false;
      } else {
        return true;
      }
    }));
  }
}
