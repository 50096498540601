import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
  pure: true
})
export class TimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const date = new Date(value);
      const leadingZero = (num) => `0${num}`.slice(-2);
      value = [date.getHours(), date.getMinutes()]
        .map(leadingZero)
        .join(':');
    }
    return value;
  }

}
