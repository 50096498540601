import {Component, EventEmitter, forwardRef, Input, Output, ChangeDetectionStrategy, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements ControlValueAccessor {
  @Input() value: any;
  @Input() availableOptions: any[];
  @Input() placeholder = 'Select';
  @Input() editOptionText;
  @Input() editTemplate: TemplateRef<any>;
  @Input() editMode = false;
  @Input() isNarrow = false;
  @Input() isSelectionOptional = false;
  @Input() optionKey = 'id';
  @Input() labelFn: ((item: any) => string);
  @Input() compareFn = ((a: any, b: any) => a[this.optionKey] === b[this.optionKey]);

  @Output() editOptionClick = new EventEmitter();
  @Output() optionValueChange = new EventEmitter<any>();

  opened = false;

  get triggerText() {
    if (this.editMode) {
      return this.editOptionText;
    }

    if (!this.value) {
      return this.placeholder;
    }

    const option = this.availableOptions.find(o => this.compareFn(o, this.value));
    if (option) {
      return this.getLabel(option);
    } else {
      return '-';
    }
  }

  onChange: (value: any) => void;

  onTriggerClick() {
    if (this.editMode) {
      return;
    }

    this.opened = !this.opened;
  }

  onOptionClick(option: any) {
    this.value = option[this.optionKey];

    if (this.onChange) {
      this.onChange(option[this.optionKey]);
    }

    this.optionValueChange.emit(option);
    this.close();
  }

  onEditOptionClick() {
    this.editOptionClick.emit();
    this.close();
  }

  close() {
    this.opened = false;
  }

  getLabel(option) {
    if (this.labelFn) {
      return this.labelFn(option);
    }
    return option.name;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  writeValue(val: any) {
    this.value = val;
  }
}
