// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: 'https://api-test.fellowup.co/api/',
  websocketUrl: 'wss://api-test.fellowup.co/hubs/main',
  firebaseConfig: {
    apiKey: 'AIzaSyAUglMflMyoEkahDL9ZIceqVcsGbgmDiRM',
    authDomain: 'pulse-debug-8fcde.firebaseapp.com',
    databaseURL: 'https://pulse-debug-8fcde.firebaseio.com',
    projectId: 'pulse-debug-8fcde',
    storageBucket: 'pulse-debug-8fcde.appspot.com',
    messagingSenderId: '820374157111',
    appId: '1:820374157111:web:b2c4b1d22d61ebc4660cb2',
    measurementId: 'G-PH8FY6MS9L'
  },
  appInsights: {
    enabled: false,
    instrumentationKey: ''
  },
  stripe: {
    apiKey: 'pk_test_Kb9YELcSAXq9cTIVRcn3UFNA00cNjkBloT'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
