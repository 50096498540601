<div class="notification" [class.warning]="warning">
  <div class="columns is-marginless is-vcentered">
    <div class="column is-narrow">
      <app-icon source="warning.svg"></app-icon>
    </div>
    <div class="column text-info">
      <ng-content></ng-content>
    </div>
  </div>
</div>
