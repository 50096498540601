import { RefreshAccessToken, Logout } from './../store/app.actions';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';

import { Store, Select } from '@ngxs/store';
import { catchError, mergeMap, switchMap, filter, take, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../store/app.state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  @Select(AppState.accessToken) accessToken$: Observable<string>;

  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private store: Store) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    const accessToken = this.store.selectSnapshot(s => s.app.accessToken);

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = this.addToken(req, accessToken);

    // send cloned request with header to the next handler.
    return next.handle(authReq).pipe(
      catchError(error => {
        if (error.status === 401) {
          return this.handle401Error(req, next);
        }

        return throwError(error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.accessTokenSubject.next(null);

      const currentAccessToken = this.store.selectSnapshot(AppState.accessToken);

      return this.store.dispatch(new RefreshAccessToken()).pipe(
        withLatestFrom(this.accessToken$),
        switchMap(([_, token]) => {
          this.refreshTokenInProgress = false;

          if (token !== currentAccessToken) {
            this.accessTokenSubject.next(token);
            return next.handle(this.addToken(request, token));
          } else {
            return this.store.dispatch(new Logout());
          }
        }),
        catchError(err => {
          this.refreshTokenInProgress = false;
          return this.store.dispatch(new Logout());
        }));

    } else {
      return this.accessTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
