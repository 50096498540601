import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import { AuthorizationService } from '../../@core/services/authorization.service';

@Directive({
  selector: '[ifAdmin]'
})
export class IfAdminDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthorizationService
  ) {
  }

  ngOnInit() {
    if (this.authService.isAdmin()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
