<div class="text-header-2 has-margin-bottom-sm">Locations</div>
<div class="multi-locations">
  <div *ngFor="let control of form.controls; let i = index" class="location">
    <div class="control is-fullwidth">
      <input
        class="input"
        [value]="getLocationName(control)"
        (locationChange)="onLocationChange($event, i)"
        placeholder="Address, City, Country"
        autocorrect="off"
        autocapitalize="off"
        type="text"
        addressAutocomplete
      />
      <!-- <span class="icon is-right">
        <app-icon source="location.svg"></app-icon>
      </span> -->
    </div>
    <button *ngIf="form.controls.length > 1" type="button" class="button is-outlined" (click)="deleteLocation(i)">
      <app-icon source="locations/delete.svg"></app-icon>
    </button>
    <button type="button" class="button is-outlined" (click)="addLocationAfter(i)">
      <app-icon source="plus.svg"></app-icon>
    </button>
  </div>
</div>
