<app-page remove-wrapper
  titleVisibility="desktop"
  [showCommunity]="true"
  (refresh)="onRefresh($event)">
  <ng-container *ifHasFeature="['Channels']">
    <ng-container *ifHasFeature="'Channels'">
      <app-channels-chats [channels]="channels$ | async" [loading]="loading$ | async"></app-channels-chats>
    </ng-container>
    <!-- we decided to disable private chats for now -->
    <!-- <ng-container *ifHasFeature="'PrivateChats'">
      <app-private-chats [privateChats]="privateChats$ | async"></app-private-chats>
    </ng-container> -->
  </ng-container>

  <ng-container *isMobile>
    <ng-container
      *emptyStatePlaceholder="
        ((channels$ | async)?.length || 0) === 0 && !(loading$ | async).channels
        type: 'chat';
        text: 'There are no chat channels created yet'">
    </ng-container>
  </ng-container>
</app-page>
