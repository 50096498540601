import { Directive, Optional, Inject, ViewContainerRef, ComponentFactoryResolver, ComponentRef, Input, Host, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ControlErrorComponent } from '../components/control-error/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { merge, EMPTY, Observable, Subscription } from 'rxjs';


import { InjectionToken } from '@angular/core';


export const defaultErrors = {
  required: (error) => `This field is required`,
  maxlength: ({ requiredLength, actualLength }) => `Only ${requiredLength} characters are allowed`,
  minlength: ({ requiredLength, actualLength }) => `Expected min length is ${requiredLength} but got ${actualLength}`,
  email: (error) => 'Email is not valid',
  emailExist: (error) => 'Email is already added. Please enter another email to add',
  invalidVideoUrl: (error) => 'Video url is not valid. Only Youtube and Vimeo urls are allowed.',
  invalidCommunityName: (error) => 'Community name contains invalid characters'
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});

@Directive({
  selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  @Input() customErrors = {};

  ref: ComponentRef<ControlErrorComponent>;
  container: ViewContainerRef;
  submit$: Observable<Event>;
  sub: Subscription;

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    @Inject(FORM_ERRORS) private errors,
    @Optional() private form: FormSubmitDirective,
    private controlDir: NgControl,
    private renderer: Renderer2
  ) {
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit() {
    this.sub = merge(
      this.submit$,
      this.control.valueChanges
    ).subscribe((v) => {
        const controlErrors = this.control.errors;
        if (controlErrors) {
          const firstKey = Object.keys(controlErrors)[0];
          const getError = this.errors[firstKey];

          if (getError || this.customErrors[firstKey]) {
            const text = this.customErrors[firstKey] || getError(controlErrors[firstKey]);
            this.setError(text);
          } else {
            this.setError(`Validation message for key "${firstKey}" is not defined`);
          }
        } else if (this.ref) {
          this.setError(null);
        }
      });
  }

  get control() {
    return this.controlDir.control;
  }

  setError(text: string) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.text = text;

    if (text) {
      this.renderer.addClass(this.vcr.element.nativeElement, 'is-danger');
    } else {
      this.renderer.removeClass(this.vcr.element.nativeElement, 'is-danger');
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
