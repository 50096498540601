import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {AppState} from '../store/app.state';
import {first, map} from 'rxjs/operators';
import { Feature } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  constructor(private store: Store) {
  }

  hasFeature(features: Feature[]) {
    return this.store.select(AppState.features).pipe(
      first(userFeatures => !!userFeatures),
      map(userFeatures => {
        return (userFeatures as Feature[]).some(f => features.includes(f));
      })
    );
  }

  hasFeatureSnapshot(features: Feature[]) {
    const allfeatures = this.store.selectSnapshot(AppState.features);
    return allfeatures && allfeatures.some(f => features.includes(f)); 
  }
}
