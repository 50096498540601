import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

export interface CustomSubdomain {
  tenantName: string;
  logoUrl: string;
}

@Injectable({providedIn: 'root'})
export class CustomSubdomainService {
  constructor() { }

  isCustomSubdomain() {
    const hostName = window.location.hostname;
    const subdomain = hostName.split('.', 1)[0];

    return !['localhost', 'web', 'pulse-web', 'app', 'app-test'].includes(subdomain);
  }

  getCustomSubdomainData(): Observable<CustomSubdomain> {
    const hostName = window.location.hostname;
    const subdomain = hostName.split('.', 1)[0].toLowerCase();
    let data: { tenantName: string, logoUrl: string };

    switch (subdomain) {
      case 'moodys':
        data = {
          tenantName: 'Moody\'s',
          logoUrl: 'https://pulsemediax.azureedge.net/customers/424a12a1-6253-4872-beea-898391de0fb9.jpg?width=512&upscale=false'
        };
        break;
      case 'compensa':
        data = {
          tenantName: 'Compensa',
          logoUrl: 'https://pulsemediax.azureedge.net/customers/ab895254-58bd-472e-9594-d2aea7b72248.png?width=512&upscale=false'
        };
        break;
      default:
        throw new Error('Unknown subdomain ' + subdomain);
    }

    return of(data).pipe(delay(100));
  }
}
