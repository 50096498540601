import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Recents} from '../models/recents';
import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecentsService {
  constructor(private httpClient: HttpClient) {
  }

  get(): Observable<Recents> {
    return this.httpClient.get<Recents>(`${environment.baseApiUrl}v2/recents`);
  }
}
