import {ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {Chart, TickOptions} from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements OnChanges {
  @Input() labels: string[];
  @Input() datasets: number[];
  @Input() isLoading: boolean;

  @ViewChild('lineChart', {static: true}) private chartRef;

  chart: Chart;
  resizeTimeout: any;

  @HostListener('window:resize')
  reinitializeChart() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.initializeChart(this.labels, this.datasets);
    }).bind(this), 250);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.labels || changes.datasets) {
      this.initializeChart(this.labels, this.datasets);
    }
  }

  initializeChart(labels: string[], datasets: number[]) {
    if (this.chart) {
      this.chart.destroy();
    }

    const ctx = this.chartRef.nativeElement.getContext('2d');

    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: {
        labels: labels || [],
        datasets: [{
          data: datasets || [],
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: '#13023f',
          lineTension: 0,
          pointStyle: 'rectRounded',
          pointBackgroundColor: '#13023f',
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false
            },
            ticks: {
              fontFamily: 'Nunito Sans',
              fontColor: 'black',
              fontSize: 12,
            }
          }],
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 0,
              precision: 0,
              fontFamily: 'Nunito Sans',
              fontColor: 'black',
              fontSize: 12,
              callback: (yAxesValue) => {
                if (yAxesValue !== 0) {
                  return yAxesValue;
                }
              }
            } as TickOptions
          }],
        }
      }
    });
  }

}
