import {ChangeDetectionStrategy, Component, OnInit, OnDestroy} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Select, Store, Actions, ofActionSuccessful} from '@ngxs/store';
import {Observable, BehaviorSubject, Subscription} from 'rxjs';
import {map, filter, withLatestFrom} from 'rxjs/operators';
import { Router, ActivationEnd } from '@angular/router';
import { AppState } from '../../../@core/store/app.state';
import {
  Logout,
  LoadUserSession,
  SwitchCommunity,
  SetMenuItem,
  LoadRecents,
  LoadCommunities,
  LoadNotifications,
  CommunityChanged,
  SetReady
} from '../../../@core/store/app.actions';
import { RecentBenefit, RecentEvent } from '../../../@core/models/recents';
import { Community } from '../../../@core/models';
import { ProfileState } from '../../../@core/modules/profile/profile.state';
import { Profile } from '../../../@core/modules/profile/profile';
import { ConnectWebSocket } from '@ngxs/websocket-plugin';
import { environment } from '../../../../environments/environment';
import { LoadProfile } from '../../../@core/modules/profile/profile.actions';
import { Navigate } from '@ngxs/router-plugin';
import { Notifications } from '../../../@core/models/notifications';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  @Select(AppState.menuItemId) menuItemId$: Observable<string>;
  @Select(AppState.notifications) notifications$: Observable<Notifications>;
  @Select(AppState.recentBenefits) recentBenefits$: Observable<RecentBenefit[]>;
  @Select(AppState.recentEvents) recentEvents$: Observable<RecentEvent[]>;
  @Select(AppState.community) community$: Observable<Community>;
  @Select(ProfileState.user) user$: Observable<Profile>;
  @Select(AppState.communities) communities$: Observable<Community[]>;
  @Select(AppState.isLoggedIn) isLoggedIn$: Observable<boolean>;
  @Select(AppState.encryptedAccessToken) encryptedAccessToken$: Observable<string>;
  @Select(AppState.hideUsersPage) hideUsersPage$: Observable<boolean>;
  @Select(AppState.hideBilling) hideBilling$: Observable<boolean>;

  behaviorSubject = new BehaviorSubject<any>({});
  sub: Subscription = new Subscription();

  hideRightSidebar$: Observable<boolean>;
  hideLeftSidebar$: Observable<boolean>;

  constructor(
    private store: Store,
    public platform: Platform,
    private router: Router,
    private actions$: Actions) {
    const routerSub = router.events.pipe(
      filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0),
      map((event: ActivationEnd) => event.snapshot.data)
    ).subscribe(x => this.behaviorSubject.next(x));

    this.sub.add(routerSub);
  }

  ngOnInit() {
    this.loadMainData();

    const switchCommunitySub = this.actions$.pipe(ofActionSuccessful(CommunityChanged)).subscribe((action: CommunityChanged) => {
      if (action.communityId) {
        this.loadMainData();

        // workaround to trigger reloading NewsPageComponent
        this.router.navigateByUrl('/events', {skipLocationChange: true}).then(() => {
          this.router.navigate([action.navigateTo]);
        });
      } else {
        this.store.dispatch(new Navigate([action.navigateTo]));
      }
    });

    this.sub.add(switchCommunitySub);

    this.hideLeftSidebar$ = this.behaviorSubject.pipe(
      map(data => !!data && !!data.hideLeftSidebar)
    );

    this.hideRightSidebar$ = this.behaviorSubject.pipe(
      map(data => !!data && !!data.hideRightSidebar)
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  selectCommunity(communityId: number) {
    this.store.dispatch(new SwitchCommunity(communityId));
  }

  setMenuItem(menuItemId: string) {
    this.store.dispatch(new SetMenuItem(menuItemId));
  }

  private loadMainData() {
    this.store.dispatch(new LoadUserSession()).pipe(
      withLatestFrom(this.store.select(AppState.userSession))
    ).subscribe(([_, userSession]) => {
      if (userSession.logoutNeeded) {
        return;
      }

      this.store.dispatch([
        new LoadCommunities(),
        new LoadProfile(),
        new LoadRecents(),
        new LoadNotifications(),
        new ConnectWebSocket({
          url: `${environment.websocketUrl}?encAuthToken=${encodeURIComponent(this.store.selectSnapshot(AppState.encryptedAccessToken))}`
        })
      ]).subscribe(() => {
        this.store.dispatch(new SetReady());
      });
    });
  }
}
