<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="field">
      <label class="label">Email address</label>
      <div class="control">
        <input class="input" formControlName="email" />
      </div>
    </div>

    <div class="field has-margin-bottom-mlg">
      <label class="label">Role</label>
      <div class="control">
        <app-role-dropdown
          formControlName="role"
          optionKey="name"
          [currentUserRole]="currentUserRole"
        >
        </app-role-dropdown>
      </div>
    </div>

  <div class="field">
    <div class="control">
      <button type="submit" class="button is-rounded is-secondary is-fullwidth is-high-button">
        Invite
      </button>
    </div>
  </div>
</form>
