import {Component, OnDestroy, OnInit} from '@angular/core';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import {RouterNavigation, Navigate} from '@ngxs/router-plugin';
import { map, filter, take, finalize, tap, delay } from 'rxjs/operators';
import {
  DisconnectWebSocket} from '@ngxs/websocket-plugin';
import { Platform, NavController } from '@ionic/angular';
import {
  ActionPerformed,
  Token,
  PushNotifications
} from '@capacitor/push-notifications';
import { LocalNotificationSchema, LocalNotificationActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
import { Logout, SwitchCommunity, Login, RemoveNearbyBenefits, AddNearbyBenefits } from './@core/store/app.actions';
import { PushNotificationService } from './@core/services/push-notification.service';
import { StyleLoadService } from './@core/services/style-load.service';
import { AppState } from './@core/store/app.state';
import { StateResetAll, StateReset } from 'ngxs-reset-plugin';
import { AnalyticsService } from './@core/services/analytics.service';
//import BackgroundGeolocation, { GeofenceEvent } from '@transistorsoft/capacitor-background-geolocation';
import { Router } from '@angular/router';
import { Benefit } from './benefits/benefits';
import { Community } from './@core/models';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import { Device } from '@capacitor/device';
import { LocationService } from './@core/services/location.service';
import { MonitoringService } from './@core/services/monitoring.service';
import { Geolocation } from '@capacitor/geolocation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(AppState.community) community$: Observable<Community>;
  @Select(AppState.getNearbyBenefits) nearbyBenefits$: Observable<Benefit[]>;

  registrationToken: string;

  constructor(
    private actions$: Actions,
    private store: Store,
    public platform: Platform,
    private router: Router,
    private pushNotificationService: PushNotificationService,
    private navController: NavController,
    private styleLoadService: StyleLoadService,
    private analyticsService: AnalyticsService,
    private locationService: LocationService,
    monitoringService: MonitoringService
  ) {
    monitoringService.init();
  }

  async ngOnInit() {
    SplashScreen.hide();

    // this.storage.create();

    await this.analyticsService.initializeFireBase();

    this.platform.pause.subscribe(() => {
      this.onPause();
    });

    this.platform.resume.subscribe(() => {
      this.onResume();
    });

    this.platform.ready().then(() => {
      LocalNotifications.addListener('localNotificationActionPerformed', (action: LocalNotificationActionPerformed) => {
        const { notification } = action;

        if (notification.extra.single) {
          this.router.navigate(['/benefits', notification.extra.benefits[0].id]);

          return;
        }

        this.router.navigate(['/benefits/nearby']);
      });
    });

    if (this.platform.is('mobile')) {
      this.styleLoadService.loadStyle('styles-mobile.css');
    }

    this.store.select(AppState.isLoggedIn).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.analyticsService.updateUserId();
        this.analyticsService.log('app_open', {});
      }
    });

    this.actions$.pipe(ofActionSuccessful(Login), delay(1000)).subscribe(async () => {
      if (this.platform.is('capacitor') && this.registrationToken) {
        const deviceInfo = await Device.getId();
        await this.pushNotificationService.registerNotificationToken(deviceInfo.identifier, this.registrationToken).toPromise();
      }
    });

    this.actions$.pipe(ofActionSuccessful(Logout)).subscribe(async () => {
      let observable = of({});

      if (this.platform.is('capacitor')) {
        const isLoggedIn = this.store.selectSnapshot(AppState.isLoggedIn);
        if (isLoggedIn) {
          const deviceInfo = await Device.getId();
          observable = this.pushNotificationService.unregisterNotificationToken(deviceInfo.identifier);
        }
      }

      observable.pipe(
        finalize(() => {
          this.store.dispatch(new StateResetAll());
          // resets saved values to localstorage
          this.store.dispatch(new StateReset(AppState));

          if (!this.platform.is('mobile')) {
            this.store.dispatch(new Navigate(['/login']));
          } else {
            // clear navigation stack in ionic
            this.navController.navigateRoot('/login');
          }

          setTimeout(() => {
            this.store.dispatch(new DisconnectWebSocket());
          }, 1000);
        })
      ).subscribe();
    });

    // this.actions$.pipe(
    //   ofActionSuccessful(WebSocketDisconnected),
    // //  delay(1000)
    // ).subscribe(_ => {
    //   const webSocketConnected = this.store.selectSnapshot(AppState.webSocketConnected);
    //   const wsShouldBeConnected = this.store.selectSnapshot(AppState.wsShouldBeConnected);

    //   // reconnect
    //   if (!webSocketConnected && wsShouldBeConnected) {
    //     this.store.dispatch(new ConnectWebSocket({
    //       url: `${environment.websocketUrl}?encAuthToken=${encodeURIComponent(this.store.selectSnapshot(AppState.encryptedAccessToken))}`
    //     }));
    //   }
    // });

    if (this.platform.is('capacitor')) {
      await this.registerPushNotifications();
      this.addPushNotificationListeners();
      // TODO: investigate issues with background geolocation
      // await this.registerBackgroundGeolocation();
    }
  }

  getRouterActionsDataValue(key: string): Observable<boolean> {
    return this.actions$.pipe(
      ofActionSuccessful(RouterNavigation),
      map(action => action.routerState.root.firstChild.firstChild && action.routerState.root.firstChild.firstChild.data),
      map(data => data && !!data[key])
    );
  }

  ngOnDestroy() {
    this.store.dispatch(new DisconnectWebSocket());
  }

  private onPause() {
    // this.store.dispatch(new DisconnectWebSocket());
  }

  private onResume() {
    // this.store.dispatch(new LoadUserSession()).pipe(
    //   withLatestFrom(this.store.select(AppState.userSession))
    // ).subscribe(([_, userSession]) => {
    //   const logoutNeeded = userSession?.logoutNeeded;

    //   if (!logoutNeeded) {
    //     const ready = this.store.selectSnapshot(AppState.ready);
    //     const webSocketConnected = this.store.selectSnapshot(AppState.webSocketConnected);

    //     // if app is ready - it was connected to ws before
    //     if (ready && !webSocketConnected) {
    //       alert('connecting to ws');
    //       this.store.dispatch(new ConnectWebSocket({
    //         url: `${environment.websocketUrl}?encAuthToken=${encodeURIComponent(this.store.selectSnapshot(AppState.encryptedAccessToken))}`
    //       })).subscribe(() => {
    //         // handshake
    //         this.store.dispatch(new NgxsSendWebSocketMessage({protocol: 'json', version: 1}));
    //       });
    //     }
    //   }
    // });
  }

  // private async registerBackgroundGeolocation() {
  //   BackgroundGeolocation.onGeofence(geofence => {
  //     this.handleGeofence(geofence);
  //   });

  //   BackgroundGeolocation.ready({
  //     locationAuthorizationRequest: 'Always',
  //     disableLocationAuthorizationAlert: true,
  //     desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_MEDIUM,
  //     // debug: !environment.production,
  //     stopOnTerminate: true,
  //     startOnBoot: true,
  //    // enableHeadless: true,
  //   }).then(async state => {
  //     if (!state.enabled) {
  //       const status = await this.locationService.getPermission();
  //       // check if geolocation permission still valid
  //       const permissions = await Geolocation.checkPermissions();

  //       if (status === 'granted' && permissions.location === 'granted') {
  //         BackgroundGeolocation.startGeofences();
  //       }
  //     }
  //   });
  // }

  // private async handleGeofence(geofence: GeofenceEvent) {
  //   if (geofence.action === 'DWELL') {
  //     const firstBenefit = geofence.extras.benefits[0] as Benefit;
  //     // this.toastService.info(firstBenefit.shortDescription, 'DWELL');

  //     const { value } = await Preferences.get({ key: 'notificationTimer' });
  //     const benefitsNotificationTimer: {} = value ? JSON.parse(value) : {};

  //     this.community$.pipe(
  //       tap(async (community: Community) => {
  //         const identifier = `${ community.id }.${ geofence.identifier }`;

  //         if (benefitsNotificationTimer.hasOwnProperty(identifier)) {
  //           const diffInDays = (new Date().getTime() - benefitsNotificationTimer[identifier]) / 1000 / 60 / 60 / 24;

  //           if (diffInDays < 14) {
  //             // reset timer to show nearby benefit no sooner than 14 days
  //             benefitsNotificationTimer[identifier] = new Date().getTime();
  //             await Preferences.set({ key: 'notificationTimer', value: JSON.stringify(benefitsNotificationTimer) });

  //             return;
  //           }
  //         }

  //         const notification: LocalNotificationSchema = {
  //           id: 1,
  //           title: `${ community.name } benefits found nearby`,
  //           body:
  //             `There are ${ (geofence.extras.benefits as any).length } places nearby you where you can use ${ community.name } benefits.`,
  //           extra: {
  //             benefits: geofence.extras.benefits,
  //             single: false,
  //           }
  //         };

  //         if ((geofence.extras.benefits as any).length === 1) {
  //           const benefit: Benefit = geofence.extras.benefits[0];

  //           notification.title = `${ community.name } benefit found nearby`;
  //           notification.body = `There is ${ benefit.companyName } nearby you where you can use ${ community.name } benefits.`;
  //           notification.extra.single = true;
  //         }

  //         LocalNotifications.schedule({ notifications: [ notification ] });

  //         benefitsNotificationTimer[identifier] = new Date().getTime();
  //         await Preferences.set({ key: 'notificationTimer', value: JSON.stringify(benefitsNotificationTimer) });
  //       })
  //     ).subscribe();
  //   }

  //   if (geofence.action === 'ENTER') {
  //      // const firstBenefit = geofence.extras.benefits[0] as Benefit;
  //      // this.toastService.info(firstBenefit.shortDescription, 'ENTER');

  //       this.store.dispatch(new AddNearbyBenefits(geofence.extras.benefits as unknown as Benefit[]));
  //   }

  //   if (geofence.action === 'EXIT') {
  //     // const firstBenefit = geofence.extras.benefits[0] as Benefit;
  //     // this.toastService.info(firstBenefit.shortDescription, 'EXIT');

  //     const ids = (geofence.extras.benefits as any).map((benefit: Benefit) => benefit.id);
  //     this.store.dispatch(new RemoveNearbyBenefits(ids));
  //   }
  // }

  private async registerPushNotifications() {
    let permStatus = await PushNotifications.checkPermissions();
  
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
  
    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
  
    await PushNotifications.register();
  }

  private addPushNotificationListeners() {
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      async (token: Token) => {
        this.registrationToken = token.value;
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      () => {
        // no need to handle as we handle in-app pushs through web socket
        // alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        const communityId = +notification.notification.data.tenantId;
        const itemId = +notification.notification.data.id;
        let action: any;

        switch (notification.notification.data.type) {
          case 'Article':
            action = new SwitchCommunity(communityId, '/news/' + itemId);
            break;
          case 'Event':
            action = new SwitchCommunity(communityId, '/events/' + itemId);
            break;
          case 'Poll':
            action = new SwitchCommunity(communityId, '/polls');
            break;
          case 'Survey':
            action = new SwitchCommunity(communityId, '/polls', true, true, { type: 'surveys' })
            break;
          case 'Benefit':
            action = new SwitchCommunity(communityId, '/benefits/' + itemId);
            // this.store.dispatch(new LoadBenefitsGeofence());
            break;
          case 'ChatChannel':
            action = new SwitchCommunity(communityId, '/chat/channel/' + itemId);
            break;
          case 'PrivateChat':
            const senderId = +notification.notification.data.senderId;
            action = new SwitchCommunity(communityId, '/chat/private/' + senderId);
            break;
        }

        if (action) {
           this.store.select(AppState.ready).pipe(
             filter(ready => ready),
             take(1)
          ).subscribe(() => {
            this.store.dispatch(action);
          });
        }
      }
    );
  }
}
