import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private httpClient: HttpClient) {
  }

  sendFeedback(feedback: string) {
    return this.httpClient.post(`${environment.baseApiUrl}feedback`, {
      feedback
    });
  }
}
