<ng-container *ngFor="let value of form.controls; let i = index">
  <div class="field is-grouped">
    <div class="control">
      <app-dropdown
        [formControl]="$any(form.controls[i])"
        [availableOptions]="availableOptions"
        [editTemplate]="editTemplate"
        [editOptionText]="editOptionText"
        [isSelectionOptional]="isSelectionOptional"
        [editMode]="editMode && i === editModeIndex"
        [placeholder]="placeholder"
        [compareFn]="compareFn"
        (optionValueChange)="onOptionValueChange($event)"
        (editOptionClick)="onEditOptionClick(i)"
      >
      </app-dropdown>
    </div>
    <div class="level is-mobile">
      <div class="level-item has-margin-right-sm">
        <app-icon
          *ngIf="form.controls.length < maxDropdowns"
          class="is-clickable"
          source="plus.svg"
          (click)="addDropdown(i)"
        ></app-icon>
      </div>
      <div class="level-item">
        <app-icon
          *ngIf="form.controls.length > 1"
          class="is-clickable"
          source="minus.svg"
          (click)="removeDropdown(i)"
        ></app-icon>
      </div>
    </div>
  </div>
</ng-container>
