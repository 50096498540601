import { Channel, PrivateChat, Message } from './channels';

export class LoadChannels {
  static readonly type = '[chat] LoadChannels';  
  constructor(public forceLoad?: boolean) {}
}

export class SetCurrentChannel {
  static readonly type = '[chat] SetCurrentChannel';
  constructor(public channelId: number) {}
}

export class SetCurrentPrivateChat {
  static readonly type = '[chat] SetCurrentPrivateChat';
  constructor(public recipientId: number) {}
}

export class LoadChannelMessages {
  static readonly type = '[chat] LoadChannelMessages';
  constructor(public channelId: number) {}
}

export class LoadPrivateChatMessages {
  static readonly type = '[chat] LoadPrivateChatMessages';
  constructor(public recipientId: number) {}
}

export class SaveChannel {
  static readonly type = '[chat] SaveChannel';
  constructor(public channel: Channel) {}
}

export class DeleteChannel {
  static readonly type = '[chat] DeleteChannel';
  constructor(public channelId: number) {}
}

export class UnsetPrivateChat {
  static readonly type = '[chat] UnsetPrivateChat';
}

export class UnsetChannel {
  static readonly type = '[chat] UnsetChannel';
}

export class FollowChannel {
  static readonly type = '[chat] FollowChannel';
  constructor(public channelId: number) {}
}

export class UnfollowChannel {
  static readonly type = '[chat] UnfollowChannel';
  constructor(public channelId: number) {}
}

export class AddMessage {
  static readonly type = '[chat] AddMessage';
  constructor(public message: Message) {}
}

// events from the server

export class JoinPrivateChatEvent {
  static readonly type = 'JoinPrivateChat';
  constructor(public payload: [PrivateChat]) {}
}

export class SendMessageEvent {
  static readonly type = 'SendMessage';
  constructor(public payload: [Message]) {}
}

export class SendPrivateMessageEvent {
  static readonly type = 'SendPrivateMessage';
  constructor(public payload: [Message]) {}
}
