import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { mergeMap, map } from 'rxjs/operators';
import { from } from 'rxjs';
import { IModalService, MessageModalData } from './models';

@Injectable()
export class MobileModalService implements IModalService {
  constructor(private alertController: AlertController) { }

  openMessageModal(messageModalData: MessageModalData) {
    return from(this.alertController.create({
    //  header: 'Delete',
      message: messageModalData.message,
      buttons: [
        {
          text: messageModalData.primaryButtonText,
          role: 'primary'
        },
        {
          text: messageModalData.secondaryButtonText,
          role: 'cancel'
        }
      ]
    })).pipe(
      mergeMap(a => {
        return from(a.present()).pipe(
          mergeMap(_ => {
            return from(a.onDidDismiss()).pipe(
              map(res => {
                return (res.role === 'primary');
              })
            );
          })
        );
      }),
    );
  }
}
