import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Platform} from '@ionic/angular';
import {Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard implements CanActivate {
  constructor(
    public platform: Platform,
    private store: Store,
  ) {
  }

  canActivate() {
    const isMobile = !!this.platform.platforms().find(platform => [
      'android', 'mobile', 'ios', 'iphone', 'mobileweb', 'cordova'
    ].includes(platform));
    if (!isMobile) {
      this.store.dispatch(new Navigate(['/']));
    }

    return isMobile;
  }
}
