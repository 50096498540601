import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Community } from '../../../@core/models';
import { communityNameValidator } from '../../../@shared/validators/community-name.validator';

@Component({
  selector: 'app-community-form',
  templateUrl: './community-form.component.html',
  styleUrls: ['./community-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityFormComponent implements OnChanges {
  @Input() community: Community;

  @Output() communitySubmit = new EventEmitter();
  @Output() cancel = new EventEmitter();

  editMode = false;
  form: FormGroup;

  constructor(fb: FormBuilder) {
    this.form = fb.group({
      name: [null, [Validators.required, communityNameValidator]],
      logo: [null],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.community) {
      if (this.community) {
        this.form.patchValue(this.community);
        this.form.controls.logo.setValue({
          fileName: this.community.logoFileName,
          url: this.community.logoUrl
        });
      }
      this.editMode = !!this.community;
    }
  }

  onSubmit() {
    const {value, valid} = this.form;
    if (valid) {
      const updatedCommunity = {
        ...this.community,
        name: value.name,
        logoFileName: value.logo?.fileName
      };

      this.communitySubmit.emit(updatedCommunity);
    }
  }
}
