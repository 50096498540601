import {Component, OnDestroy, OnInit} from '@angular/core';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {Subscription} from 'rxjs';
import { DialogRef } from '../../../dialog';
import { SendFeedback } from '../../../../store/app.actions';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit, OnDestroy {
  sub: Subscription;

  constructor(
    private store: Store,
    private dialogRef: DialogRef,
    private actions$: Actions
  ) {
  }

  ngOnInit(): void {
    this.sub = this.actions$.pipe(ofActionSuccessful(SendFeedback)).subscribe(_ => this.dialogRef.close());
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onFeedbackSubmit(feedback: string) {
    this.store.dispatch(new SendFeedback(feedback));
  }

  onCancel() {
    this.dialogRef.close();
  }
}
