<ng-container *ngIf="platform.is('desktop'); else mobile">
  <app-topbar
    [user]="user$ | async"
    [activeCommunity]="community$ | async"
    [communities]="communities$ | async"
    (logout)="logout()"
    (communitySelect)="selectCommunity($event)"
  ></app-topbar>

  <div class="container main">
    <div class="columns">
      <div class="column is-one-quarter sidebar" *ngIf="!(hideLeftSidebar$ | async)">
        <app-menu
          [notificationCounts]="notifications$ | async"
          [hideUsersPage]="hideUsersPage$ | async"
          [hideBilling]="hideBilling$ | async"
          (menuItemClick)="setMenuItem($event)">
        </app-menu>
        <app-channels></app-channels>
      </div>
      <div class="column">
        <router-outlet></router-outlet>
      </div>
      <div class="column is-one-quarter sidebar" *ngIf="!(hideRightSidebar$ | async)">
        <app-download-pulse></app-download-pulse>
        <hr>
        <app-last-benefits [benefits]="recentBenefits$ | async"></app-last-benefits>
        <app-last-events [events]="recentEvents$ | async"></app-last-events>
        <ng-content select="[right-sidebar]"></ng-content>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #mobile>
  <app-bottom-bar-layout
    [notificationCounts]="notifications$ | async"
    [menuItemId]="menuItemId$ | async"
    (menuItemClick)="setMenuItem($event)">
  </app-bottom-bar-layout>
</ng-template>
