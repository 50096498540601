<a [routerLink]='link' routerLinkActive="is-active" #rla="routerLinkActive" class="image-notification">
  <app-text-with-icon
    sizeClass="is-36x36"
    [smallText]="true"
    [text]="text"
    [source]="getIconUrl(rla.isActive)"
    [notificationCount]="notificationCount"
  >
  </app-text-with-icon>
</a>
