import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, TemplateRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-multi-dropdown',
  templateUrl: './multi-dropdown.component.html',
  styleUrls: ['./multi-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiDropdownComponent),
      multi: true,
    }
  ]
})
export class MultiDropdownComponent implements ControlValueAccessor {
  @Input() availableOptions;
  @Input() placeholder = 'Select';
  @Input() editOptionText;
  @Input() editTemplate: TemplateRef<any>;
  @Input() editMode = false;
  @Input() isNarrow = false;
  @Input() isSelectionOptional = false;
  @Input() compareFn = ((a: any, b: any) => a === b);
  @Input() maxDropdowns = 4;

  @Output() editOptionClick = new EventEmitter();

  onChange: (values: any[]) => void;

  form: FormArray;
  editModeIndex: number;

  constructor(private fb: FormBuilder) {
    this.form = fb.array([0]);
  }

  addDropdown(afterIdx: number) {
    this.form.insert(afterIdx + 1, this.buildDropdownControl());
    this.notifyValueChanged();
  }

  removeDropdown(idx: number) {
    this.form.removeAt(idx);
    this.notifyValueChanged();
  }

  onEditOptionClick(idx: number) {
    this.editModeIndex = idx;
    this.editOptionClick.emit(idx);
  }

  onOptionValueChange(val: any) {
    this.notifyValueChanged();
  }

  private buildDropdownControl() {
    return this.fb.control(null);
  }

  private notifyValueChanged() {
    if (this.onChange) {
      this.onChange(this.form.value);
    }
  }

  writeValue(val: any[]): void {
    while (this.form.controls.length < val.length) {
      this.form.push(this.buildDropdownControl());
    }

    this.form.patchValue(val);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  //  throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
  //  throw new Error('Method not implemented.');
  }
}
