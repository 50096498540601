import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DialogService} from '../../../@core/modules';
import {Router} from '@angular/router';
import {BenefitTypeDialogComponent} from '../../../@core/modules/benefit-type/containers/benefit-type-dialog.component';


export type EmptyStatePlaceholderType = 'events' | 'news' | 'benefits' | 'polls' | 'chat' | 'surveys';

@Component({
  selector: 'app-empty-state-placeholder',
  templateUrl: './empty-state-placeholder.component.html',
  styleUrls: ['./empty-state-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStatePlaceholderComponent {
  @Input() type: EmptyStatePlaceholderType;
  @Input() text: string;

  configurations = new Map<EmptyStatePlaceholderType, { buttonText: string, click: () => void }>([
    ['news', { buttonText: 'post', click: () => this.goToAddPost() }],
    ['events', { buttonText: 'event', click: () => this.goToAddEvent() }],
    ['benefits', { buttonText: 'benefit', click: () => this.showBenefitTypeDialog() }],
    ['chat', { buttonText: 'channel', click: () => this.gotToAddChannel() }],
    ['polls', { buttonText: 'poll', click: () => this.gotToAddPoll() }],
    ['surveys', { buttonText: 'survey', click: () => this.gotToAddSurvey() }],
  ]);

  get buttonText(): string {
    return this.configurations.get(this.type).buttonText;
  }

  get source(): string {
    if (this.type) {
      return `assets/icons/empty-${this.type}.svg`;
    } else {
      return 'assets/icons/empty-news.svg';
    }
  }

  constructor(
    private router: Router,
    private dialogService: DialogService) { }

  addNew() {
    this.configurations.get(this.type).click();
  }

  private goToAddPost() {
    this.router.navigate(['/news/add']);
  }

  private goToAddEvent() {
    this.router.navigate(['/events/add']);
  }

  private gotToAddChannel() {
    this.router.navigate(['/chat/create']);
  }

  private gotToAddPoll() {
    this.router.navigate(['/polls/add']);
  }

  private gotToAddSurvey() {
    this.router.navigate(['/polls/surveys/add']);
  }

  private showBenefitTypeDialog() {
    this.dialogService.open(BenefitTypeDialogComponent, {
      width: 320
    });
  }
}
