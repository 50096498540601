import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { Channel, ChannelsLoading } from '../../channels';

@Component({
  selector: 'app-channels-chats',
  templateUrl: './channels-chats.component.html',
  styleUrls: ['./channels-chats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelsChatsComponent {
  @Input() channels: Channel[];
  @Input() loading: ChannelsLoading;
}
