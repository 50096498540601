import { Component, OnInit, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { DialogContent } from '../../dialog-content';
import { DialogRef } from '../../dialog-ref';
import { DialogComponentOptions } from './dialog-component-options';

@Component({
  selector: 'lib-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
 // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent implements OnInit {
  renderMethod: 'template' | 'component' | 'text' = 'component';
  content: DialogContent;
  context;
  options?: DialogComponentOptions;

  constructor(private dialogRef: DialogRef) {
  }

  ngOnInit() {
    this.content = this.dialogRef.content;
    this.options = this.dialogRef.options;

    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    } else if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.dialogRef.close.bind(this.dialogRef)
      };
    }
  }

  // ngAfterViewInit() {
  //   if (this.options.backgroundColor) {
  //     this.renderer.setStyle(this.card.nativeElement, 'background-color', this.options.backgroundColor);
  //   }
  // }
}
