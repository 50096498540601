import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'year',
  pure: true
})
export class YearPipe implements PipeTransform {

  transform(value: any, isFullMonthName?: boolean): any {
    if (value) {
      const date = new Date(value);
      return date.getFullYear();
    }
    return value;
  }

}
