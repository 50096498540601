import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission, AuthorizationService } from '../../@core/services/authorization.service';

@Directive({
  selector: '[ifHasPermission]'
})
export class IfHasPermissionDirective {
  @Input()
  set ifHasPermission(permissions: Permission | Permission[]) {
    if (!permissions) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      permissions = Array.isArray(permissions) ? permissions : [permissions];
      this.updateView(permissions);
    }
  }

  @Input()
  set ifHasPermissionElse(templateRef: TemplateRef<any>|null) {
    if (templateRef) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(templateRef);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationService
  ) {
  }

  private updateView(permissions: Permission[]) {
    this.authorizationService.hasPermission(permissions).subscribe(hasPermission => {
      if (hasPermission) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
