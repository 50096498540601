import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Message, PrivateChat } from './channels';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrivateChatService {
  get baseApiUrl() {
    return environment.baseApiUrl;
  }

  constructor(private httpClient: HttpClient) { }

  getChatMessages(recipientId: number) {
    return this.httpClient.get<Message[]>(`${this.baseApiUrl}users/${recipientId}/messages`);
  }

  get(recipientId: number) {
    return this.httpClient.get<PrivateChat>(`${this.baseApiUrl}private-chats/${recipientId}`);
  }
}
