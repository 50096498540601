const isMobile = (win: Window) =>
  testUserAgent(win, /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i) ||
  // iPad iOS 13+
  (testUserAgent(win, /Macintosh/i) && win.navigator.maxTouchPoints > 1);

const isDesktop = (win: Window) =>
  !isMobile(win);

const isMobileWeb = (win: Window): boolean =>
  isMobile(win) && !isHybrid(win);

const isHybrid = (win: Window) =>
  isCordova(win) || isCapacitorNative(win);

const isCordova = (win: any): boolean =>
  !!(win['cordova'] || win['phonegap'] || win['PhoneGap']);

const isCapacitorNative = (win: any): boolean => {
  const capacitor = win['Capacitor'];
  return !!(capacitor && capacitor.isNative);
};

export const testUserAgent = (win: Window, expr: RegExp) =>
  expr.test(win.navigator.userAgent);

export const PLATFORMS_MAP = {
  'cordova': isCordova,
  'capacitor': isCapacitorNative,
  'mobile': isMobile,
  'mobileweb': isMobileWeb,
  'desktop': isDesktop,
  'hybrid': isHybrid
};
