import {Component} from '@angular/core';
import { DialogRef } from '../../dialog';

@Component({
  selector: 'app-benefit-type-dialog',
  templateUrl: './benefit-type-dialog.component.html',
  styleUrls: ['./benefit-type-dialog.component.scss']
})
export class BenefitTypeDialogComponent {


  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  onCancel() {
    this.dialogRef.close();
  }
}
