import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-download-content',
  templateUrl: './download-content.component.html',
  styleUrls: ['./download-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DownloadContentComponent {
  @Output() cancel = new EventEmitter();
}
