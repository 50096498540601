<ng-container *ngIf="role" platform #p="platform">
  <span class="role">
    <span
      class="icon is-24x24 has-text-weight-bold"
      [class.dark]="isDarkTheme"
      *isDesktop
    >
      {{firstChar}}
    </span>
    <span
      class="role-container has-text-weight-bold"
      [class.dark]="isDarkTheme"
      *isMobile>
      {{role}}
    </span>


    <span *isDesktop class="tooltip text-info">
      {{role}}
    </span>
  </span>

</ng-container>
