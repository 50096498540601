import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {AuthorizationService} from '../services/authorization.service';
import {Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private store: Store,
    private authorizationService: AuthorizationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if (!route.firstChild.data.permission) {
      return true;
    }
    return this.authorizationService.hasPermission(route.firstChild.data.permission).pipe(
      map(hasPermission => {
        if (!hasPermission) {
          this.store.dispatch(new Navigate(['/']));
          return false;
        }
        return true;
      })
    );
  }
}
