<div class="control">
  <ng-container *isMobile>
    <input class="input" [id]="'time-input-' + formControlName" type="text" placeholder="Choose Date" [value]="displayValue">
    <ion-modal [trigger]="'time-input-' + formControlName">
      <ng-template>
        <ion-content force-overscroll="false">
          <ion-datetime
            [value]="value"
            presentation="time"
            [showDefaultButtons]="true"
            hourCycle="h23"
            (ionChange)="onTimeChanged($event)">
          </ion-datetime>
        </ion-content>
      </ng-template>
    </ion-modal>
  </ng-container>

  <input *isDesktop bulmaCalendar
    bulmaCalendarType="time"
    type="time">
</div>
