import { Component, OnInit, ChangeDetectionStrategy, forwardRef, Input, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Platform } from '@ionic/angular';

function convertUTCDateToLocalDate(dateStr: string) {
  const date = new Date(dateStr);
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate.toISOString();
}

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    }
  ],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string;

  displayValue = '';
  value: string;

  _onChange: (value: string) => any;

  constructor(private cdr: ChangeDetectorRef, private platform: Platform) {
    const date = new Date();
    date.setUTCHours(20);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);

    this.value = date.toISOString();
  }

  ngOnInit(): void {
  }

  onTimeChanged(e) {
    this.value = e.detail.value;
    this.displayValue = new Date(e.detail.value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    this._onChange(e.detail.value);
  }

  writeValue(obj: any): void {
    // only for ionic datepicker
    if (this.platform.is('mobile')) {
      if (obj) {
        // ion datepicker does not respect UTC date
        this.value = convertUTCDateToLocalDate(obj);
        this.displayValue = new Date(obj).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        this.cdr.markForCheck();
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }
}
