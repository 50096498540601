import {ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {NgControl} from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { BulmaCalendarService } from '../../@core/services/bulma-calendar.service';

@Directive({
  selector: '[bulmaCalendar]',
})
export class BulmaCalendarDirective implements OnInit, OnDestroy {
  @Input() bulmaCalendarType: 'date' | 'time';
  id: string;

  private currentValue: string;
  private sub: Subscription;

  constructor(
    private bulmaCalendarService: BulmaCalendarService,
    private elementRef: ElementRef,
    private formControl: NgControl,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.id = this.bulmaCalendarService.create(
      this.elementRef,
      this.bulmaCalendarType,
      x => {
        this.currentValue = x;
        this.formControl.control.setValue(x);

        this.cdr.markForCheck();
      }
    );

    this.sub = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value)
    ).subscribe(value => {
      if (value !== this.currentValue) {
        this.bulmaCalendarService.setValue(this.id, value);
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.bulmaCalendarService.destroy(this.id);
  }
}
