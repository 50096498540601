import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {Navigate} from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { DialogRef } from '../../dialog';




@Component({
  selector: 'app-benefit-type-content',
  templateUrl: './benefit-type-content.component.html',
  styleUrls: ['./benefit-type-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitTypeContentComponent {
  @Output() cancel = new EventEmitter();


  constructor(
    private store: Store,
    private dialogRef: DialogRef,
  ) {}

  navigateToBenefitCreate() {
    this.dialogRef.close();
    this.store.dispatch(new Navigate(['/benefits/add']));
  }

  navigateToWeblinkCreate() {
    this.dialogRef.close();
    this.store.dispatch(new Navigate(['/benefits/add'], {web: true}));
  }

}

