<app-page remove-wrapper title="{{editMode ? 'Edit' : 'Create new'}} channel" platform #p="platform" backRoute="/chat">
    <div class="card" [ngClass]="{'no-border-radius': p.mobile}">
      <div class="card-content" [ngClass]="{'has-padding-mlg': p.desktop}">
        <div *isDesktop class="page-title-wrapper">
          <h1 class="text-header-2">{{editMode ? 'Edit' : 'Create new'}} channel</h1>
        </div>
        <app-channel-form
          [channel]="channel$ | async"
          [saving]="saving$ | async"
          (channelFormSubmit)="onSubmitChannel($event)">
        </app-channel-form>
      </div>
    </div>
  </app-page>
