import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MoreButtonComponent} from '../more-button/more-button.component';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent extends MoreButtonComponent{
  @Input() options: any[] = [];

  @Output() optionClick = new EventEmitter<number>();

  dropdownOpen = false;
}
