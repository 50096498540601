import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { DialogRef, DialogContent } from './dialog-ref';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { DialogComponent } from './components/dialog/dialog.component';
import { DIALOG_DATA } from './dialog-data';
import { DialogComponentOptions } from './components/dialog/dialog-component-options';

export interface DialogConfig<T> {
  data?: T;
  width?: string | number;
  height?: string | number;
  panelClass?: string;
  options?: DialogComponentOptions;
}

const defaultDialogOptions: DialogConfig<any> = {
  // width: 400,
  // height: 200
};

@Injectable()
export class DialogService {
  constructor(private overlay: Overlay, private injector: Injector) { }

  open<T>(content: DialogContent, config?: DialogConfig<T>) {
    config = { ...defaultDialogOptions, ...config };
    const overlayRef = this.overlay.create(this.getOverlayConfig({ width: config.width, height: config.height, panelClass: config.panelClass }));

    const dialogRef = new DialogRef<T>(overlayRef, content, config.data, config.options);

    const injector = this.createInjector(config, dialogRef, this.injector);
    overlayRef.attach(new ComponentPortal(DialogComponent, null, injector));

    return dialogRef;
  }

  private createInjector(config: DialogConfig<any>, dialogRef: DialogRef, injector: Injector) {
    const tokens = new WeakMap<any, any>([
      [DialogRef, dialogRef],
      [DIALOG_DATA, config.data]
    ]);
    return new PortalInjector(injector, tokens);
  }

  private getOverlayConfig({ width, height, panelClass = '' }): OverlayConfig {
    return new OverlayConfig({
      width,
      height,
      panelClass,
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block()
    });
  }
}
