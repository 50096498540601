import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { DialogService } from '../dialog';
import {MessageModalComponent} from './components/message-modal/message-modal.component';
import { IModalService, MessageModalData } from './models';

@Injectable()
export class ModalService implements IModalService {

  constructor(private dialogService: DialogService) { }

  openMessageModal(messageModalData: MessageModalData) {
    const ref = this.dialogService.open(MessageModalComponent, {
      width: 460,
      data: messageModalData
    });

    return ref.afterClosed$.pipe(
      map((event) => !!event.data)
    );
  }
}
