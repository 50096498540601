import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ActivateIfLoggedIn, PermissionsGuard} from './@core/guards';
import { MainLayoutComponent } from './layout/components/main-layout/main-layout.component';
import { CommunitySelectedGuard } from './@core/guards/community-selected.guard';
import { FeatureGuard } from './@core/guards/feature.guard';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [ActivateIfLoggedIn, PermissionsGuard, CommunitySelectedGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/news'
      },
      {
        path: 'news',
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
        canLoad: [FeatureGuard]
      },
      {
        path: 'polls',
        loadChildren: () => import('./polls/polls.module').then(m => m.PollsModule),
        canLoad: [FeatureGuard]
      },
      {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
        canLoad: [FeatureGuard]
      },
      {
        path: 'benefits',
        loadChildren: () => import('./benefits/benefits.module').then(m => m.BenefitsModule),
        canLoad: [FeatureGuard]
      },
      {
        path: 'menu',
        loadChildren: () => import('./more/more.module').then(m => m.MoreModule),
      },
      {
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
        canLoad: [FeatureGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./@core/modules/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'statistics',
        loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
        data: {
          permission: 'statistics_view'
        }
      },
      {
        path: 'community',
        loadChildren: () => import('./community/community.module').then(m => m.CommunityModule),        
      },
      {
        path: 'billing',
        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
        data: {
          permission: 'billing_manage'
        },
        canLoad: [FeatureGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canLoad: [FeatureGuard]
      },
      {
        path: 'locations',
        loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
        data: {
          permission: 'locations_view'
        },
        canLoad: [FeatureGuard]
      },
    ]
  },
  {
    path: '',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
