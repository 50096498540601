import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'pagination',
  pure: true
})
export class PaginationPipe implements PipeTransform {

  transform(values: any[], objectsPerPage: number = 10, page: number = 1): any {
    if (!values) {
      return;
    }

    const startingPage = objectsPerPage * page - objectsPerPage;
    const endingPage = startingPage + objectsPerPage;
    const currentPageObjects = values.slice(startingPage, endingPage);

    return currentPageObjects;
  }

}
