<ng-container *isDesktop>
    <app-home-navbar *ngIf="!isCustomSubdomain"></app-home-navbar>
  
    <div class="container initial" [class.custom-subdomain]="isCustomSubdomain">
      <div class="columns is-centered">
        <router-outlet></router-outlet>
      </div>
    </div>
  
    <!-- Might/will be used once rebranding is completed <app-home-footer *ngIf="!isCustomSubdomain"></app-home-footer> -->
  </ng-container>
  
  <ng-container *isMobile>
    <ion-router-outlet></ion-router-outlet>
  </ng-container>
  