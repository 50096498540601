import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Profile } from './profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private httpClient: HttpClient) {
  }

  get(): Observable<Profile> {
    return this.httpClient.get<Profile>(`${environment.baseApiUrl}users/profile`);
  }

  save(user: Profile): Observable<Profile> {
    return this.httpClient.put<Profile>(`${environment.baseApiUrl}v2/users/profile`, user);
  }

  deleteProfileAvatar() {
    return this.httpClient.delete(`${environment.baseApiUrl}v2/users/profile/avatar`);
  }

  delete() {
    return this.httpClient.delete(`${environment.baseApiUrl}v2/users`);
  }
}
