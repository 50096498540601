import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {DateAgoPipe} from './pipes/date-ago.pipe';
import {MonthPipe} from './pipes/month.pipe';
import {MonthDayPipe} from './pipes/month-day.pipe';
import {WeekDayPipe} from './pipes/week-day.pipe';
import {TimePipe} from './pipes/time.pipe';
import {EventGroupPipe} from './pipes/event-group.pipe';

import {ButtonComponent} from './components/button/button.component';
import {IconComponent} from './components/icon/icon.component';
import {AComponent} from './components/a/a.component';
import {NotificationsNumberComponent} from './components/notifications-number/notifications-number.component';
import {FindUserPipe} from './pipes/find-user.pipe';
import {TextWithIconComponent} from './components/text-with-icon/text-with-icon.component';
import {YearPipe} from './pipes/year.pipe';
import {FullDateComponent} from './components/full-date/full-date.component';
import {TextWithStrikeComponent} from './components/text-with-strike/text-with-strike.component';
import {MessagesDateBlockPipe} from './pipes/messages-date-block.pipe';
import {TruncatePipe} from './pipes/truncate.pipe';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {ReactiveFormsModule} from '@angular/forms';
import {IfHasPermissionDirective} from './directives/if-has-permission.directive';
import {IfHasFeatureDirective} from './directives/if-has-feature.directive';
import {HomeNavbarComponent} from './components/home-navbar/home-navbar.component';
import {HomeFooterComponent} from './components/home-footer/home-footer.component';
import {ShowNumberOfElementsPipe} from './pipes/show-number-of-elements.pipe';
import {MoreButtonComponent} from './components/more-button/more-button.component';
import {ListItemComponent} from './components/list-item/list-item.component';
import {UserSpacesComponent} from './components/user-spaces/user-spaces.component';
import {CheckboxControlComponent} from './components/checkbox-control/checkbox-control.component';
import {BulmaCalendarDirective} from './directives/bulma-calendar.directive';
import {ControlErrorComponent} from './components/control-error/control-error.component';
import {ControlErrorsDirective} from './directives/control-errors.directive';
import {ControlErrorContainerDirective} from './directives/control-error-container.directive';
import {FormSubmitDirective} from './directives/form-submit.directive';
import {UserAvatarComponent} from './components/user-avatar/user-avatar.component';
import {AddressAutocompleteDirective} from './directives/address-autocomplete.directive';
import {LastBenefitsComponent} from './components/last-benefits/last-benefits.component';
import {LastEventsComponent} from './components/last-events/last-events.component';
import {DownloadPulseComponent} from './components/download-pulse/download-pulse.component';
import {EventMonthDayComponent} from './components/event-month-day/event-month-day.component';
import {EmptyStatePlaceholderComponent} from './components/empty-state-placeholder/empty-state-placeholder.component';
import {OptionalDirective} from './directives/optional.directive';
import {MultiDropdownComponent} from './components/multi-dropdown/multi-dropdown.component';
import {EmptyStatePlaceholderDirective} from './directives/empty-state-placeholder.directive';
import {RoleComponent} from './components/role/role.component';
import {PaginationPipe} from './pipes/pagination.pipe';
import {SortPipe} from './pipes/sort.pipe';
import {NotificationComponent} from './components/notification/notification.component';
import {NotificationLightComponent} from './components/notification-light/notification-light.component'
import {DropdownMenuComponent} from './components/dropdown-menu/dropdown-menu.component';
import {CircularLoaderComponent} from './components/circular-loader/circular-loader.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {IonicModule} from '@ionic/angular';
import {PageComponent} from './components/page/page.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {IsDesktopDirective} from './directives/is-desktop.directive';
import {IsMobileDirective} from './directives/is-mobile.directive';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { RemoveHtmlPipe } from './pipes/remove-html.pipe';
import {LongPressDirective} from './directives/long-press.directive';
import { ChartComponent } from './components/chart/chart.component';
import { CounterComponent } from './components/counter/counter.component';
import { RemoveWrapperDirective } from './directives/remove-wrapper.directive';
import { PlatformDirective } from './directives/platform.directive';
import { ContextButtonComponent } from './components/context-button/context-button.component';
import { ClickCursorDirective } from './directives/click-cursor.directive';
import { MultiLocationsComponent } from './components/multi-locations/multi-locations.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { RoleDropdownComponent } from './components/role-dropdown/role-dropdown.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { IfAdminDirective } from './directives/if-admin.directive';
import { UserDirective } from './directives/user.directive';
import { CommunityLogoComponent } from './components/community-logo/community-logo.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CurrencySignPipe } from './pipes/currency-sign.pipe';
import { DateFrequencyPipe } from './pipes/date-frequency.pipe';

const COMPONENTS = [
  ButtonComponent,
  IconComponent,
  AComponent,
  NotificationsNumberComponent,
  TextWithIconComponent,
  FullDateComponent,
  TextWithStrikeComponent,
  DropdownComponent,
  HomeNavbarComponent,
  HomeFooterComponent,
  MoreButtonComponent,
  ListItemComponent,
  UserSpacesComponent,
  CheckboxControlComponent,
  ControlErrorComponent,
  UserAvatarComponent,
  RoleComponent,
  MultiDropdownComponent,
  EmptyStatePlaceholderComponent,
  EventMonthDayComponent,
  DownloadPulseComponent,
  LastEventsComponent,
  LastBenefitsComponent,
  MultiDropdownComponent,
  CircularLoaderComponent,
  NotificationComponent,
  NotificationLightComponent,
  DropdownMenuComponent,
  ProgressBarComponent,
  PageComponent,
  ChartComponent,
  CounterComponent,
  ContextButtonComponent,
  MultiLocationsComponent,
  DatePickerComponent,
  TimePickerComponent,
  RoleDropdownComponent,
  UserFormComponent,
  CommunityLogoComponent,
  LoaderComponent,
];

const DIRECTIVES = [
  IfHasPermissionDirective,
  IfHasFeatureDirective,
  BulmaCalendarDirective,
  ControlErrorsDirective,
  ControlErrorContainerDirective,
  FormSubmitDirective,
  OptionalDirective,
  AddressAutocompleteDirective,
  EmptyStatePlaceholderDirective,
  IsMobileDirective,
  IsDesktopDirective,
  PlatformDirective,
  LongPressDirective,
  RemoveWrapperDirective,
  ClickCursorDirective,
  ClickOutsideDirective,
  IfAdminDirective,
  UserDirective
];

const PIPES = [
  DateAgoPipe,
  MonthPipe,
  MonthDayPipe,
  WeekDayPipe,
  TimePipe,
  EventGroupPipe,
  FindUserPipe,
  YearPipe,
  MessagesDateBlockPipe,
  TruncatePipe,
  ShowNumberOfElementsPipe,
  PaginationPipe,
  SortPipe,
  LinkifyPipe,
  SafeHtmlPipe,
  RemoveHtmlPipe,
  CurrencySignPipe,
  DateFrequencyPipe
];


@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    IonicModule,
    InfiniteScrollModule,
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    IonicModule,
  ]
})
export class SharedModule {}
