<div class="dropdown is-right is-active text-list-item">
  <div class="dropdown-trigger" (click)="onClick()">
    <a class="icon is-medium" aria-haspopup="true" [class.active]="dropdownOpen">
      <app-icon source="more.svg"></app-icon>
    </a>
  </div>
  <div class="dropdown-menu narrow" *ngIf="dropdownOpen">
    <div class="dropdown-content" (clickOutside)="onClickOutside()">
      <a class="dropdown-item" (click)="onItemClick(item)" *ngFor="let item of menuItems">{{item.label}}</a>
      <!-- <ng-container *ngIf="!hideEdit">
        <a
          class="dropdown-item"
          [routerLink]="editRoute"
          (click)="edit.emit()">
          Edit
        </a>
      </ng-container>
      <a class="dropdown-item" (click)="onDelete()" *ifHasPermission="deletePermission">{{deleteText}}</a>
      <a *ngIf="optionalDropdownOption && !hideOptionalDropdownOption" class="dropdown-item"
         (click)="optionalDropdownOptionClick.emit()">{{optionalDropdownOption}}
      </a> -->
    </div>
  </div>
</div>
