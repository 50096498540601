<div class="page-title-wrapper">
  <h1 class="page-title">Edit message</h1>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="field has-margin-bottom-mlg">
    <label class="label">Your message</label>
    <div class="control is-fullwidth">
      <textarea
        class="textarea"
        formControlName="message"
        placeholder="Message">
      </textarea>
    </div>
  </div>

  <div class="field is-grouped has-margin-top-lg">
    <div class="control">
      <button type="submit" class="button is-rounded is-secondary">
        Save
      </button>
    </div>
    <div class="control">
      <button type="button" class="button is-rounded" (click)="cancel.emit()">
        Cancel
      </button>
    </div>
  </div>
</form>
