import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ImageCropData} from '../models/image-crop-data';
import {ImageUploadProgress, ImageUploadStatus} from '../models/image-upload-progress';
import {environment} from '../../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ImageUploadService {
  constructor(private httpClient: HttpClient) { }

  upload(
    file: File,
    uploadPath: string,
    cropData: ImageCropData,
    httpMethod: 'POST' | 'PUT' = 'POST',
    httpFormKeyName: string = 'file'
  ): Observable<ImageUploadProgress> {
    // this.setDefaults(options);
    const form = new FormData();
    form.append(httpFormKeyName, file, file.name);

    if (cropData) {
      form.append('X', cropData.x.toString());
      form.append('Y', cropData.y.toString());
      form.append('Width', cropData.width.toString());
      form.append('Height', cropData.height.toString());
    }

    const uploadUrl = environment.baseApiUrl + uploadPath;

    // upload file and report progress
    const req = new HttpRequest(httpMethod, uploadUrl, form, {
      reportProgress: true,
      //  withCredentials: options.withCredentials,
      //  headers: this._buildHeaders(options)
    });

    return new Observable(obs => {
      const imageUpload = new ImageUploadProgress(file);

      imageUpload.request = this.httpClient.request(req).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadProgress(imageUpload, event);
            obs.next(imageUpload);
          } else if (event instanceof HttpResponse) {
            this.uploadComplete(imageUpload, event);
            obs.next(imageUpload);
            obs.complete();
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            this.uploadFailed(imageUpload, err);
            obs.next(imageUpload);
            obs.complete();
          } else {
            // The backend returned an unsuccessful response code.
            this.uploadFailed(imageUpload, err);
            obs.next(imageUpload);
            obs.complete();
          }
        }
      );
    });
  }

  private uploadProgress(imageUpload: ImageUploadProgress, event: any) {
    // update the FileimageUploadect with the current progress
    const progress = Math.round(100 * event.loaded / event.total);
    imageUpload.progress = progress;
    imageUpload.status = ImageUploadStatus.Progress;
    // this._queue.next(this._files);
  }

  private uploadComplete(imageUpload: ImageUploadProgress, response: HttpResponse<any>) {
    // update the FileimageUploadect as completed
    imageUpload.progress = 100;
    imageUpload.status = ImageUploadStatus.Success;
    imageUpload.response = response;
    // this._queue.next(this._files);
    // this.onCompleteItem(imageUpload, response.body);
  }

  private uploadFailed(imageUpload: ImageUploadProgress, response: HttpErrorResponse) {
    // update the FileimageUploadect as errored
    imageUpload.progress = 0;
    imageUpload.status = ImageUploadStatus.Error;
    imageUpload.errorResponse = response;
    // this._queue.next(this._files);
  }
}
