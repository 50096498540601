import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserLogin, UserSession } from '../models';

@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(private httpClient: HttpClient) {
  }
  
  authenticate(login: UserLogin): Observable<any> {
    const body = {
      email: login.username,
      password: login.password,
      companyGroupId: login.companyGroupId
    };

    return this.httpClient.post(`${environment.baseApiUrl}auth/token2`, body);
  }

  refreshAccessToken(refreshToken: string) {
    return this.httpClient.post<any>(`${environment.baseApiUrl}auth/refresh`, {refreshToken});
  }

  switchCommunity(accessToken: string, communityId?: number) {
    return this.httpClient.post<any>(`${environment.baseApiUrl}auth/switch-community`, { accessToken, communityId });
  }

  getUserSession() {
    return this.httpClient.get<UserSession>(`${environment.baseApiUrl}v2/user/session`);
  }
}
