import { TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { OverlayRef, ComponentType } from '@angular/cdk/overlay';
import { DialogComponentOptions } from './components/dialog/dialog-component-options';

export interface DialogCloseEvent {
  type: 'backdropClick' | 'close';
  data: any;
}

export type DialogContent = TemplateRef<any> | ComponentType<any> | string;

export class DialogRef<T = any> {
  private afterClosed = new Subject<DialogCloseEvent>();
  afterClosed$ = this.afterClosed.asObservable();

  constructor(public overlay: OverlayRef, public content: DialogContent, public data: T, public options: DialogComponentOptions) {
    // an example of how to close dialog on backdrop click
    // overlay.backdropClick().subscribe(() => this._close('backdropClick', data));
  }

  close(data?: T) {
    this._close('close', data);
  }

  private _close(type, data) {
    this.overlay.dispose();
    this.afterClosed.next({
      type,
      data
    });
    this.afterClosed.complete();
  }
}
