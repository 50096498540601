import { Profile } from './profile';

export class LoadProfile {
  static readonly type = '[Profile] LoadProfile';
}

export class SaveProfile {
  static readonly type = '[Profile] SaveProfile';
  constructor(public user: Profile) {}
}

export class SetProfileAvatar {
  static readonly type = '[Profile] SetProfileAvatar';
  constructor(public avatarUrl: string) {}
}

export class DeleteProfileAvatar {
  static readonly type = '[Profile] DeleteProfileAvatar';
}

export class DeleteUserProfile {
  static readonly type = '[Profile] DeleteUserProfile';
}
