<a [routerLink]="route" [queryParams]="query" *ngIf="!disabled">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-container *ngIf="disabled">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
