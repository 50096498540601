import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Channel, PrivateChat, ChatType, Message } from '../../channels';
import { Profile } from '../../../@core/modules/profile/profile';
import { Platform } from '@ionic/angular';
import { Keyboard } from '@capacitor/keyboard';

export interface ChatMessage {
  message: string;
  messageId: number;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnDestroy, OnChanges {
  @Input() chat: Channel | PrivateChat;
  @Input() chatType: ChatType;
  @Input() messages: Message[];
  @Input() user: Profile;
  @Input() loading: boolean;

  @Output() sendMessage = new EventEmitter<ChatMessage>();
  @Output() followStatusChange = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() messageEditClick = new EventEmitter<Message>();


  @ViewChild('messagesContainer', {read: ElementRef}) divToScroll: ElementRef;

  editModeMessage: Message;
  firstLoad = true;

  get asPrivateChat() {
    return this.chat as PrivateChat;
  }

  get asChannel() {
    return this.chat as Channel;
  }

  constructor(private platform: Platform) {
  }

  ngOnInit() {
    if (this.platform.is('capacitor')) {
      Keyboard.addListener('keyboardDidShow', () => this.delayedScrollToBottom());
    }
  }

  ngOnDestroy() {
    if (this.platform.is('capacitor')) {
      Keyboard.removeAllListeners();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages && this.messages) {
      const scrollBehavior = this.firstLoad ? 'auto' : 'smooth';
      this.firstLoad = false;

      this.delayedScrollToBottom(scrollBehavior);
    }
  }

  delayedScrollToBottom(behavior = 'auto') {
    setTimeout(() => {
      this.scrollToBottom(behavior);
    }, 100);
  }

  scrollToBottom(behavior = 'auto'): void {
    if (this.divToScroll) {
      this.divToScroll.nativeElement.scrollTo({top: this.divToScroll.nativeElement.scrollHeight, behavior: behavior});
    }
  }

  onMessageSubmit(message: string) {
    this.sendMessage.emit({
      message: message,
      messageId: this.editModeMessage?.id || null,
    });
    if (this.editModeMessage) {
      this.editModeMessage = null;
    }
  }

}
