import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Benefit, BenefitCategory, BenefitCompany} from './benefits';
import { environment } from '../../environments/environment';
import {Observable, forkJoin} from 'rxjs';

export interface BenefitsCombinedResponse {
  benefits: Benefit[];
  categories: BenefitCategory[];
  companies: BenefitCompany[];
}

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {
  get baseApiUrl() {
    return environment.baseApiUrl + 'v2/';
  }

  constructor(private httpClient: HttpClient) {
  }

  getAllCombined() {
    return forkJoin({
      benefits: this.getBenefits(),
      categories: this.getBenefitCategories(),
      companies: this.getBenefitCompanies()
    });
    // return this.httpClient.get<BenefitsCombinedResponse>(`${this.baseApiUrl}benefits/combined`);
  }

  getBenefits() {
    return this.httpClient.get<Benefit[]>(`${this.baseApiUrl}benefits`);
  }

  getBenefitCategories() {
    return this.httpClient.get<BenefitCategory[]>(`${this.baseApiUrl}benefits/categories`);
  }

  getBenefitCompanies() {
    return this.httpClient.get<BenefitCompany[]>(`${this.baseApiUrl}benefit-companies`);
  }

  get(benefitId: number) {
    return this.httpClient.get<Benefit>(`${this.baseApiUrl}benefits/${benefitId}`);
  }

  delete(benefitId: number) {
    return this.httpClient.delete(`${this.baseApiUrl}benefits/${benefitId}`);
  }

  save(benefit: Benefit) {
    if (benefit.id) {
      return this.httpClient.put<Benefit>(`${this.baseApiUrl}benefits/${benefit.id}`, benefit);
    } else {
      return this.httpClient.post<Benefit>(`${this.baseApiUrl}benefits`, benefit);
    }
  }

  saveCompany(company: BenefitCompany) {
    if (company.id) {
      return this.httpClient.put<BenefitCompany>(`${this.baseApiUrl}benefit-companies/${company.id}`, company);
    } else {
      return this.httpClient.post<BenefitCompany>(`${this.baseApiUrl}benefit-companies`, company);
    }
  }

  saveBenefitAttachment(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.httpClient.post<any>(`${this.baseApiUrl}benefits/upload-attachment`, formData, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }
}
