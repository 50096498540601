import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StyleLoadService {

  constructor(@Inject(DOCUMENT) private document: any) { }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const id = styleName.replace('.css', '');
    const existingLink = this.document.getElementById(id) as HTMLLinkElement;

    if (!existingLink) {
      const style = this.document.createElement('link');
      style.id = id;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }
}
