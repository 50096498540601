import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { Permission } from '../../../@core/services/authorization.service';
import { ModalService } from '../../../@core/modules';

@Component({
  selector: 'app-more-button',
  templateUrl: './more-button.component.html',
  styleUrls: ['./more-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreButtonComponent {
  @Input() editRoute: any[];
  @Input() extraMargin = false;
  @Input() options: IMoreButtonOption[] = [];
  @Input() optionalDropdownOption;
  @Input() hideOptionalDropdownOption = false;
  @Input() hideEdit = false;
  @Input() deleteText = 'Delete';
  @Input() deletePermission: Permission;

  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() optionalDropdownOptionClick = new EventEmitter();
  @Output() dropdownOpenEvent = new EventEmitter<boolean>();

  dropdownOpen = false;
  click = false;

  constructor(private modalService: ModalService) {
  }

  onClick() {
    this.dropdownOpen = true;
    this.click = true;
    this.dropdownOpenEvent.emit(this.dropdownOpen);
  }

  onClickOutside() {
    if (!this.click) {
      this.dropdownOpen = false;
      this.dropdownOpenEvent.emit(this.dropdownOpen);
    }

    this.click = false;
  }

  onDelete() {
    this.modalService.openMessageModal({
      message: `Are you sure you want to ${this.deleteText.toLowerCase()}?`,
      primaryButtonText: this.deleteText,
      secondaryButtonText: 'Cancel'
    }).subscribe(confirmed => {
      if (confirmed) {
        this.delete.emit();
      }
    });
  }

  onOptionalDropdownOptionClick() {
    this.optionalDropdownOptionClick.emit();
    this.closeDropdown();
  }

  closeDropdown() {
    this.dropdownOpen = false;
    this.click = false;
    this.dropdownOpenEvent.emit(this.dropdownOpen);
  }
}


export interface IMoreButtonOption {
  title: string;
  onClick: () => void;
}
