<ng-container *isDesktop>
  <div class="has-padding-left-mlg has-padding-right-mlg">
    <div class="field">
      <div class="control is-fullwidth">
        <input
          [formControl]="inputControl"
          (keyup.enter)="sendMessage()"
          class="input"
          type="text"
          placeholder="Write message">
      </div>
    </div>
  </div>
</ng-container>

<ng-container *isMobile>
  <div class="field has-border-top">
    <div class="control is-fullwidth mobile-message-textarea has-padding-left-md has-padding-right-md">
      <span class="image is-32x32">
        <app-user-avatar [avatarUrl]="user?.avatarUrl"></app-user-avatar>
      </span>
      <ion-textarea
        [formControl]="inputControl"
        autocapitalize="sentences"
        (keyup.enter)="sendMessage()"
        type="text"
        placeholder="Write message"
        auto-grow="true"
        maxlength="1000"
        rows=1>
      </ion-textarea>
      <button class="icon-button send-button" (touchstart)="onTouchSendButton($event)">
        <app-icon class="send-button" source="send.svg"></app-icon>
      </button>
    </div>
  </div>
</ng-container>
