import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencySign' })
export class CurrencySignPipe implements PipeTransform {
    transform(str: string): string {
        switch (str.toLocaleLowerCase()) {
            case 'eur':
                return '€'
            case 'usd':
                return '$'
            default:
                return str;
        }
    }
}