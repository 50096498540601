<ng-container *ngFor="let messagesBlock of messages | messagesDateBlock; trackBy: trackByMessageBlock">
  <div class="has-margin-bottom-md">
    <app-text-with-strike [text]="messagesBlock.date">
    </app-text-with-strike>
  </div>

  <div *ngFor="let message of messagesBlock.messages; index as i; trackBy: trackByMessage"
    (mouseover)="hoveredMessageId = message.id"
    (mouseleave)="hoveredMessageId = null"
    [ngClass]="chatUserSwap(messagesBlock.messages, i) ? 'has-margin-bottom-sm' : 'has-margin-bottom-sm'"
    class="has-padding-left-md has-padding-right-md">

    <ng-container *ngIf="chatType==='channel'; else privateMessage">
      <article longPress (longPress)="messageEditModalOpen(message)"
        class="media"
        [class.is-clickable]="isMobile">
        <figure class="media-left">
          <p class="image is-32x32">
            <app-user-avatar [avatarUrl]="message.userAvatarUrl"></app-user-avatar>
          </p>
        </figure>

        <div class="media-content">
          <p class="text-body-1">
            <strong>{{message.userFullName}}</strong>
            <br>
            <span [innerHtml]="message.text | linkify"></span>
          </p>
        </div>

        <a *isDesktop [class.disabled]="!isEditableMessage(message)"
          (click)="messageEditClick.emit(message)"
          class="media-right text-info-sm has-text-grey">
          <div class="is-flex">
            <app-icon *ngIf="isEditableMessage(message)"
              sizeClass="is-small"
              source="message-edit.svg"></app-icon>
            <div *ngIf="!message.isSending">
            {{message.time | time}}
            </div>
          </div>
        </a>

        <div *isMobile class="media-right text-info-sm has-text-grey">
          <div *ngIf="!message.isSending">
            {{message.time | time}}
          </div>
        </div>
      </article>

    </ng-container>


    <ng-template #privateMessage>
      <ng-container *ngIf="message.userFullName === getFormattedUserFullName(); else privateMessageSentByOther">
        <div longPress (longPress)="messageEditModalOpen(message)"
          class="container text-body-1 has-text-right"
          [class.is-clickable]="isMobile">
          <div class="flex-container"
            (mouseover)="hoveredMessageId = message.id"
            (mouseleave)="hoveredMessageId = null">
            <a *isDesktop [class.disabled]="!isEditableMessage(message)"
              (click)="messageEditClick.emit(message)"
              class="flex-container edit-container">
              <app-icon *ngIf="isEditableMessage(message)"
                sizeClass="is-small"
                source="message-edit.svg"></app-icon>
            </a>
            <div class="private-message self">
              <span [innerHtml]="message.text | linkify"></span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #privateMessageSentByOther>
        <article class="level column is-paddingless">
          <figure class="level-left">
            <p class="is-mobile image is-32x32 has-margin-right-sm">
              <app-user-avatar [avatarUrl]="message.userAvatarUrl"></app-user-avatar>
            </p>
            <p class="private-message text-body-1 other">
              <span [innerHtml]="message.text | linkify"></span>
            </p>
          </figure>
        </article>
      </ng-template>
    </ng-template>
  </div>
</ng-container>

<ion-item [hidden]="messageEditModal.hidden" *isMobile>
  <ion-select #messageEditModalRef (ionChange)="messageEditModalUpdate($event)" interface="action-sheet">
    <ion-select-option value="edit">Edit</ion-select-option>
  </ion-select>
</ion-item>
