<nav class="navbar is-fixed-top has-background-beige">
  <div class="container">
    <a href="https://www.fellowup.co/">
      <div class="navbar-brand">
          <div class="image">
            <img src="assets/icons/FellowUp_logo_full.svg">
          </div>
      </div>
    </a>

    <div id="navbarExampleTransparentExample" class="navbar-menu">

      <!-- Might/will be used once rebranding is completed
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="field is-grouped has-text-weight-bold">
            <div class="font-color-action"><a href="https://pulseapp.eu/#why-pulse">Why pulse?</a></div>
            <div class="font-color-action"><a href="https://pulseapp.eu/#how-pulse-works">How pulse works?</a></div>
            <div class="font-color-action"><a href="https://pulseapp.eu/#features">Features</a></div>
            <div class="font-color-action"><a href="https://pulseapp.eu/#download">Download</a></div>
            <div class="font-color-action"><a href="https://pulseapp.eu/pulse-blog">Blog</a></div>
            <div class="font-color-action"><a href="/login">Login</a></div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</nav>
