import {Component} from '@angular/core';
import {Select} from '@ngxs/store';
import {AppState} from '../../store/app.state';
import {Observable} from 'rxjs';
import {Community, Feature} from '../../models';


@Component({
  selector: 'app-add-new-item-page',
  templateUrl: './add-new-item-page.component.html',
  styleUrls: ['./add-new-item-page.component.scss'],
})
export class AddNewItemPageComponent {
  @Select(AppState.standardPlan) isStandardPlan$: Observable<boolean>;
  @Select(AppState.community) community$: Observable<Community>;
  @Select(AppState.features) features$: Observable<Feature[]>;
}
