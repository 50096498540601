import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'monthDay',
  pure: true
})
export class MonthDayPipe implements PipeTransform {

  transform(value: any, isOrdinalNumber?: boolean): any {
    if (value) {
      const date = new Date(value);
      const monthDay = date.getDate();
      value = (isOrdinalNumber) ? monthDay + this.getSuffixForMonthDay(monthDay) : monthDay;
    }
    return value;
  }

  getSuffixForMonthDay(monthDay: number) {
    if (monthDay > 3 && monthDay < 21) {
      return 'th';
    }
    switch (monthDay % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

}
