import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateFrequency' })
export class DateFrequencyPipe implements PipeTransform {
    transform(str: string): string {
        switch (str.toLocaleLowerCase()) {
            case 'day':
                return 'Daily'
            case 'month':
                return 'Monthly'
            case 'year':
                return 'Yearly'
            default:
                return str;
        }
    }
}