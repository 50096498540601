import { Directive, ElementRef, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Subject } from 'rxjs';
import { MapLocation } from '../../more/models/map-location';

@Directive({
  selector: '[addressAutocomplete]',
  exportAs: 'addressAutocomplete'
})
export class AddressAutocompleteDirective implements OnInit, OnDestroy {
  @Output() locationChange: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter<google.maps.places.PlaceResult>();

  get locationChanges$() {
    return this.locationChanges.asObservable();
  }

  private autocomplete: google.maps.places.Autocomplete;
  private locationChanges = new Subject<MapLocation>();

  constructor(
    private el: ElementRef,
    private mapsAPILoader: MapsAPILoader) { }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, {
        types: ['address']
      });

      this.autocomplete.addListener('place_changed', () => {
        // force form value to update
        this.el.nativeElement.dispatchEvent(new Event('input'));

        const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();

        if (!place.geometry) {
          return;
        }

        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        this.locationChanges.next({
          latitude,
          longitude,
        });

        this.locationChange.emit(place);
      });
    });
  }

  ngOnDestroy(): void {
    this.autocomplete.unbindAll();
    this.autocomplete = null;
  }

  clear() {
    this.el.nativeElement.value = '';
  }
}
