<div class="has-text-centered">

  <div class="icon is-semi-round has-margin-bottom-sm">
    <app-icon source="FellowUp_logo.svg" sizeClass="is-large"></app-icon>
  </div>

  <p class="text-body-2">FellowUp app</p>
  <p class="text-body-1 has-margin-bottom-md">Available on iOS or Android</p>
  <a class="button is-rounded is-outlined is-secondary"
     (click)="onDownloadClick()"
     target="_blank">
    Download
  </a>
</div>
