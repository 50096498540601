export interface UserLogin {
  username: string;
  password: string;
  companyGroupId?: number;
}

export interface UserSession {
  userId: number;
  communityId?: number;
  communityName?: string;
  plan: CommunityPlan;
  role: Role;
  permissions: string[];
  features: Feature[];
  logoutNeeded: boolean;
}

export interface Community {
  id: number;
  name: string;
  logoUrl: string;
  logoFileName: string;
  enabled: boolean
}

export interface CommunityLogo {
  id: number;
  logoUrl: string;
  logoFileName: string;
}

export interface CommunityName {
  id: number;
  name: string;
}

export enum CommunityPlan {
  Free = 'Free',
  Standard = 'Standard',
  Stopped = 'Stopped'
}

export enum Role {
  Admin = 'Admin',
  Editor = 'Editor',
  User = 'User',
}

export type Feature =
  'News' |
  'Polls' |
  'Events' |
  'Benefits' |
  'PrivateChats' |
  'Channels' |
  'Comments' |
  'UserReactivation' |
  'Locations' |
  'AddWorkplace' |
  'ManageWhitelist' |
  'ManageBulkUsersUpload' |
  'HideUsersListForUserRole' |
  'DisableBilling' |
  'HideCreateCommunityOption'

  export interface CreateCommunityRequest {
    name: string,
    logoFileName: string,
    billingInformation: CreateSubscriptionRequest | null,
    featureToggles: FeatureToggle[]
  }
  
  export interface FeatureToggle {
    feature: Feature,
    enabled: boolean
  }

  export interface CreateSubscriptionRequest {
    pricingInternalId: number;
    customer: Customer;
    cardToken: string;
  }

  export interface Customer {
    name: string;
    country: string;
    address: string;
    postalCode: string;
    email: string;
    companyName: string;
    vatCode: string;
    companyCode: string;
  }

  export interface PaymentIntent {
    clientSecret: string
  }