import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {tap} from 'rxjs/operators';

import {AppState} from '../store/app.state';

@Injectable({
  providedIn: 'root'
})
export class ActivateIfLoggedIn implements CanActivate {
  constructor(private store: Store, private router: Router) { }

  canActivate() {
    return this.store.selectOnce(AppState.isLoggedIn).pipe(
      tap(isLoggedIn => {
        if (!isLoggedIn) {
          this.router.navigate(['login'], { queryParams: { redirect: this.router.routerState.snapshot.url } });
        }
      })
    );
  }
}
