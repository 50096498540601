import { Component, OnInit, ChangeDetectionStrategy, forwardRef, Input, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    }
  ],
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() min: string;
  @Input() max: string;

  displayValue = '';
  value: string;

  _onChange: (value: string) => any;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  onStartDateChanged(e) {
    this.value = e.detail.value;
    this.displayValue = new Date(e.detail.value).toLocaleDateString();
    this._onChange(e.detail.value);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
      this.displayValue = new Date(obj).toLocaleDateString();
      this.cdr.markForCheck();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }
}
