<app-page [backRoute]="backUrl" remove-wrapper>
  <div title-content class="level is-mobile">
    <div class="level-left">
      <span class="image is-32x32 has-margin-right-sm">
        <app-user-avatar [avatarUrl]="(currentChat$ | async)?.iconUrl"></app-user-avatar>
      </span>
      <h1 class="text-subheading-1">{{(currentChat$ | async)?.name}}</h1>
    </div>
  </div>
  <app-chat
    [chat]="currentChat$ | async"
    [messages]="currentChatMessages$ | async"
    [chatType]="'private'"
    [user]="user$ | async"
    (sendMessage)="sendMessage($event)"
    (messageEditClick)="onMessageEditClick($event)"
  ></app-chat>
</app-page>
