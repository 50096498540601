import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import { FeatureService } from '../../@core/services/feature.service';
import { Feature } from '../../@core/models';

@Directive({
  selector: '[ifHasFeature]'
})
export class IfHasFeatureDirective {
  @Input()
  set ifHasFeature(features: Feature | Feature[]) {
    if (!features) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      features = Array.isArray(features) ? features : [features];
      this.updateView(features);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureService: FeatureService
  ) {
  }

  private updateView(features: Feature[]) {
    this.featureService.hasFeature(features).subscribe(hasFeature => {
      if (hasFeature) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
