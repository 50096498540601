import {Pipe, PipeTransform} from '@angular/core';
import { CommunityMember } from '../../more/more';

@Pipe({
  name: 'findUser',
  pure: true
})
export class FindUserPipe implements PipeTransform {

  transform(communityMembers: CommunityMember[], query: string): any {
    if (query) {
      return communityMembers.filter(communityMember => {
        if (communityMember.name) {
          const memberName = communityMember.name.toLowerCase();
          return memberName.indexOf(query.toLowerCase()) !== -1;
        }
        else
        {
          return false;
        }
      });
    } else {
      return communityMembers;
    }
  }

}
