import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-a',
  templateUrl: './a.component.html',
  styleUrls: ['./a.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AComponent implements OnInit {
  @Input() route: any[];
  @Input() query;
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit() { }
}
