import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '../services/toast.service';
import { MonitoringService } from '../services/monitoring.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }

  private get toastService(): ToastService {
    return this.injector.get(ToastService);
  }

  private get monitoringService(): MonitoringService {
    return this.injector.get(MonitoringService);
  }

  public handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        this.showToastError('No Internet Connection');
      } else {
        switch (error.status) {
          case 401:
            // ignore
            break;
          case 0:
          case 429:
            // ignore CORS errors and rate-limiting errors
            this.monitoringService.logException(error);
            break;
          case 400:
            if (error.error && error.error.message) {
              // server validation message
              this.showToastError(error.error.message);
              break
            }
          default:            
            this.showToastError('Sorry, something went wrong. If you need help resolving the issue, please contact our support team.')
            this.monitoringService.logException(error);
        }
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      this.showToastError('Sorry, something went wrong. If you need help resolving the issue, please contact our support team.')
      this.monitoringService.logException(error);
    }

    // Log the error anyway
    console.error(error);
  }

  private showToastError(message: string, title?: string) {
    this.toastService.error(message, title || 'Error');
  }
}
