import {Directive} from '@angular/core';
import { AuthorizationService } from '../../@core/services/authorization.service';

@Directive({
  selector: '[user]',
  exportAs: 'user'
})
export class UserDirective {
  get id() {
    return this.authService.getUserInfo()?.userId;
  }

  get role() {
    return this.authService.getUserInfo()?.role;
  }

  get isAdmin() {
    return this.authService.isAdmin();
  }

  get roleIsUser() {
    const role = this.role;
    return role !== 'Admin' && role !== 'Editor'
  }

  constructor(private authService: AuthorizationService) {}
}
