import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChannelsComponent} from './containers/channels/channels.component';
import {ChannelsChatsComponent} from './components/channels-chats/channels-chats.component';
import {PrivateChatsComponent} from './components/private-chats/private-chats.component';
import {SharedModule} from '../@shared/shared.module';
import {NgxsModule} from '@ngxs/store';
import {ChannelEditComponent} from './containers/channel-edit/channel-edit.component';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CHAT_ROUTES} from './chat.routes';
import {ChannelFormComponent} from './components/channel-form/channel-form.component';
import {ChatComponent} from './components/chat/chat.component';
import {ChannelChatPageComponent} from './containers/channel-chat-page/channel-chat-page.component';
import {PrivateChatPageComponent} from './containers/private-chat-page/private-chat-page.component';
import {MessagesComponent} from './components/messages/messages.component';
import { MessageEditDialogComponent } from './containers/message-edit-dialog/message-edit-dialog.component';
import { MessageEditFormComponent } from './components/message-edit-form/message-edit-form.component';
import { MessageInputComponent } from './components/message-input/message-input.component';
import { ChatState } from './chat.state';

@NgModule({
  declarations: [
    ChannelsComponent,
    ChannelsChatsComponent,
    PrivateChatsComponent,
    ChannelEditComponent,
    ChannelFormComponent,
    ChatComponent,
    ChannelChatPageComponent,
    PrivateChatPageComponent,
    MessagesComponent,
    MessageEditDialogComponent,
    MessageEditFormComponent,
    MessageInputComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([ChatState]),
    RouterModule.forChild(CHAT_ROUTES),
    RouterModule,
  ],
  exports: [
    ChannelsComponent,
  ]
})
export class ChatModule {
}
