import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {Channel, Channels, Message} from './channels';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {
  get baseApiUrl() {
    return environment.baseApiUrl;
  }

  constructor(private httpClient: HttpClient) { }

  getAll() {
    return this.httpClient.get<Channels>(`${this.baseApiUrl}chat-channels`);
  }

  getChannelMessages(channelId: number) {
    return this.httpClient.get<Message[]>(`${this.baseApiUrl}chat-channels/${channelId}/messages`);
  }

  get(channelId: number) {
    return this.httpClient.get<Channel>(`${this.baseApiUrl}chat-channels/${channelId}`);
  }

  delete(channelId: number) {
    return this.httpClient.delete<Channel>(`${this.baseApiUrl}admin/chat-channels/${channelId}`);
  }

  follow(channelId: number) {
    return this.httpClient.put<Channel>(`${this.baseApiUrl}chat-channels/${channelId}/follow`, null);
  }

  unfollow(channelId: number) {
    return this.httpClient.delete<Channel>(`${this.baseApiUrl}chat-channels/${channelId}/follow`);
  }

  saveChannel(channel: Channel) {
    if (channel.id) {
      return this.httpClient.put<Channel>(`${this.baseApiUrl}admin/chat-channels/${channel.id}`, channel);
    } else {
      return this.httpClient.post<Channel>(`${this.baseApiUrl}admin/chat-channels`, channel);
    }
  }
}
