import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {Feature} from '../../models';
import {ModalController} from '@ionic/angular';
import {DialogService} from '../../modules';
import {BenefitTypeDialogComponent} from '../../modules/benefit-type/containers/benefit-type-dialog.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-new-item',
  templateUrl: './add-new-item.component.html',
  styleUrls: ['./add-new-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddNewItemComponent {

  featureProperties = [
    { feature: 'News', name: 'Post', icon: 'ic_pulse.svg', onClickFunction: () => this.onAddNewPostClick(), enabled: false },
    { feature: 'Events', name: 'Event', icon: 'ic_event.svg', onClickFunction: () => this.onAddNewEventClick(), enabled: false },
    { feature: 'Channels', name: 'Chat Channel', icon: 'ic_chat.svg', onClickFunction: () => this.onAddNewChatChannelClick(), enabled: false },
    { feature: 'Polls', name: 'Poll', icon: 'ic_poll.svg', onClickFunction: () => this.onAddNewPollClick(), enabled: false },
    { feature: 'Polls', name: 'Survey', icon: 'ic_survey.svg', onClickFunction: () => this.onAddNewSurveyClick(), enabled: false },
    { feature: 'Benefits', name: 'Benefit', icon: 'ic_benefit-2.svg', onClickFunction: () => this.onAddNewBenefitClick(), enabled: false },
    // { feature: 'UserReactivation', name: 'User reactivation', icon: 'users-black.svg', enabled: false }
  ];

  @Input() features: Feature[];
  @Input() disabled: boolean;

  @Output() featureChange = new EventEmitter();
  @Output() featureDisabledClick = new EventEmitter();

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private dialogService: DialogService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.features?.currentValue) {
      this.featureProperties.forEach(featureProperty => {
        featureProperty.enabled = this.features.includes(featureProperty.feature as Feature);
      });
    }
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }


  onAddNewPostClick() {
    this.router.navigate(['/news/add']);
    this.dismiss();
  }

  onAddNewEventClick() {
    this.router.navigate(['/events/add']);
    this.dismiss();
  }

  onAddNewChatChannelClick() {
    this.router.navigate(['/chat/create']);
    this.dismiss();
  }

  onAddNewPollClick() {
    this.router.navigate(['/polls/add']);
    this.dismiss();
  }

  onAddNewSurveyClick() {
    this.router.navigate(['/polls/surveys/add']);
    this.dismiss();
  }

  onAddNewBenefitClick() {
    this.dismiss();
    this.dialogService.open(BenefitTypeDialogComponent, {
      width: 320
    });
  }
}
