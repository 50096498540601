<div>
<a class="corner-element has-margin-smd icon is-24x24" (click)="cancel.emit()">
  <img src="assets/icons/exit.svg">
</a>

<div class="overlay-content">
  <div class="has-padding-mlg">
    <div>
      <img src="assets/icons/location-permission-img.svg">
    </div>
  </div>
  <div class="text-header-2 has-padding-bottom-md">
    Enable location permissions to find benefits nearby easily
  </div>
  <div class="text-body-1">
    Turning on location services allows us to show you benefits nearby in the map and notify when you are next to them even if the FellowUp app is closed.
  </div>

  <div class="is-at-bottom">
    <a
      class="button is-rounded is-secondary is-high-button"
      (click)="cancel.emit()"
      target="_blank">
   Continue
    </a>
  </div>

</div>



</div>
