import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Message } from '../../channels';
import { Profile } from '../../../@core/modules/profile/profile';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageInputComponent implements OnChanges {
  @Input() user: Profile;
  @Input() editModeMessage: Message;

  @Output() messageSend = new EventEmitter<string>();

  inputControl = new FormControl('', [Validators.maxLength(1000)]);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editModeMessage && this.editModeMessage) {
      this.inputControl.setValue(this.editModeMessage.text);
    }
  }

  onTouchSendButton(event: Event) {
    event.preventDefault();
    this.sendMessage();
  }

  sendMessage() {
    const message = this.inputControl.value.trim();
    if (message) {
      this.messageSend.emit(message);
      this.inputControl.setValue('');
    }
  }
}
