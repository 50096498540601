import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {DialogRef} from '../../../dialog/dialog-ref';
import {DIALOG_DATA} from '../../../dialog/dialog-data';
import { MessageModalData } from '../../models';

@Component({
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageModalComponent {

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: MessageModalData
  ) { }

  close(confirmed: boolean) {
    this.dialogRef.close(confirmed);
  }
}
