<div class="dropdown is-right is-active text-list-item">
  <div class="dropdown-trigger" (click)="onClick()">
    <a class="icon is-medium" [class.has-margin-smd]="extraMargin" aria-haspopup="true" [class.active]="dropdownOpen">
      <app-icon source="more.svg"></app-icon>
    </a>
  </div>
  <div class="dropdown-menu narrow" *ngIf="dropdownOpen" [class.has-margin-right-md]="extraMargin">
    <div class="dropdown-content" (clickOutside)="onClickOutside()">
      <a *ngFor="let option of options; let i = index" class="dropdown-item" (click)="option.onClick()">
        {{ option.title }}
      </a>
      <ng-container *ngIf="!hideEdit">
        <a
          class="dropdown-item"
          [routerLink]="editRoute"
          (click)="edit.emit()">
          Edit
        </a>
      </ng-container>
      <a class="dropdown-item" (click)="onDelete()" *ifHasPermission="deletePermission">{{deleteText}}</a>
      <a
        *ngIf="optionalDropdownOption && !hideOptionalDropdownOption"
        class="dropdown-item"
        (click)="onOptionalDropdownOptionClick()"
      >
        {{optionalDropdownOption}}
      </a>
    </div>
  </div>
</div>
