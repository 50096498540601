<aside class="menu has-margin-bottom-lg">
  <ul class="menu-list text-body-2">
    <li *ngFor="let item of primaryMenu">
      <ng-container *ifHasFeature="item.features">
        <app-menu-item
          *ifHasPermission="item.permissions"
          [id]="item.id"
          [text]="item.text"
          [link]="item.link"
          [notificationCount]="notificationCounts[item.id]"
          (click)="menuItemClick.emit(item.id)">
        </app-menu-item>
      </ng-container>
    </li>
  </ul>
  <div>
    <hr>
    <ul class="menu-list text-body-2">
      <li *ngFor="let item of secondaryMenu">
        <ng-container *ifHasFeature="item.features">
          <app-menu-item
            *ifHasPermission="item.permissions"
            [id]="item.id"
            [text]="item.text"
            [link]="item.link"
            [notificationCount]="notificationCounts[item.id]"
            (click)="menuItemClick.emit(item.id)">
          </app-menu-item>
        </ng-container>
      </li>
    </ul>
  </div>
</aside>
