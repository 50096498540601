import {Injectable, ElementRef} from '@angular/core';
import * as bulmaCalendar from 'bulma-calendar';

export class BulmaCalendarOptions {
  type: 'date' | 'time';
  color = 'black';
  isRange = false;
  allowSameDayRange = true;
  dateFormat = 'YYYY-MM-DD';
  timeFormat = 'HH:mm';
  position = 'auto';
  showHeader = true;
  headerPosition: 'top' | 'bottom' = 'top';
  showFooter = true;
  showButtons = true;
  showTodayButton = false;
  showClearButton = false;
  cancelLabel = 'Cancel';
  clearLabel = 'Clear';
  todayLabel = 'Today';
  nowLabel = 'Now';
  validateLabel = 'Save';
  enableMonthSwitch = true;
  enableYearSwitch = true;
  disabledDates = [];
  weekStart = 1;
  minuteSteps = 5;
  labelFrom = '';
  labelTo = '';
  closeOnOverlayClick = true;
  closeOnSelect = true;
  toggleOnInputClick = true;
  onReady = null;

  constructor(type: 'date' | 'time') {
    this.type = type;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BulmaCalendarService {
  private instances: any = [];

  constructor() {}

  create(elementRef: ElementRef, bulmaCalendarType: 'date' | 'time', onSelect: (date: string) => void) {
    const options = new BulmaCalendarOptions(bulmaCalendarType);
    const instance = bulmaCalendar.attach(elementRef.nativeElement, options)[0];

    this.instances.push(instance);

    instance.on('show', _ => {
      this.instances.filter(x => x.id !== instance.id).forEach(x => x.hide());
    });

    instance.on('select', picker => {
      onSelect(<string>(<unknown>picker.data.value()));
    });

    return instance.id;
  }

  destroy(id: string) {
    this.instances.find(x => x.id === id)?.destroy();
    this.instances = this.instances.filter(x => x.id !== id);
  }

  setValue(id: string, date: string) {
    if (date) {
      this.instances.find(x => x.id === id)?.value(date);
    }
  }
}
