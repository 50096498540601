<ion-tabs (ionTabsWillChange)="menuItemClick.emit($event.tab)">
  <ion-tab-bar slot="bottom">
    <ng-container *ngFor="let item of menu">
      <ng-container *ifHasPermission="item.permissions">
        <ion-tab-button
          *ifHasFeature="item.features"
          [tab]="item.link">
          <app-icon
            [source]="getIconUrl(item.id)"
            sizeClass="is-medium"
            [notificationCount]="notificationCounts[item.id]"
          ></app-icon>
        </ion-tab-button>
      </ng-container>
    </ng-container>
  </ion-tab-bar>
</ion-tabs>
