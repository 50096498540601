<app-page remove-wrapper
  backRoute="/chat">

  <div right-icon>
    <ion-item [hidden]="true">
      <ion-select #moreMenuRef (ionChange)="onMoreMenuChange($event)" interface="action-sheet">
        <ion-select-option value="edit">Edit</ion-select-option>
        <ion-select-option value="delete">Delete</ion-select-option>
        <ion-select-option *ngIf="!(currentChannel$ | async)?.isFollowing" value="follow">Follow</ion-select-option>
        <ion-select-option *ngIf="(currentChannel$ | async)?.isFollowing" value="unfollow">Unfollow</ion-select-option>
      </ion-select>
    </ion-item>
    <a (click)="moreMenuRef.open()">
      <app-icon source="more.svg"></app-icon>
    </a>
  </div>

  <div title-content class="level is-mobile">
      <h1 class="text-subheading-1">#{{(currentChannel$ | async)?.name}}</h1>
  </div>

  <app-chat
    [chat]="currentChannel$ | async"
    [messages]="currentChannelMessages$ | async"
    [chatType]="'channel'"
    [user]="user$ | async"
    [loading]="(loading$ | async).channelMessages || (loading$ | async).deletingChannelId === (currentChannel$ | async)?.id"
    (delete)="deleteChannel()"
    (sendMessage)="sendMessage($event)"
    (followStatusChange)="followStatusChange($event)"
    (messageEditClick)="onMessageEditClick($event)"
  ></app-chat>
  <!--  -->
</app-page>
