<div class="page-title-wrapper">
  <h1 class="page-title">Send us feedback</h1>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="field has-margin-bottom-mlg">
    <div class="control is-fullwidth">
      <textarea
        class="textarea"
        formControlName="feedback"
        placeholder="Add your feedback">
      </textarea>
    </div>
  </div>

  <div class="field is-grouped has-margin-top-lg">
    <div class="control">
      <button type="submit" class="button is-rounded is-secondary">
        Send
      </button>
    </div>
    <div class="control">
      <button type="button" class="button is-rounded" (click)="cancel.emit()">
        Cancel
      </button>
    </div>
  </div>
</form>
