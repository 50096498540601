import {ChannelEditComponent} from './containers/channel-edit/channel-edit.component';
import {PrivateChatPageComponent} from './containers/private-chat-page/private-chat-page.component';
import {ChannelChatPageComponent} from './containers/channel-chat-page/channel-chat-page.component';
import {ChannelsComponent} from './containers/channels/channels.component';
import {Routes} from '@angular/router';
import { MobileGuard } from '../@core/guards';

export const CHAT_ROUTES: Routes = [
  {
    path: 'create', component: ChannelEditComponent,
    data: {permission: 'channels_manage', hideRightSidebar: true}
  },
  {
    path: 'edit/:channelId', component: ChannelEditComponent,
    data: {permission: 'news_manage', hideRightSidebar: true}
  },
  {path: 'channel/:channelId', component: ChannelChatPageComponent},
  {path: 'private/:recipientId', component: PrivateChatPageComponent},
  {path: '', component: ChannelsComponent, canActivate: [MobileGuard]},
];
