import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {ImmutableContext} from '@ngxs-labs/immer-adapter';
import {Navigate} from '@ngxs/router-plugin';
import { Injectable } from '@angular/core';
import { PrivateChat, Channel, Message, ChannelsLoading } from './channels';
import { ChannelsService } from './channels.service';
import { PrivateChatService } from './private-chat.service';
import { LoadChannels, LoadChannelMessages, LoadPrivateChatMessages,
  SetCurrentChannel, SetCurrentPrivateChat, SaveChannel, DeleteChannel,
  UnsetChannel, UnsetPrivateChat, FollowChannel, UnfollowChannel,
  JoinPrivateChatEvent, SendMessageEvent, SendPrivateMessageEvent, AddMessage } from './chat.actions';
import { WSEditMessage, WSEditPrivateMessage, WSChannelMessageSent, WSPrivateChatMessageSent, SetNotificationCount } from '../@core/store/app.actions';

export interface ChatStateModel {
  channels: Channel[];
  privateChats: PrivateChat[];
  currentChannelId: number;
  currentPrivateChatRecipientId: number;
  channelMessages: { [key: number]: Message[] };
  privateChatMessages: { [key: number]: Message[] };
  loading: ChannelsLoading;
}

@State<ChatStateModel>({
  name: 'chat',
  defaults: {
    channels: [],
    privateChats: [],
    currentChannelId: null,
    currentPrivateChatRecipientId: null,
    channelMessages: {},
    privateChatMessages: {},
    loading: {
      channels: false,
      channelMessages: false,
      deletingChannelId: undefined,
      save: false,
      forceReload: false,
    }
  }
})
@Injectable()
export class ChatState {

  constructor(private store: Store, private channelsService: ChannelsService, private privateChatService: PrivateChatService) {}

  @Selector()
  static channels(state: ChatStateModel): Channel[] {
    return state.channels;
  }
  
  @Selector()
  static loading(state: ChatStateModel) {
    return state.loading;
  }

  @Selector()
  static saving(state: ChatStateModel) {
    return state.loading.save;
  }

  @Selector()
  static privateChats(state: ChatStateModel): PrivateChat[] {
    const privateChats = [...state.privateChats];
    privateChats.sort((a, b) => {
      if (a.lastMessageTime > b.lastMessageTime) {
        return -1;
      }
      if (b.lastMessageTime > a.lastMessageTime) {
        return 1;
      }
      return 0;
    });
    return privateChats.slice(0, 5);
  }

  @Selector()
  static currentChannel(state: ChatStateModel): Channel {
    if (state.channels) {
      return state.channels.find(c => c.id === state.currentChannelId);
    }
  }

  @Selector()
  static currentPrivateChat(state: ChatStateModel): PrivateChat {
    if (state.privateChats) {
      return state.privateChats.find(c => c.userId === state.currentPrivateChatRecipientId);
    }
  }

  @Selector()
  static currentChannelMessages(state: ChatStateModel): Message[] {
    return state.channelMessages[state.currentChannelId];
  }

  @Selector()
  static currentPrivateChatMessages(state: ChatStateModel): Message[] {
    return state.privateChatMessages[state.currentPrivateChatRecipientId];
  }

  @Selector()
  static unreadMessageCount(state: ChatStateModel): number {
    return state.privateChats.reduce((sum, current) => sum + current.unreadMessageCount, 0)
      + state.channels.reduce((sum, current) => sum + current.unreadMessageCount, 0);
  }

  @Action(JoinPrivateChatEvent)
  @ImmutableContext()
  joinPrivateChatEvent(ctx: StateContext<ChatStateModel>, action: JoinPrivateChatEvent) {
    ctx.setState(state => {
      const payloadPrivateChat = action.payload[0];
      const existingPrivateChat = state.privateChats.find(c => c.userId === payloadPrivateChat.userId);

      if (!existingPrivateChat) {
        state.privateChats.push(payloadPrivateChat);
      }

      return state;
    });
  }

  @Action(AddMessage)
  @ImmutableContext()
  addMessage(ctx: StateContext<ChatStateModel>, action: AddMessage) {
    const currentMessages = this.store.selectSnapshot(ChatState.currentChannelMessages);

    ctx.setState(state => {
      state.channelMessages[action.message.chatId] = [...currentMessages, action.message];
      return state;
    });
  }

  @Action(SendMessageEvent)
  @ImmutableContext()
  addNewMessage(ctx: StateContext<ChatStateModel>, action: SendMessageEvent) {
    let currentMessages = this.store.selectSnapshot(ChatState.currentChannelMessages);
    const newMessage = action.payload[0];

    if (currentMessages.filter(cm => cm.messageCorrelationId === newMessage.messageCorrelationId).length > 0){
      const updatedMessageArray = currentMessages.filter(cm => cm.messageCorrelationId !== newMessage.messageCorrelationId);
      currentMessages = updatedMessageArray;
    }

    ctx.setState(state => {
      state.channelMessages[newMessage.chatId] = [...currentMessages, newMessage];
      return state;
    });
  }

  @Action(SendPrivateMessageEvent)
  @ImmutableContext()
  addNewPrivateMessage(ctx: StateContext<ChatStateModel>, action: SendPrivateMessageEvent) {
    const currentMessages = this.store.selectSnapshot(ChatState.currentPrivateChatMessages);
    const recipientId = ctx.getState().currentPrivateChatRecipientId;
    const newMessage = action.payload[0];

    ctx.setState(state => {
      state.privateChatMessages[recipientId] = [...currentMessages, newMessage];
      return state;
    });
  }

  @Action(LoadChannels)
  loadChannels(ctx: StateContext<ChatStateModel>, action: LoadChannels) {
    const state = ctx.getState();
    ctx.patchState({
      loading: {
        ...state.loading,
        channels: true,
        forceReload: action.forceLoad
      },
    });

    return this.channelsService.getAll()
      .pipe(
        tap(channels => {
          ctx.patchState({
            channels: channels.channels,
            privateChats: channels.privateChats,
            loading: {
              ...state.loading,
              channels: false,
              forceReload: false
            },
          });
        })
      );
  }

  @Action(LoadChannelMessages)
  @ImmutableContext()
  loadChannelMessages(ctx: StateContext<ChatStateModel>, action: LoadChannelMessages) {
    ctx.setState(state => {   
      state.loading.channelMessages = true;
      return state;
    });

    return this.channelsService.getChannelMessages(action.channelId)
      .pipe(
        tap(messages => {       
          ctx.setState(state => {
            state.channelMessages[action.channelId] = messages;
            const index = state.channels.findIndex(x => x.id === action.channelId);
            if (index >= 0) {
              state.channels[index].unreadMessageCount = 0;
            }
            state.loading.channelMessages = false;
            return state;
          });
        })
      );
  }

  @Action(LoadPrivateChatMessages)
  @ImmutableContext()
  loadPrivateChatMessages(ctx: StateContext<ChatStateModel>, action: LoadPrivateChatMessages) {
    return this.privateChatService.getChatMessages(action.recipientId)
      .pipe(
        tap(messages => {
          ctx.setState(state => {
            state.privateChatMessages[action.recipientId] = messages;
            const index = state.privateChats.findIndex(x => x.userId === action.recipientId);
            if (index >= 0) {
              state.privateChats[index].unreadMessageCount = 0;
            }
            return state;
          });
        })
      );
  }

  @Action(SetCurrentChannel)
  setCurrentChannel(ctx: StateContext<ChatStateModel>, action: SetCurrentChannel) {
    ctx.patchState({
      currentChannelId: action.channelId
    });
  }

  @Action(SetCurrentPrivateChat)
  setCurrentPrivateChat(ctx: StateContext<ChatStateModel>, action: SetCurrentPrivateChat) {
    ctx.patchState({
      currentPrivateChatRecipientId: action.recipientId
    });
  }

  @Action(SaveChannel)
  @ImmutableContext()
  saveChannel(ctx: StateContext<ChatStateModel>, action: SaveChannel) {
    ctx.setState(state => {   
      state.loading.save = true;
      return state;
    });

    return this.channelsService.saveChannel(action.channel).pipe(
      tap(channel => {
        if (!action.channel.id) {
          ctx.setState((state: ChatStateModel) => {
            const newChannel: Channel = {
              unreadMessageCount: 0,
              name: action.channel.name,
              id: channel.id,
              isFollowing: false
            };
            state.channels = [...state.channels, newChannel];
            state.loading.save = false;
            return state;
          });
        } else {
          ctx.setState((state: ChatStateModel) => {
            const index = state.channels.findIndex(newChannel => newChannel.id === action.channel.id);
            state.channels[index] = action.channel;
            state.loading.save = false;
            return state;
          });
        }
        ctx.dispatch(new Navigate([`/chat/channel/`,channel.id]));
      })
    );
  }

  @Action(DeleteChannel)
  @ImmutableContext()
  deleteChannel(ctx: StateContext<ChatStateModel>, action: DeleteChannel) {
    ctx.setState(state => {   
      state.loading.deletingChannelId = action.channelId;
      return state;
    });   

    return this.channelsService.delete(action.channelId).pipe(
      tap(_ => {
        ctx.setState((state: ChatStateModel) => {
          const channelIndex = state.channels.findIndex(channel => channel.id === action.channelId);
          state.channels.splice(channelIndex, 1);
          state.loading.deletingChannelId = undefined;
          return state;
        });
        ctx.dispatch(new Navigate(['/chat']));
      })
    );
  }

  @Action(UnsetChannel)
  unsetChannel(ctx: StateContext<ChatStateModel>) {
    ctx.patchState({
      currentChannelId: null
    });
  }

  @Action(UnsetPrivateChat)
  unsetPrivateChat(ctx: StateContext<ChatStateModel>) {
    ctx.patchState({
      currentPrivateChatRecipientId: null
    });
  }

  @Action(FollowChannel)
  @ImmutableContext()
  followChannel(ctx: StateContext<ChatStateModel>, action: FollowChannel) {
    return this.channelsService.follow(action.channelId).pipe(
      tap(channel => {
        this.followStatusChange(ctx, action, true);
      })
    );
  }

  @Action(UnfollowChannel)
  @ImmutableContext()
  unfollowChannel(ctx: StateContext<ChatStateModel>, action: UnfollowChannel) {
    return this.channelsService.unfollow(action.channelId).pipe(
      tap(channel => {
        this.followStatusChange(ctx, action, false);
      })
    );
  }

  @Action(WSEditMessage)
  @ImmutableContext()
  editMessage(ctx: StateContext<ChatStateModel>, action: WSEditMessage) {
    const editMessage = action.payload[0];

    ctx.setState(state => {
      const index = state.channelMessages[editMessage.chatId].findIndex(x => x.id === editMessage.id);
      if (index >= 0) {
        state.channelMessages[editMessage.chatId][index] = editMessage;
      }
      return state;
    });
  }

  @Action(WSEditPrivateMessage)
  @ImmutableContext()
  editPrivateMessage(ctx: StateContext<ChatStateModel>, action: WSEditPrivateMessage) {
    const recipientId = ctx.getState().currentPrivateChatRecipientId;
    const editMessage = action.payload[0];

    ctx.setState(state => {
      const index = state.privateChatMessages[recipientId].findIndex(x => x.id === editMessage.id);
      if (index >= 0) {
        state.privateChatMessages[recipientId][index] = editMessage;
      }
      return state;
    });
  }

  @Action(WSChannelMessageSent)
  @ImmutableContext()
  channelMessageSent(ctx: StateContext<ChatStateModel>, action: WSChannelMessageSent) {
    const channelId = action.payload[0].properties.Id;
    ctx.setState(state => {
      const index = state.channels.findIndex(x => x.id === channelId);
      if (index >= 0) {
        if (state.currentChannelId === channelId) {
          state.channels[index].unreadMessageCount = 0;
        } else {
          state.channels[index].unreadMessageCount++;
        }
      }
      return state;
    });

    ctx.dispatch(new SetNotificationCount('chat', this.store.selectSnapshot(ChatState.unreadMessageCount)));
  }

  @Action(WSPrivateChatMessageSent)
  @ImmutableContext()
  privateChatMessageSent(ctx: StateContext<ChatStateModel>, action: WSPrivateChatMessageSent) {
    const chatId = action.payload[0].properties.Id;
    ctx.setState(state => {
      const index = state.privateChats.findIndex(x => x.id === chatId);
      const privateChat = JSON.parse(action.payload[0].properties.PrivateChat) as PrivateChat;

      if (index >= 0) {
        if (state.currentPrivateChatRecipientId === privateChat.userId) {
          state.privateChats[index].unreadMessageCount = 0;
        } else {
          state.privateChats[index].unreadMessageCount = privateChat.unreadMessageCount;
        }
        state.privateChats[index].lastMessageTime = privateChat.lastMessageTime;
      } else {
        state.privateChats.push(privateChat);
      }
      return state;
    });

    ctx.dispatch(new SetNotificationCount('chat', this.store.selectSnapshot(ChatState.unreadMessageCount)));
  }

  followStatusChange(ctx: StateContext<ChatStateModel>, action: FollowChannel | UnfollowChannel, followStatus: boolean) {
    ctx.setState(state => {
      const currentChannel = state.channels.find(channel => channel.id === action.channelId);
      currentChannel.isFollowing = followStatus;
      return state;
    });
  }
}
