import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Platform} from '@ionic/angular';

@Directive({
  selector: '[isDesktop]'
})
export class IsDesktopDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public platform: Platform,
  ) {}

  ngOnInit(): void {
    const rightPlatform = this.platform.is('desktop');
    this.updateView(rightPlatform);
  }

  private updateView(rightPlatform: boolean) {
    if (rightPlatform) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

