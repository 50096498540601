import {Component, Input, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-event-month-day',
  templateUrl: './event-month-day.component.html',
  styleUrls: ['./event-month-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventMonthDayComponent {
  @Input() eventDate: string;
  @Input() size: 'sm';

  constructor() { }
}
