import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() path = '';
  @Input() source: string;
  @Input() isRounded: boolean;
  @Input() notificationCount: number;
  @Input() withBackground: boolean;
  @Input() backgroundOnHover: boolean;
  @Input() sizeClass: '' | 'is-small' | 'is-medium' | 'is-large' | 'is-16x16' | 'is-20x20' | 'is-24x24' | 'is-32x32' | 'is-36x36' = '';

  get formattedPath() {
    return `assets/icons${this.path}/${this.source}`;
  }

  constructor() {
  }
}
