<div *isMobile class="control">
  <input class="input" [id]="'date-input-' + formControlName" type="text" placeholder="Choose Date" [value]="displayValue">
  <!-- <span class="icon is-right">
    <app-icon source="calendar.svg"></app-icon>
  </span> -->
  <ion-modal [trigger]="'date-input-' + formControlName">
    <ng-template>
      <ion-content force-overscroll="false">
        <ion-datetime
          [value]="value"
          presentation="date"
          [showDefaultButtons]="true"
          [min]="min"
          [max]="max"
          hourCycle="h23"
          minuteValues="0,15,30,45"
          (ionChange)="onStartDateChanged($event)">
        </ion-datetime>
      </ion-content>
    </ng-template>
  </ion-modal>
</div>

<div class="control" *isDesktop>
  <input bulmaCalendar
    placeholder="Choose Date"
    bulmaCalendarType="date"
    type="date" >
</div>
