import {Directive, EventEmitter, HostListener, OnDestroy, Output} from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';

@Directive({
  selector: '[longPress]'
})
export class LongPressDirective implements OnDestroy {
  @Output() longPress = new EventEmitter();

  private longPressApplied = false;
  private longPressTriggerTime = 300;
  private longPressSubscription: Subscription;
  private cancelled$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  @HostListener('mouseup', ['$event'])
  @HostListener('mouseout', ['$event'])
  @HostListener('mouseleave', ['$event'])
  @HostListener('touchcancel', ['$event'])
  @HostListener('touchend', ['$event'])
  onExit() {
    this.cancelled$.next(true);
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onHold() {
    this.cancelled$.next(false);
    this.longPressSubscription = interval(this.longPressTriggerTime).pipe(
      takeUntil(this.cancelled$.pipe(filter(v => v))),
      tap(longPressIteration => {
        this.longPressApplied = !!longPressIteration && !this.cancelled$.value;
        if (this.longPressApplied) {
          this.longPress.emit();
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.longPressSubscription) {
      this.longPressSubscription.unsubscribe();
    }
  }
}
