<div>
  <div class="text-subheading-1 has-text-centered has-margin-bottom-md">Select benefit type</div>

  <div class="media flex-container has-margin-bottom-sm" (click)="navigateToBenefitCreate()">
        <figure class="media-left">
          <div class="with-background">
            <app-icon source="ic_benefit-2.svg"></app-icon>
          </div>
        </figure>
        <div class="media-content">
          <div class="text-body-small-2">Regular benefit</div>
          <div class="text-body-small">Benefit details will be opened in the app</div>
        </div>
  </div>

  <div class="media flex-container" (click)="navigateToWeblinkCreate()">
    <figure class="media-left">
      <div class="with-background">
        <app-icon source="ic_weblink.svg"></app-icon>
      </div>
    </figure>
    <div class="media-content">
      <div class="text-body-small-2">Weblink benefit</div>
      <div class="text-body-small">Benefit details will be opened in external website</div>
    </div>
</div>


  <a class="close-button has-margin-smd icon is-24x24" (click)="cancel.emit()">
    <!--<app-icon source="exit.svg" class="icon is-24x24"></app-icon>-->
    <img src="assets/icons/exit.svg">
  </a>
</div>
